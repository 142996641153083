import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../common/Button';
import Card from '../common/Card';
import Form from '../common/Form';
import Page from '../common/Page';
import APIManager from '../../managers/APIManager';

import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/manipulateText';

const AddPlayer = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [language, setLanguage] = useState('en');

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    APIManager.createPlayer({ name, language }).then(res => {
      props.history.push(`/players/${res.data.id}`);
    });
  };

  return (
    <Page title={capitalize(t('new player'))}>
      <Card className="max-w-lg m-auto">
        <Form onSubmit={handleSubmit}>
          <Card.Body>
            <Form.FormTextInput
              autoFocus={true}
              name="name"
              label="Name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <Form.Select
              className="w-full"
              name="language"
              label="Language"
              onChange={e => setLanguage(e.target.value)}
              value={language}
            >
              <option value="en">English</option>
              <option value="fr">French</option>
              <option value="de">German</option>
              <option value="ru">Russian</option>
              <option value="es">Spanish</option>
              <option value="tr">Turkish</option>
            </Form.Select>
          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              color="primary"
              disabled={!name.length || loading}
              loading={loading}
              onClick={handleSubmit}
              icon="plus"
            >
              Add Player
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </Page>
  );
};

AddPlayer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(AddPlayer);
