import * as React from "react";
import * as Icon from "react-feather";

import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";
import Page from "../common/Page";

const ChangePassword = (
  errors,
  values,
  errorMessage,
  infoMessage,
  onBlur,
  onChange,
  onSubmit,
  password,
  newPassword
) => (
  <Page>
    <div className="max-w-lg flex flex-col">
      <div className="my-auto">
        {errorMessage ? (
          <div
            className="bg-red-100 border-l-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow mb-3"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <Icon.Info className="h-6 w-6 text-red-500 mr-4" />
              </div>
              <div>
                <p className="font-bold">Failed</p>
                <p className="text-sm">{errorMessage}</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {infoMessage ? (
          <div
            className="bg-blue-100 border-l-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow mb-3"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <Icon.Info className="h-6 w-6 text-blue-500 mr-4" />
              </div>
              <div>
                <p className="font-bold">Success!</p>
                <p className="text-sm">{infoMessage}</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Card className="p-2">
          <Card.Body>
            <span className="block text-lg mb-5">Change Your Password</span>
            <Form>
              <Form.FormTextInput
                name="password"
                type="password"
                label="New password"
                placeholder="New password"
                onChange={onChange}
                onBlur={onBlur}
                value={values && values.password}
                error={errors && errors.password}
              />
              <Form.FormTextInput
                name="password2"
                type="password"
                label="Confirm new password"
                placeholder="Confirm new password"
                onChange={onChange}
                onBlur={onBlur}
                value={values && values.password2}
                error={errors && errors.password2}
              />
              <Button
                className="w-full"
                color="login"
                onClick={onSubmit}
                type="submit"
              >
                Change Password
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  </Page>
);

export default ChangePassword;
