import * as React from "react";
import * as Icon from "react-feather";

import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";

import logo from "./../../assets/images/empower-logo-box.png";

const Register = (
  errors,
  values,
  errorMessage,
  infoMessage,
  onBlur,
  onChange,
  onSubmit,
  email,
  password,
  name
) => (
  <div className="max-w-sm m-auto h-screen flex flex-col">
    <div className="my-auto">
      <img src={logo} width="150" className="mb-3 m-auto" alt="Empower Logo" />
      {errorMessage ? (
        <div
          className="bg-red-100 border-l-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow mb-3"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-red-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Register Failed</p>
              <p className="text-sm">{errorMessage}</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {infoMessage ? (
        <div
          className="bg-blue-100 border-l-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow mb-3"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-blue-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Your account has registered!</p>
              <p className="text-sm">{infoMessage}</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Card className="p-2">
        <Card.Body>
          <span className="block text-lg mb-5">Create an account</span>
          <Form>
            <Form.FormTextInput
              name="name"
              label="Full Name"
              placeholder="Full name"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.name}
              error={errors && errors.name}
            />
            <Form.FormTextInput
              name="email"
              label="Email Address"
              placeholder="Enter email"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.email}
              error={errors && errors.email}
              type="email"
            />
            <Form.FormTextInput
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.password}
              error={errors && errors.password}
            />
            <Button
              className="w-full"
              color="login"
              onClick={onSubmit}
              type="submit"
            >
              Create Account
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <p className="text-center text-gray-600 text-sm">
        Already have an account?{" "}
        <a href="/auth/login" className="font-bold text-blue-700">
          Login
        </a>
      </p>
    </div>
  </div>
);

export default Register;
