import React from "react";
import HelperFormatter from "../../helpers/formatter";
import HelperExcel from "../../helpers/exporter";
import Card from "../common/Card";
import { useSelector } from "react-redux";
import * as Icon from "react-feather";
import { Translation, useTranslation } from "react-i18next";
import { upperCase } from "../../utils/manipulateText";
import APIManager from "../../managers/APIManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/Table";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { current } from "@reduxjs/toolkit";
import _ from "lodash";
const ReportTable = ({
  table,
  site,
  reportDimensions,
  breakdowns,
  tableFooter,
  sortOrder,
  setTable,
}) => {
  const currency = useSelector((state) => state?.auth?.user?.currency);
  const { t } = useTranslation();

  const tableHeaders = [
    {
      content: "month",
      key: "month",
      onClick: () => sortTable("month"),
    },
    {
      content: "date",
      key: "date",
      onClick: () => sortTable("day"),
    },
    {
      content: "ad placement",
      key: "ad placement",
      onClick: () => sortTable("zone"),
    },

    {
      content: "platform",
      key: "platform",
      onClick: () => sortTable("platform"),
    },
    {
      content: "site",
      key: "site",
      onClick: () => sortTable("site"),
    },
    {
      content: "stat type",
      key: "stat type",
      onClick: () => sortTable("type"),
    },
    {
      content: "tag",
      key: "tag",
      onClick: () => sortTable("tag"),
    },
    {
      content: "app",
      key: "app",
      onClick: () => sortTable("app"),
    },
    {
      content: "analytics pageview",
      key: "analytics pageview",
      onClick: () => sortTable("analyticsPageview"),
    },
    {
      content: "analytics unique visitors",
      key: "analytics unique visitors",
      onClick: () => sortTable("analyticsUniqueVisitor"),
    },
    {
      content: "empower pageview",
      key: "virgul pageview",
      onClick: () => sortTable("virgulPageview"),
    },
    {
      content: "empower unique visitors",
      key: "virgul unique visitors",
      onClick: () => sortTable("virgulUniqueVisitor"),
    },
    {
      content: "ad req.",
      key: "ad req.",
      onClick: () => sortTable("request"),
    },
    {
      content: "matched req.",
      key: "matched req.",
      onClick: () => sortTable("match"),
    },
    {
      content: "impressions",
      key: "impressions",
      onClick: () => sortTable("view"),
    },
    {
      content: "clicks",
      key: "clicks",
      onClick: () => sortTable("click"),
    },
    {
      content: "coverage",
      key: "coverage",
      onClick: () => sortTable("coverage"),
    },
    {
      content: "ctr",
      key: "ctr",
      onClick: () => sortTable("ctr"),
    },
    {
      content: "req. ecpm",
      key: "req. ecpm",
      onClick: () => sortTable("requestEcpm"),
    },
    {
      content: "ecpm",
      key: "ecpm",
      onClick: () => sortTable("ecpm"),
    },
    {
      content: "viewability",
      key: "viewability",
      onClick: () => sortTable("viewability"),
    },
    {
      content: "revenue",
      key: "revenue",
      onClick: () => sortTable("spent"),
    },
  ];

  const sortTable = (field) => {
    sortOrder.current = sortOrder.current !== "asc" ? "asc" : "desc";
    const tblTmp = table.tbl;
    if (field === "spent") {
      tblTmp.sort((a, b) => {
        const aValue = a.spent && a.spent[currency] ? a.spent[currency] : 0;
        const bValue = b.spent && b.spent[currency] ? b.spent[currency] : 0;

        if (sortOrder.current === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });

      setTable({
        ...table,
        tbl: tblTmp,
      });
    } else if (field === "ecpm") {
      tblTmp.sort((a, b) => {
        const aValue = a.ecpm && a.ecpm[currency] ? a.ecpm[currency] : 0;
        const bValue = b.ecpm && b.ecpm[currency] ? b.ecpm[currency] : 0;

        if (sortOrder.current === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });

      setTable({
        ...table,
        tbl: tblTmp,
      });
    } else if (field === "requestEcpm") {
      tblTmp.sort((a, b) => {
        const aValue =
          a.requestEcpm && a.requestEcpm[currency]
            ? a.requestEcpm[currency]
            : 0;
        const bValue =
          b.requestEcpm && b.requestEcpm[currency]
            ? b.requestEcpm[currency]
            : 0;

        if (sortOrder.current === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });

      setTable({
        ...table,
        tbl: tblTmp,
      });
    } else if (field === "viewability") {
      tblTmp.sort((a, b) => {
        const aValue =
          a.viewability && a.viewability.real ? a.viewability.real : 0;
        const bValue =
          b.viewability && b.viewability.real ? b.viewability.real : 0;

        if (sortOrder.current === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });

      setTable({
        ...table,
        tbl: tblTmp,
      });
    } else if (field === "date") {
      const sortedData = tblTmp
        .map((item) => {
          return {
            ...item,
            date: new Date(item.date.split(".").reverse().join("-")),
          };
        })
        .sort((a, b) => {
          if (sortOrder.current === "asc") {
            return a.date - b.date;
          } else {
            return b.date - a.date;
          }
        })
        .map((item) => {
          return {
            ...item,
            date: item.date
              .toLocaleDateString("en-GB")
              .split("/")
              .reverse()
              .join("."),
          };
        });

      setTable({
        ...table,
        tbl: _.orderBy(sortedData, field, sortOrder.current),
      });
    } else {
      setTable({ ...table, tbl: _.orderBy(tblTmp, field, sortOrder.current) });
    }
  };

  const getTableHeaders = () => {
    return tableHeaders.filter((header) => {
      if (header.key === "month") {
        return reportDimensions.indexOf("monthly") !== -1;
      } else if (header.key === "date") {
        return reportDimensions.indexOf("daily") !== -1;
      } else if (header.key === "ad placement") {
        return reportDimensions.indexOf("zone") !== -1;
      } else if (header.key === "tag") {
        return reportDimensions.indexOf("tag") !== -1;
      } else if (header.key === "platform") {
        return reportDimensions.indexOf("platform") !== -1;
      } else if (header.key === "site") {
        return reportDimensions.indexOf("site") !== -1;
      } else if (header.key === "app") {
        return reportDimensions.indexOf("app") !== -1;
      } else if (header.key === "stat type") {
        return reportDimensions.indexOf("statType") !== -1;
      } else if (header.key.includes("virgul")) {
        if (
          table.dim.includes(header.key) &&
          reportDimensions.indexOf("statType") === -1
        ) {
          return true;
        }
      } else {
        if (table.dim.includes(header.key)) {
          return true;
        }
        return false;
      }
    });
  };

  const footerSection =
    Object.keys(tableFooter).length > 0 ? (
      <Table.Row key={Math.random()} className="bg-gray-200">
        {reportDimensions.indexOf("monthly") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong>{upperCase(t(tableFooter?.month))}</strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("daily") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong>{upperCase(t(tableFooter?.day))}</strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("zone") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong data-zone={tableFooter?.zone?.id}>
              {tableFooter?.zone?.id &&
                `${tableFooter?.zone?.name} (${tableFooter?.zone?.id})`}
            </strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("tag") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong data-zone={tableFooter?.tag}>
              {upperCase(t(tableFooter?.tag))}
            </strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("platform") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong data-zone={tableFooter?.platform}>
              {upperCase(t(tableFooter?.platform))}
            </strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("site") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong data-zone={tableFooter?.site?.name}>
              {upperCase(t(tableFooter?.site?.name))}
            </strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("statType") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong data-zone={tableFooter?.site?.name}>
              {upperCase(t(tableFooter?.site?.name))}
            </strong>
          </Table.Col>
        ) : null}
        {reportDimensions.indexOf("app") !== -1 ? (
          <Table.Col className="border text-center uppercase">
            <strong>{tableFooter?.app}</strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("analytics pageview") ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter.number(tableFooter.analyticsPageview)}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("analytics unique visitors") ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter.number(tableFooter.analyticsUniqueVisitor)}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("virgul pageview") &&
        reportDimensions.indexOf("statType") === -1 ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter.number(tableFooter.virgulPageview)}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("virgul unique visitors") &&
        reportDimensions.indexOf("statType") === -1 ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter.number(tableFooter.virgulUniqueVisitor)}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("ad req.") ? (
          <Table.Col className="border text-right">
            <strong>{HelperFormatter.number(tableFooter.request)}</strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("matched req.") ? (
          <Table.Col className="border text-right">
            <strong>{HelperFormatter.number(tableFooter.match)}</strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("impressions") ? (
          <Table.Col className="border text-right">
            <strong>{HelperFormatter.number(tableFooter.view)}</strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("clicks") ? (
          <Table.Col className="border text-right">
            <strong>{HelperFormatter.number(tableFooter.click)}</strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("coverage") ? (
          <Table.Col className="border text-right">
            <strong>{HelperFormatter.percentage(tableFooter.coverage)}</strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("ctr") ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter?.percentage(tableFooter?.ctr * 100)}
            </strong>
          </Table.Col>
        ) : null}
        {table?.dim?.includes("req. ecpm") ? (
          <Table.Col className="border text-right">
            <strong>
              {tableFooter?.requestEcpm &&
                HelperFormatter?.currency(currency)(
                  tableFooter?.requestEcpm[currency]
                )}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("ecpm") ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter.currency(currency)(tableFooter.ecpm[currency])}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("viewability") ? (
          <Table.Col className="border text-right">
            <strong>
              {tableFooter.viewability &&
                HelperFormatter.percentage(tableFooter.viewability.real * 100)}
            </strong>
          </Table.Col>
        ) : null}
        {table.dim.includes("revenue") ? (
          <Table.Col className="border text-right">
            <strong>
              {HelperFormatter.currency(currency)(tableFooter.spent[currency])}
            </strong>
          </Table.Col>
        ) : null}
      </Table.Row>
    ) : null;

  return (
    <Card title="report" className="mb-5">
      <div className="float-right flex ">
        <button
          style={{ marginTop: "-30px" }}
          onClick={() => {
            HelperExcel.exportExcel(
              [].concat(table.tbl),
              "DailyBreakdown",
              true,
              currency
            );
          }}
          className="mx-4 mt-4 pull-right text-sm py-1"
        >
          <Icon.Download
            className="pull-left mt-1 mr-1 text-gray-600"
            width="16"
            height="16"
          />
          <label
            style={{
              float: "right",
              color: "#718096",
              cursor: "pointer",
              marginTop: "1px",
            }}
          >
            /<Translation>{(t) => upperCase(t("download"))}</Translation>
          </label>
        </button>
        <button
          style={{ marginTop: "-30px" }}
          onClick={() => {
            var data = HelperExcel.exportExcel(
              [].concat(table.tbl),
              "DailyBreakdown",
              true,
              currency,
              true
            );
            const fileName =
              site.fqdn + " - " + new Date().toLocaleDateString();
            APIManager.exportToDrive(fileName, data).then((res) => {
              window.open(res.url, "_blank");
            });
          }}
          className="mx-4 mt-4 pull-right text-sm py-1"
        >
          <FontAwesomeIcon
            icon={faGoogleDrive}
            style={{
              width: "16px",
              height: "16px",
            }}
            className="pull-left mt-1 mr-1 text-gray-600"
          />
          <label
            style={{
              float: "right",
              color: "#718096",
              cursor: "pointer",
              marginTop: "1px",
            }}
          >
            /<Translation>{(t) => upperCase(t("open in drive"))}</Translation>
          </label>
        </button>
      </div>

      <div className="overflow-x-scroll">
        <Table headers={getTableHeaders()} footerSection={footerSection}>
          {table.tbl.map((row, index) => (
            <Table.Row
              key={Math.random()}
              className="even:bg-gray-100 hover:bg-gray-200"
            >
              {reportDimensions.indexOf("monthly") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong>{row?.month}</strong>
                </Table.Col>
              ) : null}
              {reportDimensions.indexOf("daily") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong>{row?.day}</strong>
                </Table.Col>
              ) : null}
              {reportDimensions.indexOf("zone") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong data-zone={row?.zone?.id}>
                    {row?.zone?.id && `${row?.zone?.name} (${row?.zone?.id})`}
                  </strong>
                </Table.Col>
              ) : null}
              {reportDimensions.indexOf("tag") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong data-zone={row?.tag}>{row?.tag}</strong>
                </Table.Col>
              ) : null}
              {reportDimensions.indexOf("platform") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong data-zone={row?.platform}>{row?.platform}</strong>
                </Table.Col>
              ) : null}
              {reportDimensions.indexOf("site") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong data-zone={row?.site?.name}>{row?.site?.name}</strong>
                </Table.Col>
              ) : null}
              {reportDimensions.indexOf("statType") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong data-zone={row?.type}>{row?.type}</strong>
                </Table.Col>
              ) : null}
              {breakdowns.indexOf("app") !== -1 ? (
                <Table.Col className="border text-center">
                  <strong>{row?.app}</strong>
                </Table.Col>
              ) : null}

              {table.dim.includes("analytics pageview") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.analyticsPageview)}
                </Table.Col>
              ) : null}
              {table.dim.includes("analytics unique visitors") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.analyticsUniqueVisitor)}
                </Table.Col>
              ) : null}
              {table.dim.includes("virgul pageview") &&
              reportDimensions.indexOf("statType") === -1 ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.virgulPageview)}
                </Table.Col>
              ) : null}
              {table.dim.includes("virgul unique visitors") &&
              reportDimensions.indexOf("statType") === -1 ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.virgulUniqueVisitor)}
                </Table.Col>
              ) : null}
              {table.dim.includes("ad req.") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.request)}
                </Table.Col>
              ) : null}
              {table.dim.includes("matched req.") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.match)}
                </Table.Col>
              ) : null}
              {table.dim.includes("impressions") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.view)}
                </Table.Col>
              ) : null}
              {table.dim.includes("clicks") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.number(row.click)}
                </Table.Col>
              ) : null}
              {table.dim.includes("coverage") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.percentage(row.coverage)}
                </Table.Col>
              ) : null}
              {table.dim.includes("ctr") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter?.percentage(row?.ctr * 100)}
                </Table.Col>
              ) : null}
              {table?.dim?.includes("req. ecpm") ? (
                <Table.Col className="border text-right">
                  {row.requestEcpm &&
                    HelperFormatter?.currency(currency)(
                      row.requestEcpm[currency]
                    )}
                </Table.Col>
              ) : null}
              {table.dim.includes("ecpm") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.currency(currency)(row.ecpm[currency])}
                </Table.Col>
              ) : null}
              {table.dim.includes("viewability") ? (
                <Table.Col className="border text-right">
                  {row.viewability &&
                    HelperFormatter.percentage(row.viewability.real * 100)}
                </Table.Col>
              ) : null}
              {table.dim.includes("revenue") ? (
                <Table.Col className="border text-right">
                  {HelperFormatter.currency(currency)(row.spent[currency])}
                </Table.Col>
              ) : null}
            </Table.Row>
          ))}
        </Table>
      </div>
    </Card>
  );
};

export default ReportTable;
