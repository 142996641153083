import { SiteActions } from "../actions/site";
import HelperUtil from "../../helpers/utils";

const INITIAL_STATE = {
  reports: {},
  sites: [],
  info: {},
  selectedSite: null,
};

function site(state = INITIAL_STATE, action) {
  let reports, sites;
  switch (action.type) {
    case SiteActions.CREATE_SITE:
      sites = state.sites.slice();
      sites.push(action.payload.site);
      return {
        ...state,
        sites,
      };
    case SiteActions.GET_SITE:
    case SiteActions.UPDATE_SITE:
      sites = state.sites
        .slice()
        .filter((site) => site.id !== action.payload.site.id);
      sites.push(action.payload.site);
      return {
        ...state,
        sites,
      };
    case SiteActions.SELECT_SITE:
      return {
        ...state,
        selectedSite: action.payload.site,
      };
    case SiteActions.GET_SITE_REPORT:
      reports = Object.assign({}, state.reports);
      reports[HelperUtil.getMD5Hash(JSON.stringify(action.payload.params))] =
        action.payload.report;
      return {
        ...state,
        reports,
      };
    case SiteActions.GET_SITES:
      return {
        ...state,
        sites: action.payload.sites,
        info: action.payload.info,
      };
    case SiteActions.INVALIDATE_SITES:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default { site };
