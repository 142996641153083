import React from "react";
import Loading from "./Loading";

const LoadingBackDrop = () => {
  return (
    <div
      id="wrapper"
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 overflow-y:scroll"
    >
      <Loading />
    </div>
  );
};

export default LoadingBackDrop;
