import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import APIManager from "../../../managers/APIManager";
import ReportTable from "./ReportTable";
import Page from "../../common/Page";
import LanguageSelector from "../../common/LanguageSelector";
import Loading from "../../common/Loading";
import * as Icon from "react-feather";
import { capitalize, capitalizeFirst } from "../../../utils/manipulateText";
import Button from "../../common/Button";
import DeleteModal from "./DeleteModal";
import ConfigModal from "./ConfigModal";
import { modalConstants } from "./constants/modalConstants";
import { initialState } from "./constants/actionTypes";
import i18n from "i18next";
import { Translation, useTranslation } from "react-i18next";
import Card from "../../common/Card";
import { upperCase } from "lodash";

const Reports = ({ integrationType, site, user, match }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [modalReportName, setModalReportName] = useState("");
  const [initState, setInitState] = useState(initialState);

  const { t } = useTranslation();
  useEffect(() => {
    if (integrationType != 3 && integrationType != 4) {
      setInitState({
        ...initState,
        selectedSite: "",
        selectedBreakdowns: ["daily"],
        selectedPlatforms: ["all"],
      });
    } else {
      setInitState({
        ...initState,
        selectedSite: "",
        selectedBreakdowns: ["appNames"],
      });
    }

    var reportType = "site";
    if (integrationType === 3 || integrationType === 4) reportType = "app";

    setLoading(true);
    var breakdowns = [];
    var filters = {};
    var platforms = [];
    const siteArrTmp = [
      ...site.sites,
      { id: "all-sites", virgulId: "all-sites" },
    ];

    APIManager.getScheduledReports(reportType).then((res) => {
      const formattedReports = res.data.map((item) => {
        var siteEmpowerId = item.site;
        const parsedFilter = JSON.parse(item.reportFilter);
        breakdowns = [];
        filters = {};
        platforms = [];
        if (integrationType === 3 || integrationType === 4) {
          Object.keys(modalConstants.appBreakdownsAll).forEach((key) => {
            if (parsedFilter?.[key]) {
              breakdowns.push(modalConstants.appBreakdownsAll[key]);
            }
          });

          platforms = parsedFilter.platforms;

          if (platforms.length === 0) {
            platforms = Object.keys(modalConstants.appPlatformsAll);
          }
          siteEmpowerId = item.site;
        } else {
          if (parsedFilter?.appNames) breakdowns.push("appNames");
          if (parsedFilter?.day) breakdowns.push("daily");
          if (parsedFilter?.month) breakdowns.push("monthly");
          if (parsedFilter?.tagContent) breakdowns.push("tag");
          if (parsedFilter?.zone) breakdowns.push("placement");
          if (parsedFilter?.site) breakdowns.push("site");
          if (parsedFilter?.platformTag) breakdowns.push("platform");

          platforms = parsedFilter.platform ? [parsedFilter.platform] : ["All"];
          siteEmpowerId = siteArrTmp.find((s) => s.virgulId === item.site)[
            "id"
          ];
        }

        const updatedAt = item.updatedAt;

        const date = new Date(updatedAt);

        const dateFormat = i18n.language === "en" ? "en-US" : "tr-TR";
        const formattedDate = date.toLocaleDateString(
          dateFormat,
          modalConstants.options
        );
        return {
          ...item,
          site: siteEmpowerId,
          breakdowns,
          platforms,
          reportFilter: parsedFilter,
          updatedAt: formattedDate,
        };
      });
      setReports(formattedReports);
      setLoading(false);
    });
  }, [refreshData]);

  return (
    <>
      <DeleteModal
        isVisible={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          const bodyEl = document.getElementsByTagName("BODY")[0];
          bodyEl.style.overflow = "hidden";
        }}
        modalId={modalId}
        modalReportName={modalReportName}
        setRefreshData={setRefreshData}
        setShowDeleteModal={setShowDeleteModal}
      />
      <ConfigModal
        isVisible={showConfig}
        setIsVisible={setShowConfig}
        onClose={() => {
          setShowConfig(false);
          if (
            initState.selectedSite === "" ||
            initState.selectedSite === "all-sites"
          ) {
            setInitState({
              ...initialState,
              operation: "create",
              // selectedSite:
              //   integrationType === 3 || integrationType === 4
              //     ? ""
              //     : "all-sites",
              selectedPlatforms:
                integrationType === 3 || integrationType === 4 ? [] : ["all"],
              selectedBreakdowns:
                integrationType === 3 || integrationType === 4
                  ? ["appNames"]
                  : ["daily"],
            });
          }
          const bodyEl = document.getElementsByTagName("BODY")[0];
          bodyEl.style.overflow = "hidden";
        }}
        integrationType={integrationType}
        initialState={initState}
        setRefreshData={setRefreshData}
      />
      {loading && <Loading />}
      {!loading && (
        <Page
          title={
            <>
              <div className={"flex justify-between items-center"}>
                <h2 className="text-xl font-semibold text-gray-700 tracking-tight leading-tight">
                  <Translation>
                    {(t) => capitalize(t("scheduled reports"))}
                  </Translation>
                </h2>
                <LanguageSelector customStyle="ml-auto" />
              </div>
            </>
          }
          controls={
            <>
              <div className="my-auto">
                <span className="hidden sm:inline-flex p-1 border bg-gray-200 rounded-md">
                  <Link
                    to="/reports"
                    className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded ${
                      match.params.subaction !== "scheduled"
                        ? "bg-white shadow-md"
                        : ""
                    }`}
                  >
                    {capitalize(t("reports"))}
                  </Link>
                  <Link
                    to="/reports/scheduled"
                    className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded ${
                      match.params.subaction === "scheduled"
                        ? "bg-white shadow-md"
                        : ""
                    }`}
                  >
                    {capitalize(t("scheduled reports"))}
                  </Link>
                </span>
              </div>
              <div className="">
                <Button
                  color="primary"
                  disabled={false}
                  onClick={() => {
                    setInitState({
                      ...initialState,
                      operation: "create",
                      name: "create new report",
                      selectedSite:
                        integrationType === 3 || integrationType === 4
                          ? ""
                          : site?.sites?.[0]?.id
                          ? site.sites[0].id
                          : "all-sites",
                      selectedPlatforms:
                        integrationType === 3 || integrationType === 4
                          ? []
                          : ["all"],
                      selectedBreakdowns:
                        integrationType === 3 || integrationType === 4
                          ? ["appNames"]
                          : ["daily"],
                      emails: user?.email ? [user.email] : [],
                    });
                    setShowConfig(true);
                  }}
                >
                  <Icon.Plus className="inline mr-2" width="16" height="16" />
                  {capitalizeFirst(t("add new report"))}
                </Button>
              </div>
            </>
          }
          children={
            <ReportTable
              reports={reports}
              integrationType={integrationType}
              setRefreshData={setRefreshData}
              setShowDeleteModal={setShowDeleteModal}
              setModalId={setModalId}
              setModalReportName={setModalReportName}
              setShowConfig={setShowConfig}
              initialState={initState}
              setInitialState={setInitState}
            />
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    integrationType: state.auth.user.integrationType,
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(Reports));
