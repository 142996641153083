import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Icon from 'react-feather';
import { withRouter } from 'react-router-dom';

import Badge from '../../common/Badge';
import Card from '../../common/Card';
import Progress from '../../common/Progress';
import Table from '../../common/Table';
import HelperFormatter from '../../../helpers/formatter';
import HelperVideo from '../../../helpers/video';

import thumbnailPlaceholder from '../../../assets/images/thumbnail-placeholder.png';

const TopVideosList = props => {
  const [order, setOrder] = React.useState(-1);
  const [page, setPage] = React.useState(0);
  const [perPage] = React.useState(20);
  const [sort, setSort] = React.useState(0);
  const sortMap = ['views', 'completionRate'];

  const videoSet = [...props.videos].sort((a, b) => {
    if (order === 1) {
      return (a[sortMap[sort]] < b[sortMap[sort]] ? -1 : 1);
    } else {
      return (a[sortMap[sort]] > b[sortMap[sort]] ? -1 : 1);
    }
  });

  return (
    <Card title="top videos">
      <div className="overflow-x-scroll">
        <Table headers={[
          { content: 'video', className: 'text-left' },
          { content: 'duration', className: 'text-right' },
          { content: `completion${sort === 1 ? order === 1 ? ' ↑' : ' ↓' : ''}`, className: 'text-right', onClick: e => { e.preventDefault(); sort === 1 ? setOrder(order * -1) : setSort(1); setPage(0); } },
          { content: `views${sort === 0 ? order === 1 ? ' ↑' : ' ↓' : ''}`, className: 'text-right', onClick: e => { e.preventDefault(); sort === 0 ? setOrder(order * -1) : setSort(0); setPage(0); } },
          { content: 'status', className: 'text-left' },
          { content: 'upload date', className: 'text-left' },
          { content: '', className: 'w-1' }
        ]}>
          {videoSet.slice(page * perPage, (page + 1) * perPage).map((video, index) => (
            <Table.Row key={video.id} dataKey={video.id} className="cursor-pointer even:bg-gray-100 hover:bg-gray-200" onClick={e => {
              e.preventDefault();
              props.history.push(`/analytics/videos/${e.target.closest('tr').dataset.key}`);
            }}>
              <Table.Col>
                <div className="flex">
                  <div className="flex-none">
                    {video.status.indexOf('ERROR') === -1 && video.status !== 'CREATED' && video.status !== 'QUEUED' ? (
                      <img
                        className="mr-3"
                        src={video.thumbnail}
                        onError={e => {
                          e.target.src = thumbnailPlaceholder;
                        }}
                        alt="Video Thumbnail"
                        width="100"
                        height="56"
                      />
                    ) : (
                      <img
                        className="mr-3"
                        src={thumbnailPlaceholder}
                        alt="Video Thumbnail"
                        width="100"
                        height="56"
                      />
                    )}
                  </div>
                  <div className="flex-1">
                    {video.title}
                    <div className="font-mono text-gray-600 text-xs">{video.id}</div>
                  </div>
                </div>
              </Table.Col>
              <Table.Col className="text-right">
                {HelperVideo.formatDuration(video.duration)}
              </Table.Col>
              <Table.Col className="text-right">
                {HelperFormatter.percentage(video.completionRate)}
              </Table.Col>
              <Table.Col className="text-right">
                {video.views.toLocaleString()}
              </Table.Col>
              <Table.Col>
                {HelperVideo.getVideoStatus(video.status).percentage ? (
                  <React.Fragment>
                    <div className="clearfix">
                      <div className="float-left">
                        <strong>
                          {HelperVideo.getVideoStatus(video.status).percentage}%
                        </strong>
                      </div>
                      <div className="float-right">
                        {HelperVideo.getVideoStatus(video.status).description}
                      </div>
                    </div>
                    <Progress size="xs" value={HelperVideo.getVideoStatus(video.status).percentage} />
                  </React.Fragment>
                ) : (
                  <Badge color={HelperVideo.getVideoStatus(video.status).color}>
                    {video.status}
                  </Badge>
                )}
              </Table.Col>
              <Table.Col>
                {moment(video.createdAt).format('MMMM D, YYYY')}
              </Table.Col>
              <Table.Col>
                <Icon.ChevronRight className="h-5 w-5 text-gray-600" />
              </Table.Col>
            </Table.Row>
          ))}
        </Table>
      </div>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm leading-5 text-gray-700">
              Showing <span className="font-medium">{Math.max(1, page * perPage)}</span> to <span className="font-medium">{Math.min((page + 1) * perPage, videoSet.length)}</span> of <span className="font-medium">{videoSet.length}</span> videos
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex shadow-sm">
              {page === 0 ? (
                <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-400 transition ease-in-out duration-150 cursor-not-allowed" aria-label="Previous">
                  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
              ) : (
                <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-600 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-600 transition ease-in-out duration-150" aria-label="Previous" onClick={e => setPage(Math.max(0, page - 1))}>
                  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"/>
                  </svg>
                </button>
              )}
              {page === Math.floor(videoSet.length / perPage) ? (
                <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-400 transition ease-in-out duration-150 cursor-not-allowed" aria-label="Next">
                  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                  </svg>
                </button>
              ) : (
                <button type="button" className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-600 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-600 transition ease-in-out duration-150" aria-label="Next" onClick={e => setPage(Math.min(Math.floor(videoSet.length / perPage), page + 1))}>
                  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                  </svg>
                </button>
              )}
            </nav>
          </div>
        </div>
      </div>
    </Card>
  );
};

TopVideosList.propTypes = {
  history: PropTypes.any,
  videos: PropTypes.array
};

export default withRouter(TopVideosList);
