import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppActionCreators } from "../../store/actions/app";
import * as Icon from "react-feather";

function LanguageSelector({ customStyle = "" }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const selectedLang = useSelector((state) => state.app.language);
  const [showLanguages, setShowLanguages] = useState(false);
  const languages = [
    { code: "en", label: "EN" },
    { code: "tr", label: "TR" },
  ];
  const langMenuRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang, i18n]);

  const handleChange = (lang) => {
    dispatch(AppActionCreators.setLanguage(lang));
    setShowLanguages(false);
  };

  const toggleLanguages = () => {
    setShowLanguages(!showLanguages);
  };

  const handleClickOutside = (event) => {
    if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
      setShowLanguages(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={langMenuRef} className={`relative z-70 ${customStyle}`}>
      <div
        onClick={toggleLanguages}
        className="flex items-center cursor-pointer p-1 bg-transparent rounded-md px-2"
      >
        <Icon.Globe className="text-gray-600 mx-1" width="20" height="20" />
        <span className="rounded-md p-1 font-semibold text-xs">
          {languages.find((l) => l.code === selectedLang)?.label}
        </span>
        <Icon.ChevronDown
          className={`transform ${
            showLanguages ? "rotate-180" : ""
          } transition-transform`}
          width="10"
          height="10"
        />
      </div>
      {showLanguages && (
        <div className="absolute w-full mt-1 bg-gray-100  shadow-lg rounded-lg z-10">
          <div className="p-2 border border-gray-300 rounded-md">
            {languages.map((lang) => (
              <div
                key={lang.code}
                className="px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-md hover:text-indigo-500"
                onClick={() => handleChange(lang.code)}
              >
                {lang.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
