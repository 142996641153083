import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import Loading from "../common/Loading";
import { SiteActionCreators } from "../../store/actions/site";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const sites = useSelector((state) => state?.site?.sites);
  const selectedSite = useSelector((state) => state?.site?.selectedSite);
  const auth = useSelector((state) => state?.auth);

  useEffect(() => {
    if (typeof props.match.params.site === "undefined") {
      props.getSites().then((res) => {
        setLoading(false);
        if (res?.data?.length === 0 && auth.user.integrationType === 3) {
          props.getSites();
          props.history.push("/apps");
        } else if (res?.data?.length === 0) {
          props.history.push("/sites/add");
        } else {
          props.getSites();
        }
      });
    } else {
      setLoading(true);
    }
  }, []);

  if (auth.user.integrationType === 1) {
    props.history.push("/analytics/videos");
  } else if (loading) {
    return <Loading />;
  } else if (
    auth.user.integrationType === 3 ||
    auth.user.integrationType === 4
  ) {
    props.history.push(`/apps`);
  } else if (sites.length) {
    const siteId =
      selectedSite === null || typeof selectedSite === "undefined"
        ? sites[0].id
        : selectedSite.id;
    props.getSite(siteId).then((res) => {
      props.history.push(`/sites/${siteId}`);
    });
  }
  return null;
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  getSite: PropTypes.func.isRequired,
  getSites: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      auth: state.auth,
    }),
    (dispatch) => ({
      getSite: (siteId) => dispatch(SiteActionCreators.getSite(siteId)),
      getSites: () => dispatch(SiteActionCreators.getSites()),
    })
  )(Dashboard)
);
