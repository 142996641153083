import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';

const Notification = (props) => {
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const notifArea = useRef(null);

  const { data } = props;

  useEffect(() => {
    if (data.warning) {
      setTitle(data.warning.title);
      setDesc(data.warning.description);
    } else {
      notifArea.current.remove();
    }
  }, []);

  const closeNotification = () => {
    notifArea.current.remove();
  };

  return (
    <div
      ref={notifArea}
      className="flex absolute bg-indigo-200 m-auto top-0 right-0  mt-6 mr-6 w-1/2 rounded-lg card shadow-md ease-in-out duration-500"
    >
      <div className="m-5 flex flex-col scaled-font font-semibold text-gray-700">
        <span>{title}</span>
        <span>{desc}</span>
      </div>
      <div className="m-5">
        <button onClick={closeNotification}>
          <span className="text-gray-600 flex items-start font-semibold text-3xl">
            &times;
          </span>
        </button>
      </div>
    </div>
  );
};

Notification.propTypes = {
  data: PropTypes.object
};

export default Notification;
