import * as React from 'react';
import * as Icon from 'react-feather';

import Button from '../common/Button';
import Card from '../common/Card';
import Form from '../common/Form';

import logo from './../../assets/images/empower-logo-box.png';

const ResetPassword = (errors, values, errorMessage, infoMessage, onBlur, onChange, onSubmit) => (
  <div className="max-w-sm m-auto h-screen flex flex-col">
    <div className="my-auto">
      <img src={logo} width="150" className="mb-3 m-auto" alt="Empower Logo" />
      {errorMessage ? (
        <div className="bg-red-100 border-l-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow mb-3" role="alert">
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-red-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Something wrong</p>
              <p className="text-sm">{errorMessage}</p>
            </div>
          </div>
        </div>
      ) : ''}
      {infoMessage ? (
        <div className="bg-blue-100 border-l-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow mb-3" role="alert">
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-blue-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Password reset link has been sent!</p>
              <p className="text-sm">{infoMessage}</p>
            </div>
          </div>
        </div>
      ) : ''}
      <Card className="p-2">
        <Card.Body>
          <span className="block text-lg mb-5">Password assistance</span>
          <span className="block text-sm mb-5">Enter the email address associated with your Empower account.</span>
          <Form>
            <Form.FormTextInput
              name="email"
              label="Email Address"
              placeholder="Enter email"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.email}
              error={errors && errors.email}
              type="email"
            />
            <Button
              className="w-full"
              color="login"
              onClick={onSubmit}
              type="submit"
            >
              Continue
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <p className="text-center text-gray-600 text-sm">
        Already have an account? <a href="/auth/login" className="font-bold text-blue-700">Login</a>
      </p>
    </div>
  </div>
);

export default ResetPassword;
