import APIManager from '../../managers/APIManager';

export const VideoActions = {
  CREATE_VIDEO: 'CREATE_VIDEO',
  DELETE_VIDEO: 'DELETE_VIDEO',
  GET_VIDEO: 'GET_VIDEO',
  GET_VIDEO_PLAYBACK: 'GET_VIDEO_PLAYBACK',
  GET_VIDEOS: 'GET_VIDEOS',
  INVALIDATE_VIDEOS: 'INVALIDATE_VIDEOS',
  UPDATE_VIDEO: 'UPDATE_VIDEO'
};

export const VideoActionCreators = {
  createVideo: video => dispatch => APIManager.createVideo(video).then(res => dispatch({
    type: VideoActions.CREATE_VIDEO,
    payload: {
      video: res.data
    }
  })),
  deleteVideo: id => dispatch => APIManager.deleteVideo(id).then(res => dispatch({
    type: VideoActions.DELETE_VIDEO,
    payload: {
      video: res.data
    }
  })),
  getVideo: id => dispatch => APIManager.getVideo(id).then(res => dispatch({
    type: VideoActions.GET_VIDEO,
    payload: {
      video: res.data
    }
  })),
  getVideoPlayback: id => dispatch => APIManager.getVideoPlayback(id).then(res => dispatch({
    type: VideoActions.GET_VIDEO_PLAYBACK,
    payload: {
      video: res.data
    }
  })),
  getVideos: () => dispatch => APIManager.getVideos().then(res => dispatch({
    type: VideoActions.GET_VIDEOS,
    payload: {
      videos: res.data
    }
  })),
  updateVideo: video => dispatch => APIManager.updateVideo(video).then(res => dispatch({
    type: VideoActions.UPDATE_VIDEO,
    payload: {
      video: res.data
    }
  }))
};
