import PropTypes from "prop-types";
import * as React from "react";

import { useTranslation } from "react-i18next";

const Table = ({ className = "", headers, children, footerSection }) => {
  const { t } = useTranslation();
  return (
    <table className={`table-auto text-sm w-full border-collapse ${className}`}>
      {headers.length ? (
        <thead>
          <tr className="text-xs border-b border-gray-200">
            {headers.map((header) => (
              <th
                key={header.content}
                className={`border-b-1 px-2 pt-3 pb-2 uppercase font-semibold text-gray-700 ${
                  header.className || ""
                } ${header.onClick ? "cursor-pointer" : ""}`}
                onClick={header.onClick}
              >
                {t(header.content)}
              </th>
            ))}
          </tr>
        </thead>
      ) : null}
      <tbody>{children}</tbody>
      <tfoot>{footerSection}</tfoot>
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.array,
  children: PropTypes.any,
};

const Col = ({ className = "", children }) => (
  <td className={`px-2 py-2 border-r-0 text-gray-800 nowrap ${className}`}>
    {children}
  </td>
);

Col.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

const Row = ({ className = "", onClick, dataKey, children }) => (
  <tr className={className} onClick={onClick} data-key={dataKey}>
    {children}
  </tr>
);

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  dataKey: PropTypes.string,
  onClick: PropTypes.func,
};
Table.Col = Col;
Table.Row = Row;

export default Table;
