import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import * as Icon from "react-feather";
import check from "../../assets/images/check.png";

const UserSelector = ({
  users,
  selectedUser,
  setSelectedUser,
  setRefreshData,
}) => {
  const [userSelection, setUserSelection] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setUserSelection(false);
    }
  };

  const getUserName = (userId) => {
    var uname = "";
    users.forEach((user) => {
      if (user.id === userId) {
        uname = user.name;
      }
    });
    return uname || "All Users";
  };

  useEffect(() => {
    if (userSelection) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userSelection]);

  const toggleSelectedIndex = (userId) => {
    if (selectedUser !== userId) {
      setSelectedUser(userId);
      setUserSelection(false);
      setRefreshData((prev) => !prev);
    }
  };

  return (
    <div className="relative flex justify-between" ref={dropdownRef}>
      <div
        onClick={() => setUserSelection((prev) => !prev)}
        id="userSelector"
        ref={buttonRef} // Add ref to the button
        className="pr-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold"
      >
        <div className="inline-flex">
          <span
            id="selectedUserTitle"
            className="ml-2 inline group-hover:inline text-xl"
          >
            {getUserName(selectedUser)}
          </span>
        </div>
        {users.length > 1 && (
          <Icon.ChevronDown
            className={`transform ${
              userSelection ? "rotate-180" : ""
            } transition-transform ml-2`}
            width="13"
            height="13"
          />
        )}
      </div>
      <div
        style={{ width: "250px", top: "100%", maxHeight: "80vh" }} // Adjust top to 100% of the button height
        className={`${
          userSelection ? "absolute" : "hidden"
        } z-80 px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none items-center justify-between text-md font-semibold bg-white overflow-scroll`}
      >
        {users.map((user, ind) => (
          <NavLink
            onClick={() => toggleSelectedIndex(user.id)}
            key={user.id}
            to={`/apps/${user.id}`}
            className="px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold text-gray-600"
          >
            <div className="flex">
              <span className="ml-2 inline">{user.name}</span>
            </div>
            {user.id === selectedUser && (
              <img
                style={{ width: "20px" }}
                alt=""
                className="float-right mr-1"
                src={check}
              />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default UserSelector;
