import PropTypes from "prop-types";
import React from "react";
import * as Icon from "react-feather";
import Card from "../common/Card";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/manipulateText";

import HelperFormatter from "../../helpers/formatter";

const StatsBox = ({
  className = "",
  comparison = null,
  description = "",
  series,
  label,
  value = null,
  valueFn = (v) => v,
  formatter = (v) => v,
  xaxisType = "datetime",
  displayChart = true,
}) => {
  const { t } = useTranslation();
  if (series) {
    value = valueFn(series[0].data);
  }
  const isFloating = value - parseInt(value) >= 0.0095;
  let changePercentage = 0;
  let changeTextClass = "text-blue-700";
  if (comparison) {
    changePercentage = parseFloat(
      (((value - comparison) / comparison) * 100).toFixed(2)
    );
    if (changePercentage > 0) {
      changeTextClass = "text-green-700";
    } else if (changePercentage < 0) {
      changeTextClass = "text-red-700";
    }
  }

  return (
    <Card
      className={`${className} flex flex-col justify-end md:justify-center`}
    >
      <div className="p-4 flex flex-col justify-between">
        <div className="text-gray-600 font-semibold scaled-font">
          {capitalize(t(label))}
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse md:justify-between">
          {comparison ? (
            <div
              className={"font-semibold text-lg float-right " + changeTextClass}
              style={{ paddingTop: ".35rem" }}
              title="Compared to same time last week"
            >
              <CountUp
                preserveValue={true}
                formattingFn={HelperFormatter.percentage}
                decimals={2}
                end={changePercentage}
              />
            </div>
          ) : (
            ""
          )}
          <div className="flex-start font-semibold text-gray-700 text-2xl">
            {formatter === HelperFormatter.percentage || isFloating ? (
              <CountUp
                preserveValue={true}
                formattingFn={formatter}
                decimals={2}
                end={value}
              />
            ) : (
              <CountUp
                preserveValue={true}
                formattingFn={formatter}
                end={value}
              />
            )}
          </div>
          {description.length ? (
            <div title={description}>
              <Icon.HelpCircle className="h-4 w-4 float-right text-gray-600 mt-1"></Icon.HelpCircle>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {displayChart && series ? (
        <Chart
          height="70"
          width="100%"
          options={{
            chart: {
              id: series[0].name,
              type: series[0].type || "line",
              sparkline: {
                enabled: true,
              },
            },
            colors: ["#4299e1", "#48bb78", "#9f7aea"],
            dataLabels: {
              enabled: false,
            },
            fill: {
              type: "solid",
              opacity: 1,
              colors: ["#4299e1"],
            },
            legend: {
              show: false,
            },
            stroke: {
              colors: ["#4299e1", "#48bb78", "#9f7aea"],
              curve: "smooth",
              width: 2,
            },
            tooltip: {
              y: {
                formatter,
              },
            },
            xaxis: {
              type: xaxisType,
            },
            yaxis: {
              min: 0,
            },
          }}
          series={series}
        />
      ) : (
        <div className="mt-0"></div>
      )}
    </Card>
  );
};

StatsBox.propTypes = {
  className: PropTypes.string,
  comparison: PropTypes.any,
  description: PropTypes.string,
  formatter: PropTypes.func,
  label: PropTypes.string.isRequired,
  displayChart: PropTypes.bool,
  series: PropTypes.any,
  value: PropTypes.any,
  valueFn: PropTypes.any,
  xaxisType: PropTypes.any,
};

export default StatsBox;
