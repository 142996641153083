import { AnalyticsActions } from '../actions/analytics';
import HelperUtil from '../../helpers/utils';

const INITIAL_STATE = {
  overall: {},
  realtime: {
    player: {
      data: {}
    },
    video: {
      data: {},
      id: null
    }
  },
  video: {}
};

function analytics(state = INITIAL_STATE, action) {
  let overall, realtime, player, video;
  switch (action.type) {
    case AnalyticsActions.GET_OVERALL_VIDEO_ANALYTICS:
      overall = Object.assign({}, state.overall);
      overall.data = action.payload.data;
      return {
        ...state,
        overall
      };
    case AnalyticsActions.GET_REALTIME_PLAYER_DATA:
      realtime = Object.assign({}, state.realtime);
      realtime.player.data = action.payload.data;
      return {
        ...state,
        realtime: {
          ...realtime,
          player
        }
      };
    case AnalyticsActions.GET_REALTIME_VIDEO_DATA:
      realtime = Object.assign({}, state.realtime);
      realtime.video.data = action.payload.data;
      realtime.video.id = action.payload.videoId;
      return {
        ...state,
        realtime: {
          ...realtime,
          video
        }
      };
    case AnalyticsActions.GET_SINGLE_VIDEO_ANALYTICS:
      video = Object.assign({}, state.video);
      video[action.payload.videoId][HelperUtil.getMD5Hash(`${action.payload.startDate}:${action.payload.endDate}`)] = {
        ...action.payload
      };
      return {
        ...state,
        video
      };
    case AnalyticsActions.INVALIDATE_ANALYTICS_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default { analytics };
