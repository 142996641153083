import PropTypes from "prop-types";
import * as React from "react";
import { withRouter } from "react-router-dom";

const Page = ({
  className = "",
  title,
  controls,
  children,
  controlClass = "flex items-center justify-between float-right w-full mt-3",
}) => (
  <div className={`flex-1 min-w-0 flex flex-col pt-4 w-full ${className}`}>
    {title ? (
      <div className="flex-shrink-0">
        <header>
          <div className="py-2 px-4 sm:px-6">
            {typeof title === "string" ? (
              <div className="sm:flex sm:items-center">
                <h2 className="text-xl font-semibold text-gray-700 tracking-tight leading-tight">
                  {title}
                </h2>
              </div>
            ) : (
              title
            )}
            <div className={controlClass}>
              {controls ? (
                <div
                  className={`w-full justify-between ${
                    controlClass.indexOf("flex ") !== -1 ? "flex" : ""
                  }`}
                >
                  {controls}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </header>
      </div>
    ) : (
      ""
    )}
    <div className="m-auto w-100 w-full px-2 md:px-6 pt-4">{children}</div>
  </div>
);

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  controls: PropTypes.any,
  controlClass: PropTypes.string,
  children: PropTypes.any,
};

export default withRouter(Page);
