import React from "react";
import * as Icon from "react-feather";
import APIManager from "../../../managers/APIManager";
import { Translation } from "react-i18next";
import { capitalizeFirst } from "../../../utils/manipulateText";

const DeleteModal = ({
  isVisible,
  onClose,
  modalReportName,
  modalId,
  setRefreshData,
  setShowDeleteModal,
}) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };

  const handleDelete = () => {
    APIManager.deleteScheduledReport(modalId).then(() => {
      setShowDeleteModal((prev) => !prev);
      setRefreshData((prev) => !prev);
    });
  };

  return (
    <div
      id="wrapper"
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 top-50"
      onClick={handleClose}
    >
      <div className="bg-white max-w-sm md:max-w-xl rounded-md">
        <div className="p-3 flex items-center justify-between border-b border-b-gray-300">
          <h3 className="font-semibold text-xl">
            <Translation>{(t) => capitalizeFirst(t("delete"))}</Translation>
          </h3>
          <span className="cursor-pointer" onClick={onClose}>
            <Icon.X />
          </span>
        </div>
        <div className="p-3 border-b border-b-gray-300">
          <p>
            <Translation>
              {(t) =>
                capitalizeFirst(
                  t("are you sure you want to delete the report?")
                )
              }
            </Translation>
            <br></br>
            <div className="text-center">
              <b>{modalReportName}</b>
            </div>
          </p>
        </div>
        <div className="p-3 flex items-center float-right">
          <div>
            <button
              className="text-sm text-white bg-red-500 rounded-md px-4 py-2 mx-2"
              onClick={handleDelete}
            >
              <Translation>{(t) => capitalizeFirst(t("delete"))}</Translation>
            </button>
            <button
              className="text-sm text-white border rounded-md px-4 py-2 bg-gray-500"
              onClick={onClose}
            >
              <Translation>{(t) => capitalizeFirst(t("cancel"))}</Translation>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
