import { combineReducers } from "redux";
import AnalyticsReducers from "./analytics";
import AuthReducers from "./auth";
import PlayerReducers from "./player";
import SiteReducers from "./site";
import VideoReducers from "./video";
import AppReducers from "./app";

const RootReducer = combineReducers({
  ...AnalyticsReducers,
  ...AuthReducers,
  ...PlayerReducers,
  ...SiteReducers,
  ...VideoReducers,
  ...AppReducers,
});

export default RootReducer;
