const formatter = {
  currency: (currency) => {
    return (v) => {
      if (currency === "try") {
        return v.toLocaleString("tr-TR", {
          currency,
          currencyDisplay: "symbol",
          style: "currency",
        });
      } else if (currency === "usd") {
        return v.toLocaleString("en-US", {
          currency,
          currencyDisplay: "symbol",
          style: "currency",
        });
      } else if (currency === "eur") {
        return v.toLocaleString("de-DE", {
          currency,
          currencyDisplay: "symbol",
          style: "currency",
        });
      }
    };
  },
  number: (v) => v?.toLocaleString("en-US"),
  percentage: (v) => v?.toFixed(2) + "%",
};

export const getCurrencySymbol = (currencyCode) => {
  let locale = "";
  if (currencyCode === "try") {
    locale = "tr-TR";
  } else if (currencyCode === "usd") {
    locale = "en-US";
  } else if (currencyCode === "eur") {
    locale = "de-DE";
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(0).replace(/\d/g, "").trim();
};

export default formatter;
