import React from "react";
import StatsBox from "../common/StatsBox";

const StatSection = ({
  totalRevenueData,
  adDataColumns,
  mergedReport,
  adRequestData,
  matchRequestData,
  viewsData,
}) => {
  return (
    <>
      <div className="flex flex-col min-w-fit md:flex-1">
        {totalRevenueData.length > 0 && (
          <StatsBox
            series={[
              {
                name: "Revenue",
                data: totalRevenueData,
                type: "area",
              },
            ]}
            label="total revenue"
            displayChart={false}
            valueFn={(data) => data.map((v) => v.y).reduce((a, v) => a + v)}
            formatter={adDataColumns.revenue.formatter}
            xaxisType={mergedReport.breakdown === "day" ? "datetime" : "string"}
            className="flex-auto"
          />
        )}
      </div>
      {adRequestData.length > 0 && !isNaN(adRequestData[0].y) && (
        <div className="flex flex-col min-w-fit md:flex-1">
          <StatsBox
            series={[
              {
                name: "Ad Request",
                data: adRequestData,
                type: "area",
              },
            ]}
            label="total ad requests"
            displayChart={false}
            valueFn={(data) => data.map((v) => v.y).reduce((a, v) => a + v)}
            formatter={adDataColumns.adRequests.formatter}
            xaxisType={mergedReport.breakdown === "day" ? "datetime" : "string"}
            className="flex-auto"
          />
        </div>
      )}
      {matchRequestData.length > 0 && !isNaN(matchRequestData[0].y) && (
        <div className="flex flex-col min-w-fit md:flex-1">
          <StatsBox
            series={[
              {
                name: "Match Request",
                data: matchRequestData,
                type: "area",
              },
            ]}
            label="total match requests"
            displayChart={false}
            valueFn={(data) => data.map((v) => v.y).reduce((a, v) => a + v)}
            formatter={adDataColumns.adRequests.formatter}
            xaxisType={mergedReport.breakdown === "day" ? "datetime" : "string"}
            className="flex-auto"
          />
        </div>
      )}
      <div className="flex flex-col min-w-fit md:flex-1">
        {viewsData.length > 0 && (
          <StatsBox
            series={[
              {
                name: "Impressions",
                data: viewsData,
                type: "area",
              },
            ]}
            label="total impressions"
            displayChart={false}
            valueFn={(data) => data.map((v) => v.y).reduce((a, v) => a + v)}
            formatter={adDataColumns.views.formatter}
            xaxisType={mergedReport.breakdown === "day" ? "datetime" : "string"}
            className="flex-auto"
          />
        )}
      </div>
    </>
  );
};

export default StatSection;
