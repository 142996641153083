import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AdIntegration from "./AdIntegration";
import AdsTxt from "./AdsTxt";

const Settings = (props) => {
  if (props.match.params.setting === "adstxt") {
    return <AdsTxt />;
  }
  return <AdIntegration user={props.auth.user} />;
};

Settings.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(Settings));
