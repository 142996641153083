import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { Link, withRouter } from "react-router-dom";

import APIManager from "../../managers/APIManager";
import HelperUtils from "../../helpers/utils";
import HelperVideo from "../../helpers/video";

import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";
import Loading from "../common/Loading";
import Page from "../common/Page";

import thumbnailPlaceholder from "../../assets/images/thumbnail-placeholder.png";

const VideoEdit = (props) => {
  const [player, setPlayer] = useState(null);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    APIManager.getVideo(props.video).then((res) => {
      if (res.data.status === "DELETED") {
        props.history.push("/videos");
      }
      setVideo(res.data);
    });
  }, []);

  useEffect(() => {
    if (!video || player || video.status !== "AVAILABLE") {
      return;
    }
    const playerVar = new window.EmpowerPlayer("preview", {
      adTimeout: 1,
      language: "en",
      logo: { image: "" },
      playerName: "empower_console",
    });
    playerVar.setInfoLoadURL(
      `${process.env.REACT_APP_API_BASE}/videos/{VIDEO_ID}/playback`
    );
    playerVar.loadVideo(props.video);
    setPlayer(playerVar);
  }, [video, player]);

  // componentDidMount() {
  //   APIManager.getVideo(props.video).then(res => {
  //     if (res.data.status === 'DELETED') {
  //       props.history.push('/videos');
  //     }
  //     setState({ video: res.data });
  //   });
  // }
  // componentDidUpdate() {
  //   if (!video || player || video.status !== 'AVAILABLE') {
  //     return;
  //   }
  //   const player = new window.EmpowerPlayer('preview', {
  //     adTimeout: 1,
  //     language: 'en',
  //     logo: { image: '' },
  //     playerName: 'empower_console'
  //   });
  //   player.setInfoLoadURL(`${process.env.REACT_APP_API_BASE}/videos/{VIDEO_ID}/playback`);
  //   player.loadVideo(props.video);
  //   setState({ player });
  // }

  const deleteVideo = (e) => {
    e.preventDefault();
    const confirmation = window.confirm(
      "Are you sure you want to delete this video? This operation is irreversible."
    );
    if (!confirmation) {
      return;
    }

    const video = e.target.dataset.video;
    if (typeof video !== "undefined") {
      APIManager.deleteVideo(video).then((res) => {
        this.getVideos();
      });
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    const videoVar = video;
    videoVar[e.target.name] = e.target.value;
    setVideo(videoVar);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    HelperUtils.prompt("Are you sure you want to save the changes?")
      .then(() =>
        APIManager.updateVideo({
          id: video.id,
          title: video.title,
          description: video.description,
          meta: video.meta,
        })
      )
      .then(() => props.history.push("/videos"));
  };

  if (video === null) {
    return <Loading />;
  }

  return (
    <Page
      title={video.title}
      controls={
        <>
          <Button
            className="mr-2"
            RootComponent={Link}
            to={`/analytics/videos/${video.id}`}
            color="primary"
          >
            <Icon.BarChart2
              className="inline mr-1 text-empower-500"
              width="16"
              height="16"
            />
            Analytics
          </Button>
          <Button
            RootComponent={Link}
            to={`/videos/${video.id}/embed`}
            color="primary"
          >
            <Icon.Code
              className="inline text-empower-500 mr-1"
              width="16"
              height="16"
            />
            Get Embed Code
          </Button>
        </>
      }
    >
      {HelperVideo.getVideoStatusAlert(video)}
      <div className="flex">
        <div className="flex-1 w-1/2 mr-5">
          <Card title="Edit Video">
            <Card.Body>
              <Form.FormTextInput
                name="title"
                label="Title"
                onChange={onChange}
                value={video && video.title}
              />
              <Form.Textarea
                name="description"
                label="Description"
                onChange={onChange}
                value={video && video.description}
              />
              <Form.FormTextInput
                readOnly={true}
                label="Source"
                onChange={onChange}
                value={video && video.source}
              />
              <Form.Label>Thumbnail</Form.Label>
              {video.status === "AVAILABLE" ? (
                <img
                  src={video.thumbnail}
                  onError={(e) => {
                    e.target.src = thumbnailPlaceholder;
                  }}
                  alt="Video Thumbnail"
                  width="240"
                  height="135"
                />
              ) : (
                <img
                  src={thumbnailPlaceholder}
                  alt="Video Thumbnail"
                  width="240"
                  height="135"
                />
              )}
            </Card.Body>
            <Card.Footer className="flex justify-between">
              <Button
                className="mr-1"
                color="danger"
                data-video={video.id}
                onClick={deleteVideo}
              >
                <Icon.Trash2 className="inline mr-1" width="16" height="16" />
                Delete
              </Button>
              <Button color="primary" onClick={onSubmit}>
                <Icon.Check
                  className="inline mr-1 text-empower-500"
                  width="16"
                  height="16"
                />
                Save Changes
              </Button>
            </Card.Footer>
          </Card>
        </div>
        <div className="flex-1 w-1/2">
          <Card>
            <div
              id="preview"
              className="empower-player"
              data-empower-id={video.id}
              data-empower-options='{"language":"en"}'
              style={{
                backgroundColor: "#000",
                height: 280,
                width: 498,
              }}
            />
          </Card>
        </div>
      </div>
    </Page>
  );
};

VideoEdit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  video: PropTypes.string.isRequired,
};

export default withRouter(VideoEdit);
