import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import check from "../../assets/images/check.png";
import * as Icon from "react-feather";

function SiteSelector({
  selectedSiteFqdn,
  site,
  sites,
  siteSelection,
  toggleSiteSelect,
  handleClick,
  handleRedirect,
}) {
  const node = useRef();
  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      if (siteSelection) {
        toggleSiteSelect();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [siteSelection]);

  return (
    <div ref={node}>
      <div
        onClick={toggleSiteSelect}
        id="siteSelector"
        className="pr-3 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold"
      >
        <div className="inline-flex">
          <span
            id="selectedSiteTitle"
            className="ml-2 inline group-hover:inline text-xl"
          >
            {selectedSiteFqdn || site.fqdn}
          </span>
        </div>
        {sites.length > 1 && (
          <Icon.ChevronDown
            className={`transform ${
              siteSelection ? "rotate-180" : ""
            } transition-transform ml-2`}
            width="13"
            height="13"
          />
        )}
      </div>

      <div
        style={{ width: "270px", zIndex: "100 !important" }}
        className={`${
          siteSelection ? "absolute" : "hidden"
        } px-3 py-2 my-1 cursor-pointer hover:text-indigo-500 items-center justify-between text-md font-semibold bg-gray-100 border-gray-300 shadow-lg rounded-lg`}
      >
        {sites.map((otherSite) => (
          <NavLink
            onClick={() => {
              handleClick(otherSite);
            }}
            key={otherSite.fqdn}
            to={handleRedirect(otherSite)}
            className="px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold text-gray-600"
          >
            <div className="flex">
              <span className="ml-2 inline group-hover:inline">
                {otherSite.fqdn}
              </span>
            </div>
            {(otherSite.id === site.id ||
              otherSite.virgulId === site.virgulId) && (
              <img
                style={{ width: "20px" }}
                alt=""
                className="float-right mr-1"
                src={check}
              />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default SiteSelector;
