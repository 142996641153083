import React, { useEffect, useState, useRef } from "react";
import { capitalizeFirst, upperCase } from "../../utils/manipulateText";
import { Translation } from "react-i18next";
import dropdown from "../../assets/images/dropdown.png";

const MultiSelectDropdown = ({
  data,
  title,
  customWidth = "w-auto",
  selectedParam,
  setSelectedParam,
  customHeight = "15vh",
  onConfigModal = true,
  customClass = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const wrapperRef = useRef(null);
  const tmpContainer = useRef([]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !event.target.classList.contains("dropdown") &&
        !event.target.classList.contains("dropdown-content")
      ) {
        if (!isOpen) setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFilterQuery(e.target.value);
  };

  const handleItemSelect = (event) => {
    const item = event.target.value;
    if (event.target.checked) {
      var newSelected = [...selectedParam, item];
      setSelectedParam(newSelected);
      tmpContainer.current = newSelected;
    } else {
      var filteredData = selectedParam.filter((i) => i !== item);
      setSelectedParam(filteredData);
      tmpContainer.current = filteredData;
    }
  };

  return (
    <div
      className={`dropdown ${title} ${customClass} relative`}
      ref={wrapperRef}
    >
      {!onConfigModal && (
        <button
          className="sm:inline-flex border bg-gray-200 rounded-md transition-all ease-out transition-fast text-xs font-bold py-2 px-3"
          onClick={handleButtonClick}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            {selectedParam.length > 0 && (
              <span
                className="text-xs font-bold px-1 rounded bg-white shadow mr-1"
                style={{
                  display: "inline-block",
                  minWidth: "20px",
                  textAlign: "center",
                }}
              >
                {selectedParam.length}
              </span>
            )}
            <Translation>
              {(t) => upperCase(t(title.toLowerCase()))}
            </Translation>
          </div>
          <img
            src={dropdown}
            alt=""
            style={{ width: "15px", height: "15px" }}
          />
        </button>
      )}
      {onConfigModal && (
        <button
          className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          onClick={handleButtonClick}
        >
          {selectedParam.length > 0 && (
            <label
              className={`mr-2 transition-all ease-out transition-fast text-sm font-bold px-2 py-1 rounded bg-white shadow`}
            >
              {selectedParam.length}
            </label>
          )}
          {capitalizeFirst(title)}
        </button>
      )}

      {isOpen && (
        <div
          className={`dropdown-content border p-3 rounded-md text-sm font-semibold bg-white absolute z-50 pr-4 ${customWidth}`}
          style={{
            // height: customHeight,
            minHeight: "fit-content",
            maxHeight: customHeight,
            overflowY: "auto",
            overflowX: "hidden",
            boxSizing: "content-box",
          }}
        >
          <div
            style={{ fontSize: 11.5 }}
            className="md:font-light text-md pt-1 pl-5"
          >
            Select {title.toLowerCase()} in order to view selected data.
          </div>
          <span className="inline-block py-1 align-middle m-2 w-full">
            <input
              type="text"
              placeholder="Filter"
              className="pt-1 mx-2 px-1 rounded-md"
              onChange={handleChange}
              value={filterQuery}
            />
            <ul className="mr-3">
              {data
                ?.filter((item) =>
                  item.toLowerCase().includes(filterQuery.toLowerCase())
                )
                .map((item, ind) => (
                  <li
                    key={ind}
                    className="pt-1 pl-3 mt-3 md:bg-gray-50 p-1 m-1 mb-2 w-full pr-5 hover:text-indigo-500"
                  >
                    <label className="block w-full pr-5 cursor-pointer">
                      <input
                        className="pr-5 cursor-pointer"
                        type="checkbox"
                        value={item}
                        onChange={handleItemSelect}
                        checked={selectedParam.includes(item)}
                      />
                      <span className="ml-2 w-full cursor-pointer">{item}</span>
                    </label>
                  </li>
                ))}
            </ul>
          </span>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
