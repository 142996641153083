import PropTypes from 'prop-types';
import * as React from 'react';

import Loading from '../common/Loading';
import VideoAnalytics from './VideoAnalytics';

const Analytics = props => {
    let childComponent = <Loading />;
    if (props.match.params.category === 'videos') {
      childComponent = <VideoAnalytics />;
      if (props.match.params.id) {
        childComponent = <VideoAnalytics video={props.match.params.id} />;
      }
    } else {
      props.history.push('/analytics/videos');
    }
    return childComponent;
}

Analytics.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default Analytics;
