import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import _ from "lodash";

import APIManager from "../../managers/APIManager";
import Card from "../common/Card";

import Page from "../common/Page";
import Loading from "../common/Loading";

import HelperFormatter, { getCurrencySymbol } from "../../helpers/formatter";

import { SiteActionCreators } from "../../store/actions/site";
import S from "../../store";
import { Translation, useTranslation } from "react-i18next";
import { capitalizeFirst, upperCase } from "../../utils/manipulateText";

import Control from "./Control";
import LanguageSelector from "../../components/common/LanguageSelector";

import Notification from "../../components/common/Notification";
import { initialState } from "../reports/ScheduledReports/constants/actionTypes";
import ConfigModal from "../reports/ScheduledReports/ConfigModal";
import SiteSelector from "../common/SiteSelector";
import ReportTable from "./ReportTable";
import MessageText from "../common/MessageText";
import ComboChart from "./ComboChart";
import StatSection from "./StatSection";
import SavedFilters from "../common/QuickFilters";
import Tooltip from "../common/Tooltip";
import QuickFilters from "../common/QuickFilters";

const SiteDetail = (props) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [sites, setSites] = useState();
  const [siteSelection, setSiteSelection] = useState(false);
  const adDataColumns = {
    revenue: {
      index: 0,
      code: "revenue",
      color: "#2b6cb0",
      enabled: true,
      hidden: false,
      name: "Revenue",
      padding: "42px",
      formatter: HelperFormatter.currency(props.auth.user.currency),
    },
    adRequests: {
      index: 1,
      code: "adRequests",
      color: "#c05621",
      enabled: false,
      hidden: true,
      name: "Ad Requests",
      padding: "42px",
      formatter: HelperFormatter.number,
    },
    matchedRequests: {
      index: 2,
      code: "matchedRequests",
      color: "#b83280",
      enabled: false,
      hidden: true,
      name: "Matched Ad Requests",
      padding: "20px",
      formatter: HelperFormatter.number,
    },
    views: {
      index: 3,
      code: "views",
      color: "#6b46c1",
      enabled: true,
      hidden: true,
      name: "Impressions",
      padding: "42px",
      formatter: HelperFormatter.number,
    },
    clicks: {
      index: 4,
      code: "clicks",
      color: "#b7791f",
      enabled: true,
      hidden: true,
      name: "Clicks",
      padding: "42px",
      formatter: HelperFormatter.number,
    },
    coverage: {
      index: 5,
      code: "coverage",
      color: "#c53030",
      enabled: false,
      hidden: true,
      name: "Coverage",
      padding: "42px",
      formatter: HelperFormatter.percentage,
    },
    ctr: {
      index: 6,
      code: "ctr",
      color: "#2f855a",
      enabled: true,
      hidden: true,
      name: "CTR",
      padding: "42px",
      formatter: HelperFormatter.percentage,
    },
    requestECPM: {
      index: 7,
      code: "requestECPM",
      color: "#4a5568",
      enabled: false,
      hidden: true,
      name: "Request eCPM",
      padding: "20px",
      formatter: HelperFormatter.currency(props.auth.user.currency),
    },
    eCPM: {
      index: 8,
      code: "eCPM",
      color: "#2c7a7b",
      enabled: false,
      hidden: true,
      name: "eCPM",
      padding: "42px",
      formatter: HelperFormatter.currency(props.auth.user.currency),
    },
    viewabilityReal: {
      index: 9,
      code: "viewabilityReal",
      color: "#4c51bf",
      enabled: true,
      hidden: true,
      name: "Viewability",
      padding: "42px",
      formatter: HelperFormatter.percentage,
    },
  };
  const [cache, setCache] = useState(null);
  const { t, i18n } = useTranslation();
  const currencySymbol = getCurrencySymbol(props.auth.user.currency);

  const [chart, setChart] = useState({
    combinedChart: {
      options: {
        chart: {
          id: "combined-site-revenue",
          type: "line",
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
            },
            autoSelected: "selection",
          },
        },
        colors: ["#4299e1", "#48bb78"],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "60%",
          },
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y, { series, seriesIndex, dataPointIndex, w }) => {
              if (
                w.config.series[seriesIndex].name ===
                capitalizeFirst(t("revenue"))
              ) {
                return HelperFormatter.currency(props.auth.user.currency)(y);
              } else {
                return y.toLocaleString();
              }
            },
          },
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: [
          {
            seriesName: capitalizeFirst(t("revenue")),
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: (val) => {
                if (val >= 1000000) {
                  return `${(val / 1000000).toFixed(1)}M`.replace(".0", "");
                } else if (val >= 1000) {
                  return `${(val / 1000).toFixed(1)}K`.replace(".0", "");
                }
                return val.toFixed(0);
              },
            },
            title: {
              text: capitalizeFirst(t("revenue")) + ` (${currencySymbol})`,
              rotate: -90,
            },
          },
          {
            seriesName: capitalizeFirst(t("impression")),
            opposite: true,
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: (val) => {
                if (val >= 1000000) {
                  return `${(val / 1000000).toFixed(1)}M`.replace(".0", "");
                } else if (val >= 1000) {
                  return `${(val / 1000).toFixed(1)}K`.replace(".0", "");
                }
                return val;
              },
            },
            title: {
              text: capitalizeFirst(t("impression")),
              rotate: -90,
            },
          },
        ],
        fill: {
          opacity: 1,
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "12px",
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            vertical: 4,
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 8,
          },
        },
      },
      series: [
        {
          name: capitalizeFirst(t("revenue")),
          type: "bar",
          data: [],
        },
        {
          name: capitalizeFirst(t("impression")),
          type: "line",
          data: [],
        },
      ],
    },
  });

  const [loading, setLoading] = useState(true);
  const [platformFilter, setPlatformFilter] = useState("all");
  const [breakdowns, setBreakdowns] = useState(["daily"]);
  const [adPlacements, setAdPlacements] = useState([]);
  const adPlacementsRef = useRef([]);
  const [adPlacement, setAdPlacement] = useState(useRef());
  const [mergedReport, setMergedReport] = useState({
    breakdown: "daily",
    report: [],
  });
  const [site, setSite] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("DD.MM.YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(0, "days").format("DD.MM.YYYY")
  );
  const [table, setTable] = useState({ tbl: [], dim: [] });
  const [refreshReport, setRefreshReport] = useState(false);
  const platformFilterRef = useRef("all");

  const [selectedSiteFqdn, setSelectedSiteFqdn] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [initState, setInitState] = useState({ ...initialState });
  const [showConfig, setShowConfig] = useState(false);
  const [tableFooter, setTableFooter] = useState({});
  const [reportDimensions, setReportDimensions] = useState([]);
  const [timeoutErr, setTimeoutErr] = useState(false);
  const [serverErr, setServerErr] = useState(false);
  const sortOrder = useRef(null);
  const dimRef = useRef([]);
  const tblRef = useRef([]);

  const toggleSiteSelect = () => {
    setSiteSelection((prev) => !prev);
  };

  const setDates = (range) => {
    if (typeof range === "undefined") {
      return;
    }
    const dates = {};
    switch (range) {
      case "LH":
        dates.startDate = moment().format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "T":
        dates.startDate = moment().format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "Y":
        dates.startDate = moment().subtract(1, "days").format("DD.MM.YYYY");
        dates.endDate = moment().subtract(1, "days").format("DD.MM.YYYY");
        break;
      case "MTD":
        dates.startDate = moment().startOf("month").format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "7D":
        dates.startDate = moment().subtract(7, "days").format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "30D":
        dates.startDate = moment().subtract(30, "day").format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "LM":
        dates.startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("DD.MM.YYYY");
        dates.endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case "L3M":
        dates.startDate = moment()
          .subtract(3, "month")
          .startOf("month")
          .format("DD.MM.YYYY");
        dates.endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case "L6M":
        dates.startDate = moment()
          .subtract(6, "month")
          .startOf("month")
          .format("DD.MM.YYYY");
        dates.endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      default:
        return;
    }
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };

  const getSiteFqdn = (siteId) => {
    var fqdn = "all-sites";
    for (var site of sites) {
      if (site.id === siteId || site.virgulId === siteId) {
        fqdn = site.fqdn;
      }
    }
    return fqdn;
  };

  const toggleSelectedIndex = (e) => {
    setSelectedSiteFqdn(e.fqdn || getSiteFqdn(e));
    setSiteSelection(false);
    dimRef.current = e.dimensions;
    setLoading(true);
    setRefreshReport((prev) => !prev);
    if (e?.fqdn)
      S.store
        .dispatch(SiteActionCreators.getSite(e.id))
        .then(() => setLoading(false));
  };

  let isFetchReportResolved = false;
  let isTimeoutResolved = false;

  const fetchReport = (reportParams, refreshData) => {
    return new Promise((resolve, reject) => {
      APIManager.getSiteReport(props.site, reportParams)
        .then((res) => {
          if (!isTimeoutResolved) {
            isFetchReportResolved = true;
            refreshData(res);
            resolve(res);
          }
        })
        .catch((e) => {
          if (!isTimeoutResolved) {
            reject(e);
          }
        });
    });
  };

  const timeoutPromise = new Promise((resolve) => {
    setTimeout(() => {
      if (!isFetchReportResolved) {
        isTimeoutResolved = true;
        resolve("Timeout");
      }
    }, 15000);
  });
  const getReport = (
    useCache,
    platformFilterArg = platformFilterRef.current,
    startDateArg = startDate,
    endDateArg = endDate
  ) => {
    return new Promise((resolve) => {
      var useCacheVar = useCache || false;
      const refreshData = (res) => {
        const refreshDim = APIManager.getSite(props.site).then(
          (res) => res.data.dimensions
        );
        if (!res.data || res.data.error) {
          return;
        }
        if (platformFilterArg !== "all") {
          res.data.results = res.data.results.filter(
            (value) => value.platform === platformFilterArg
          );
        }

        const report = res.data.results.reverse();
        const breakdownKey =
          breakdowns.indexOf("monthly") !== -1
            ? "month"
            : breakdowns.indexOf("daily") !== -1
            ? "day"
            : breakdowns.indexOf("zone") !== -1
            ? "zone"
            : breakdowns.indexOf("tag") !== -1
            ? "tag"
            : breakdowns.indexOf("platform") !== -1
            ? "platform"
            : breakdowns.indexOf("site") !== -1
            ? "site"
            : breakdowns.indexOf("app") !== -1
            ? "app"
            : "";

        const mergedReport = Object.values(
          report.reduce((pv, cv, ci, arr) => {
            let key = "All";
            if (breakdownKey === "day") {
              key =
                moment(
                  cv.day.split(".").reverse().join("-") + " 03:00"
                ).unix() * 1000;
            } else if (breakdownKey === "month") {
              key = cv.month;
            } else if (breakdownKey === "zone") {
              key = cv.zone.name + " (" + cv.zone.id + ")";
            } else if (breakdownKey === "app") {
              key = cv.app;
            } else if (breakdownKey === "platform") {
              key = cv.app;
            }
            pv[key] = pv[key] || {
              key: key,
              revenue: 0,
              view: 0,
              request: 0,
              match: 0,
            };
            pv[key].revenue += cv.spent[props.auth.user.currency];
            pv[key].view += cv.view;
            pv[key].request += cv.request;
            pv[key].match += cv.match;
            return pv;
          }, {})
        );
        const chartRevenue = Object.assign({}, chart.combinedChart);
        chartRevenue.tooltip = { y: [] };
        chartRevenue.options.xaxis.type =
          breakdownKey === "day" ? "datetime" : "string";
        chartRevenue.options.yaxis = [
          {
            seriesName: capitalizeFirst(t("revenue")),
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: (val) => {
                if (val >= 1000000) {
                  return `${(val / 1000000).toFixed(1)}M`.replace(".0", "");
                } else if (val >= 1000) {
                  return `${(val / 1000).toFixed(1)}K`.replace(".0", "");
                }
                return val.toFixed(0);
              },
            },
            title: {
              text: capitalizeFirst(t("revenue")) + ` (${currencySymbol})`,
              rotate: -90,
            },
          },
          {
            seriesName: capitalizeFirst(t("impression")),
            opposite: true,
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: (val) => {
                if (val >= 1000000) {
                  return `${(val / 1000000).toFixed(1)}M`.replace(".0", "");
                } else if (val >= 1000) {
                  return `${(val / 1000).toFixed(1)}K`.replace(".0", "");
                }
                return val;
              },
            },
            title: {
              text: capitalizeFirst(t("impression")),
              rotate: -90,
            },
          },
        ];
        chartRevenue.series = [];
        chartRevenue.series = [
          {
            name: capitalizeFirst(t("revenue")),
            type: "bar",
            data: mergedReport.map((result) => ({
              x: result.key,
              y: Number(result.revenue.toFixed(0)),
            })),
          },
          {
            name: capitalizeFirst(t("impression")),
            type: "line",
            data: mergedReport.map((result) => ({
              x: result.key,
              y: result.view,
            })),
          },
        ];
        chartRevenue.tooltip.y.push({
          formatter: adDataColumns.revenue.formatter,
        });

        setCache(res);
        setChart({ ...chart, combinedChart: chartRevenue });
        setMergedReport({ breakdown: breakdownKey, report: mergedReport });

        if (res.data.results?.length) {
          const allBreakdowns = [
            "month",
            "day",
            "tag",
            "platform",
            "app",
            "zone",
            "site",
            "statType",
          ];
          var grandTotal = {};

          const keys = [];
          const bdKeys = [];
          Object.keys(report[0]).forEach((key) => {
            if (allBreakdowns.indexOf(key) === -1) keys.push(key);
            else bdKeys.push(key);
          });
          grandTotal = { ...report[0] };
          keys.forEach((key) => (grandTotal[key] = 0));
          bdKeys.forEach((key) => (grandTotal[key] = ""));

          if (bdKeys.indexOf("site") !== -1)
            grandTotal["site"] = { name: "", id: "" };
          if (keys.indexOf("spent") !== -1)
            grandTotal["spent"] = { try: 0, eur: 0, usd: 0 };
          if (keys.indexOf("viewability") !== -1)
            grandTotal["viewability"] = { calculated: 0, real: 0 };
          if (keys.indexOf("ecpm") !== -1)
            grandTotal["ecpm"] = { try: 0, usd: 0, eur: 0 };
          if (keys.indexOf("requestEcpm") !== -1)
            grandTotal["requestEcpm"] = { try: 0, usd: 0, eur: 0 };

          report.forEach((rep) => {
            keys.forEach((key) => {
              if (key === "spent") {
                grandTotal[key]["try"] += rep[key]["try"];
                grandTotal[key]["usd"] += rep[key]["usd"];
                grandTotal[key]["eur"] += rep[key]["eur"];
              } else if (key === "viewability") {
                grandTotal[key]["calculated"] +=
                  rep[key]["calculated"] * rep["view"];
                grandTotal[key]["real"] += rep[key]["real"] * rep["view"];
              } else if (key === "ctr" || key === "coverage") {
                grandTotal[key] = rep[key];
              } else if (key === "ecpm" || key === "requestEcpm") {
                grandTotal[key] = { ...rep[key] };
              } else {
                grandTotal[key] += rep[key];
              }
            });
          });

          grandTotal[breakdownKey] = "grand total";

          if (keys.indexOf("ecpm") !== -1) {
            grandTotal["ecpm"]["try"] =
              (grandTotal["spent"]["try"] * 1000) / grandTotal["view"];
            grandTotal["ecpm"]["eur"] =
              (grandTotal["spent"]["eur"] * 1000) / grandTotal["view"];
            grandTotal["ecpm"]["usd"] =
              (grandTotal["spent"]["usd"] * 1000) / grandTotal["view"];
          }

          if (keys.indexOf("requestEcpm") !== -1) {
            grandTotal["requestEcpm"]["try"] =
              (grandTotal["spent"]["try"] * 1000) / grandTotal["request"];
            grandTotal["requestEcpm"]["eur"] =
              (grandTotal["spent"]["eur"] * 1000) / grandTotal["request"];
            grandTotal["requestEcpm"]["usd"] =
              (grandTotal["spent"]["usd"] * 1000) / grandTotal["request"];
          }

          if (keys.indexOf("viewability") !== -1) {
            grandTotal["viewability"]["calculated"] /= grandTotal["view"];
            grandTotal["viewability"]["real"] /= grandTotal["view"];
          }

          if (keys.indexOf("ctr") !== -1)
            grandTotal["ctr"] = grandTotal["click"] / grandTotal["view"];
          if (keys.indexOf("coverage") !== -1)
            grandTotal["coverage"] =
              (grandTotal["match"] / grandTotal["request"]) * 100;
          setTableFooter({ ...grandTotal });
        }
        const tmp = [].concat(res.data.results).reverse();

        tblRef.current = tmp;
        setTable({
          ...table,
          tbl: tmp,
          dim: dimRef.current,
        });
        resolve();
      };

      if (useCacheVar && cache) {
        refreshData(cache);
      } else {
        const reportParams = {
          day: breakdowns.indexOf("daily") !== -1,
          month: breakdowns.indexOf("monthly") !== -1,
          tag: false,
          tagContent: breakdowns.indexOf("tag") !== -1,
          zone: breakdowns.indexOf("zone") !== -1,
          startDate: startDateArg,
          endDate: endDateArg,
          appNames: breakdowns.indexOf("app") !== -1,
          platform: breakdowns.indexOf("platform") !== -1,
          site: breakdowns.indexOf("site") !== -1,
          platformFilter: platformFilter,
          embedAd: breakdowns.indexOf("statType") !== -1,
        };
        adPlacementsRef.current.forEach((val, ind, arr) => {
          reportParams["zoneIds[" + ind + "]"] = parseInt(val);
        });
        if (platformFilterArg !== "all") {
          reportParams.platform = true;
        }
        Promise.race([fetchReport(reportParams, refreshData), timeoutPromise])
          .then((result) => {
            if (result === "Timeout") {
              setTimeoutErr(true);
              if (!isFetchReportResolved) {
                APIManager.reportsFallback(
                  { ...reportParams },
                  props.selectedSite.virgulId,
                  props.auth.user.email,
                  props.auth.user.currency,
                  props.auth.user.integrationType === 3 ||
                    props.auth.user.integrationType === 4
                    ? "app"
                    : "site"
                );
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setServerErr(true);
          });
      }
    });
  };

  const getSite = async () => {
    const res = await APIManager.getSite(props.site);
    if (initRender.current) {
      setTable({ tbl: tblRef.current, dim: res.data.dimensions });
      initRender.current = false;
    }
    setSite(res.data);
    dimRef.current = res.data.dimensions;
    return res.data;
  };

  const initRender = useRef(true);

  useEffect(() => {
    dispatch(SiteActionCreators.getSites()).then((res) => {
      if (res.data.length === 0) {
        return;
      }
      setSiteId(props.site);
      processSites(res);
    });
    setLoading(true);
    setTimeoutErr(false);
    setServerErr(false);
    setReportDimensions([...breakdowns]);
    getSite().then(() => {
      getReport().then(() => {
        setLoading(false);
      });
    });
  }, [refreshReport, language]);

  const processSites = (res) => {
    const sites = res.data;
    const selectedSite =
      sites.find((site) => site.id === props.site) || sites[0];

    if (sites.length > 1) {
      const reorderedSites = [
        sites[sites.length - 1],
        ...sites.slice(0, sites.length - 1),
      ];
      setSites(reorderedSites);
    } else {
      setSites(sites);
    }

    if (selectedSite) {
      setSelectedSiteFqdn(selectedSite.fqdn);
      setSiteId(selectedSite.id);
    }
  };

  useEffect(() => {
    if (siteId === props.site) {
      return;
    }
    setSiteId(props.site);
    getSite().then(() => {
      getReport().then(() => {
        setLoading(false);
      });
    });
  });

  const toggleFilters = () => {
    const filters = document.querySelector(".filters");
    filters.classList.toggle("hidden");
    filters.classList.toggle("flex");
    filters.classList.toggle("flex-row");
  };

  let pageContent = null;
  const filterClass = window.innerWidth < 1440 ? "float-left" : "float-right";
  if (!loading) {
    if (site.onboardingStep >= 0) {
      props.history.push(`/sites/${site.id}/onboarding`);
      return null;
    }

    const adRequestData = mergedReport.report.map((row) => ({
      x: row.key,
      y: row.request,
    }));
    const matchRequestData = mergedReport.report.map((row) => ({
      x: row.key,
      y: row.match,
    }));
    const viewsData = mergedReport.report.map((row) => ({
      x: row.key,
      y: row.view,
    }));
    const totalRevenueData = mergedReport.report.map((row) => ({
      x: row.key,
      y: row.revenue,
    }));

    if (!totalRevenueData.length) {
      pageContent = (
        <div className="py-4 text-orange-600 text-md text-center">
          <Icon.Info className="inline mr-2" width="16" height="16" />
          <Translation>
            {(t) =>
              capitalizeFirst(
                t("there is no data to show for this time period.")
              )
            }
          </Translation>
        </div>
      );
    } else {
      pageContent = (
        <>
          <div className="md:grid md:grid-cols-5 md:gap-4 mb-5">
            <div className="grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-1 md:col-span-1">
              <StatSection
                totalRevenueData={totalRevenueData}
                adDataColumns={adDataColumns}
                mergedReport={mergedReport}
                adRequestData={adRequestData}
                matchRequestData={matchRequestData}
                viewsData={viewsData}
              />
            </div>
            <div className="md:col-span-4">
              <Card title="revenue & impression" type="chart">
                <div className="p-2">
                  <ComboChart chart={chart} />
                </div>
              </Card>
            </div>
          </div>
          <ReportTable
            table={table}
            site={site}
            reportDimensions={reportDimensions}
            breakdowns={breakdowns}
            tableFooter={tableFooter}
            sortOrder={sortOrder}
            setTable={setTable}
          />
        </>
      );
    }
  }

  return (
    <>
      <ConfigModal
        isVisible={showConfig}
        onClose={() => {
          setShowConfig(false);
          if (
            initState.selectedSite === "" ||
            initState.selectedSite === "all-sites"
          ) {
            setInitState({
              ...initialState,
              siteBreakdowns: initState.siteBreakdowns,
              sitePlatforms: initState.sitePlatforms,
              selectedSite: initState.selectedSite,
              selectedSitePlacements: initState.selectedSitePlacements,
              selectedUserApps: initState.selectedUserApps,
              selectedUserCountries: initState.selectedUserCountries,
              selectedPlatforms:
                props.auth.user.integrationType === 3 ||
                props.auth.user.integrationType === 4
                  ? []
                  : ["all"],
              selectedBreakdowns:
                props.auth.user.integrationType === 3 ||
                props.auth.user.integrationType === 4
                  ? ["appNames"]
                  : ["daily"],
            });
          } else {
            setInitState({
              ...initialState,
            });
          }
          const bodyEl = document.getElementsByTagName("BODY")[0];
          bodyEl.style.overflow = "hidden";
        }}
        integrationType={props.auth.user.integrationType}
        initialState={initState}
      />

      <Page
        controlClass="w-full items-center justify-between"
        title={
          site &&
          sites?.length > 0 && (
            <>
              <div className="flex justify-between relative my-1">
                <SiteSelector
                  selectedSiteFqdn={selectedSiteFqdn}
                  site={site}
                  sites={sites}
                  siteSelection={siteSelection}
                  toggleSiteSelect={toggleSiteSelect}
                  handleClick={(otherSite) => {
                    toggleSelectedIndex(otherSite);
                    setBreakdowns(["daily"]);
                    setPlatformFilter("all");
                    adPlacementsRef.current = [];
                    setAdPlacements([]);
                    setStartDate(
                      moment().subtract(7, "days").format("DD.MM.YYYY")
                    );
                    setEndDate(
                      moment().subtract(0, "days").format("DD.MM.YYYY")
                    );
                  }}
                  handleRedirect={(otherSite) => {
                    return `/sites/${otherSite.id}`;
                  }}
                  siteId={siteId}
                />
                <div className="md:hidden flex">
                  <button className={"filter-icon"} onClick={toggleFilters}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V4.586C1.00006 4.85119 1.10545 5.10551 1.293 5.293L7.707 11.707C7.89455 11.8945 7.99994 12.1488 8 12.414V19L12 15V12.414C12.0001 12.1488 12.1055 11.8945 12.293 11.707L18.707 5.293C18.8946 5.10551 18.9999 4.85119 19 4.586V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289Z"
                        stroke="#728195"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="hidden md:flex flex-row items-center justify-end w-72 divide-x">
                  <QuickFilters
                    sites={sites}
                    setRefreshReport={setRefreshReport}
                    handleClick={(item) => {
                      toggleSelectedIndex(item.siteId || "all-sites");
                      setBreakdowns(item?.breakdowns || ["daily"]);
                      setPlatformFilter(item?.platform || "all");
                      adPlacementsRef.current = [];
                      setAdPlacements(item?.placements || []);
                      setDates(item?.dateRange);
                    }}
                    handleRedirect={(siteId) => {
                      return `/sites/${siteId}`;
                    }}
                  />
                  <LanguageSelector customStyle="ml-2 float-right" />
                </div>
              </div>
              <Notification data={site} />
            </>
          )
        }
        controls={
          <Control
            filterClass={filterClass}
            state={{
              site,
              platformFilter,
              loading,
              adPlacement,
              startDate,
              endDate,
              adPlacements,
              setAdPlacements,
              setCache,
              setLoading,
              setPlatformFilter,
              setEndDate,
              setStartDate,
            }}
            setShowConfig={setShowConfig}
            initState={initState}
            setInitState={setInitState}
            site={site}
            platformFilterRef={platformFilterRef}
            adPlacementsRef={adPlacementsRef}
            getReport={getReport}
            setRefreshReport={setRefreshReport}
            breakdowns={breakdowns}
            setBreakdowns={setBreakdowns}
          />
        }
      >
        {timeoutErr ? (
          <MessageText
            message={
              "Your report took a long time to generate. You can check its status on the reports page."
            }
          />
        ) : serverErr ? (
          <MessageText
            message={
              "A server side error has occured. Please try refreshing the report again."
            }
          />
        ) : (
          <>
            {loading && <Loading />}
            {pageContent}
          </>
        )}
      </Page>
    </>
  );
};

SiteDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  site: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    selectedSite: state.site.selectedSite,
  };
};

export default connect(mapStateToProps)(withRouter(SiteDetail));
