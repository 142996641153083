import React, { useState, useEffect } from "react";
import APIManager from "../../../managers/APIManager";
import Loading from "../../common/Loading";
import AsyncReportTable from "./AsyncReportTable";

import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Page from "../../common/Page";
import LanguageSelector from "../../common/LanguageSelector";
import { Translation, useTranslation } from "react-i18next";
import { capitalize, upperCase } from "../../../utils/manipulateText";
import * as Icon from "react-feather";
import Tooltip from "../../common/Tooltip";

const AsyncReports = ({ match }) => {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    setLoading(true);
    APIManager.getAsyncReports().then((res) => {
      for (const obj of res?.data) {
        obj.params = JSON.parse(obj.params.replace(/\\/g, ""));
      }

      setReports(res.data);
      setLoading(false);
    });
  }, [refreshData]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Page
          title={
            <>
              <div className={"flex justify-between items-center"}>
                <h2 className="text-xl font-semibold text-gray-700 tracking-tight leading-tight">
                  <Translation>{(t) => capitalize(t("reports"))}</Translation>
                </h2>
                <LanguageSelector customStyle="ml-auto" />
              </div>
            </>
          }
          controls={
            <>
              <div className="my-auto">
                <span className="inline-flex p-1 border bg-gray-200 rounded-md">
                  <Link
                    to="/reports"
                    className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded ${
                      match.params.subaction !== "scheduled"
                        ? "bg-white shadow-md"
                        : ""
                    }`}
                  >
                    {capitalize(t("reports"))}
                  </Link>
                  <Link
                    to="/reports/scheduled"
                    className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded ${
                      match.params.subaction === "scheduled"
                        ? "bg-white shadow-md"
                        : ""
                    }`}
                  >
                    {capitalize(t("scheduled reports"))}
                  </Link>
                </span>
              </div>
              <Tooltip
                title="refresh data"
                content={"you can refresh data."}
                customStyle="w-fit"
              >
                <div className="my-auto">
                  <span className="inline-flex p-1 border bg-gray-200 rounded-md">
                    {
                      <button
                        className={`transition-all ease-out transition-fast font-bold px-3 py-1 rounded`}
                        onClick={() => setRefreshData((prev) => !prev)}
                      >
                        <Icon.RefreshCw
                          className="text-gray-600"
                          width="15"
                          height="15"
                        />
                      </button>
                    }
                  </span>
                </div>
              </Tooltip>
            </>
          }
          children={<AsyncReportTable reports={reports} />}
        />
      )}
    </>
  );
};

export default withRouter(AsyncReports);
