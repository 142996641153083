export const capitalize = (input) => {
  return input
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
  // return input.replace(/(^\w{1})|(\s{1}\w{1})/g, s => s.toUpperCase());
};

export const capitalizeFirst = (input) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const upperCase = (input) => {
  return input.toUpperCase();
};

export const upperCaseFirstWord = (input) => {
  const words = input.toLowerCase().split(" ");
  words[0] = words[0].toUpperCase();
  return words;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
};
