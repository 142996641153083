import { AppActions } from "../actions/app";

const INITIAL_STATE = {
  sidebarStatus: false,
  language: "en",
  quickFilterStatus: false,
  selectedQuickFilter: {},
};

function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppActions.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarStatus: !state.sidebarStatus,
      };
    case AppActions.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      };
    case AppActions.SET_QUICK_FILTER_MODAL:
      return {
        ...state,
        quickFilterStatus: action.payload.newState,
      };
    case AppActions.SET_SELECTED_QUICK_FILTER:
      return {
        ...state,
        selectedQuickFilter: action.payload.newFilter,
      };
    default:
      return state;
  }
}

export default { app };
