import * as React from 'react';

const Loading = () => {
  return (
    <div className="loading">
      <div>
      </div>
      <div>
      </div>
    </div>
  );
};

export default Loading;
