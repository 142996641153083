import md5 from 'blueimp-md5';
import { trim } from 'lodash';

const utils = {
  generateMD5Hash: str => md5(str),
  getGravatarURL: email => {
    const hash = md5(trim(email));
    return `https://www.gravatar.com/avatar/${hash}`;
  },
  prompt: message => {
    return new Promise(resolve => {
      const confirmation = window.confirm(message);
      if (confirmation) {
        return resolve();
      }
    });
  }
};

export default utils;
