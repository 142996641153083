import PropTypes from "prop-types";
import * as React from "react";
import * as Icon from "react-feather";
import HelperExcel from "../../helpers/exporter";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/manipulateText";

const Card = ({ className = "", type, title, data, children }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`rounded-md transition duration-500 ease-in-out card shadow-md bg-white w-full ${className}`}
    >
      {title ? (
        <div className="font-semibold text-md pt-4 px-4">
          {capitalize(t(title))}
          {data ? (
            <button
              onClick={() => HelperExcel.exportExcel(data, title, true)}
              className="pull-right text-sm py-1"
            >
              <Icon.Download
                className="pull-left mx-2 text-gray-600"
                width="16"
                height="16"
              />
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.any,
  children: PropTypes.any,
};

const Body = ({ className = "", children }) => (
  <div className={`px-5 py-5 ${className}`}>{children}</div>
);

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

const Footer = ({ className = "", children }) => (
  <div className={`border-t-2 border-gray-200  px-5 py-5 ${className}`}>
    {children}
  </div>
);

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

Card.Body = Body;
Card.Footer = Footer;

export default Card;
