import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import { Link, NavLink } from "react-router-dom";
import _ from "lodash";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Badge from "../common/Badge";
import Page from "../common/Page";
import Card from "../common/Card";

import desktopMasthead from "./../../assets/images/desktop-masthead.png";
import desktopIncontent from "./../../assets/images/desktop-incontent.png";
import desktopSidebar from "./../../assets/images/desktop-sidebar.png";
import desktopTower from "./../../assets/images/desktop-tower.png";
import desktopFooter from "./../../assets/images/desktop-footer.png";
import desktopSticky from "./../../assets/images/desktop-sticky.png";
import desktopVideo from "./../../assets/images/desktop-video.png";

import mobileMasthead from "./../../assets/images/mobile-masthead.png";
import mobileIncontent from "./../../assets/images/mobile-incontent.png";
import mobileFooter from "./../../assets/images/mobile-footer.png";
import mobileSticky from "./../../assets/images/mobile-sticky.png";
import mobileVideo from "./../../assets/images/mobile-video.png";

import { SiteActionCreators } from "../../store/actions/site";
import S from "../../store";
import dropdown from "../../assets/images/down.png";
import check from "../../assets/images/check.png";
import Loading from "../common/Loading";

import { useTranslation } from "react-i18next";
import {
  capitalize,
  upperCase,
  capitalizeFirst,
} from "../../utils/manipulateText";
import LanguageSelector from "../common/LanguageSelector";
import SiteSelector from "../common/SiteSelector";

const AdIntegration = (props) => {
  const toggleSelectedIndex = (e) => {
    setSiteSelection(false);
    setLoading(true);
    S.store
      .dispatch(SiteActionCreators.getSite(e.id))
      .then(() => setLoading(false));
  };
  const { t, i18n } = useTranslation();
  const { site, sites } = props;
  const [siteSelection, setSiteSelection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState(site);
  const [zones, setZones] = useState(selectedSite.zones || []);
  const [selectedPlacement, setSelectedPlacement] = useState(null);
  const [selectedPlacementName, setSelectedPlacementName] = useState(null);
  const [selectedPlacementId, setSelectedPlacementId] = useState(null);
  const [siteList, setSiteList] = useState(sites);

  const adPlacements = {
    masthead: {
      name: "masthead",
      zoneType: "masthead",
      desc: "Placed after your header and before your content. Runs on the desktop on  970x250 as a normal set up and 320x50 on mobile.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopMasthead, mobileMasthead],
    },
    footer: {
      name: "footer",
      zoneType: "footer",
      desc: "Placed on the very bottom of your website as 970x25 on desktop and 300x250 on mobile.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopFooter, mobileFooter],
    },
    sticky: {
      name: "sticky",
      zoneType: "sticky",
      desc: "There's no need for you to do anything. The sticky ad codes tailored to your website will be deployed automatically by your account manager. Should you have any inquiries, don't hesitate to get in touch with us.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopSticky, mobileSticky],
    },
    sidebar_top: {
      name: "sidebar above the fold",
      zoneType: "sidebar_top",
      desc: "Placed as 300x250 on the top of the sidebar, only available on desktop. Provide high viewability rates, highly recommended.",
      zoneID: [],
      mobile: false,
      desktop: true,
      placeholder: [desktopSidebar],
    },
    tower_right: {
      name: "tower right sticky",
      zoneType: "tower_right",
      desc: "Exist only on desktop and set based on the design of your website. It is automatically placed by us with a suitable size as 300x600 or 160x600 on right side of the page.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopTower],
    },
    tower_left: {
      name: "tower left sticky",
      zoneType: "tower_left",
      desc: "Exist only on desktop and set based on the design of your website. It is automatically placed by us with a suitable size as 300x600 or 160x600 on left side of the page.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopTower],
    },
    incontent: {
      name: "in content",
      zoneType: "incontent",
      desc: "Placed between the paragraphs/images of your content. Highly recommended to place in-content ads in each 2 paragraph/image or section in your content. The revenue coming from in-content ads increases while the number of in-content ads increases, we suggest you place at least 3 in-content ads on your content pages.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopIncontent, mobileIncontent],
    },
    preroll: {
      name: "preroll",
      zoneType: "preroll",
      desc: "There's no need for you to do anything. The preroll ad codes tailored to your website will be deployed automatically by your account manager. Should you have any inquiries, don't hesitate to get in touch with us.",
      zoneID: [],
      mobile: true,
      desktop: true,
      placeholder: [desktopVideo, mobileVideo],
    },
    sidebanner: {
      name: "side banner",
      zoneType: "sidebanner",
      desc: "",
      zoneID: [],
      mobile: false,
      desktop: true,
      placeholder: [],
    },
  };

  useEffect(() => {
    const filteredSites = sites.filter(
      (s) => s.virgulId !== "all-sites" && s.virgulId !== ""
    );

    setSiteList([...filteredSites]);
    const defaultSite = filteredSites[0] || site;
    if (site.virgulId === "all-sites" || site.virgulId === "") {
      setSelectedSite(defaultSite);
      S.store.dispatch(SiteActionCreators.getSite(defaultSite.id)).then(() => {
        setZones(defaultSite.zones || []);
        setLoading(false);
      });
    } else {
      setSelectedSite(site);
      setZones(site.zones || []);
    }
  }, [site, sites]);

  useEffect(() => {
    const newZones =
      selectedSite.zones?.map((zone) => ({
        ...zone,
        zoneType: getZoneType(zone.name.toLowerCase()),
      })) || [];
    setZones(newZones);
  }, [selectedSite.zones]);

  const getZoneType = (zoneName) => {
    if (zoneName.includes("tower")) {
      return zoneName.includes("left") || zoneName.includes("sol")
        ? "tower_left"
        : "tower_right";
    } else if (zoneName.includes("sticky")) {
      return "sticky";
    } else if (zoneName.includes("footer")) {
      return "footer";
    } else if (zoneName.includes("masthead")) {
      return "masthead";
    } else if (zoneName.includes("preroll")) {
      return "preroll";
    } else if (zoneName.includes("content")) {
      return "incontent";
    } else if (zoneName.includes("banner")) {
      return "sidebanner";
    }
    return "";
  };

  const empowerSDKIntegration = () => {
    return (
      <>
        <SyntaxHighlighter
          language="html"
          className="my-4"
          style={codeStyle}
          useInlineStyles="true"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
          wrapLines={true}
        >
          {`<script async type="text/javascript" id="empower-ad-script" src="https://static.virgul.com/theme/mockups/adcode/outside.js?namespace=empower&class=empower-ad&site=${
            selectedSite.code
          }${
            selectedSite.category !== "site_geneli"
              ? `&category=${selectedSite.category}`
              : ""
          }${
            selectedSite.page !== "site_geneli"
              ? `&page=${selectedSite.page}`
              : ""
          }"></script>`}
        </SyntaxHighlighter>
        <SyntaxHighlighter
          language="javascript"
          style={codeStyle}
          useInlineStyles="true"
        >
          {`<script>
  window.Empower = windowEmpower || [];
  Empower.push({
    action: 'init',
    params: {
      site: '${selectedSite.id}'
    }
  });
</script>`}
        </SyntaxHighlighter>
      </>
    );
  };

  const virgulSDKIntegration = () => {
    return (
      <>
        <SyntaxHighlighter
          language="javascript"
          style={codeStyle}
          useInlineStyles="true"
        >
          {`<script async type="text/javascript" id="empower-ad-script" src="https://static.virgul.com/theme/mockups/adcode/outside.js?namespace=empower&class=empower-ad&site=${selectedSite.code}"></script>`}
        </SyntaxHighlighter>
      </>
    );
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Page
          title={
            <>
              <div className={"flex justify-between items-center"}>
                <SiteSelector
                  site={props.site}
                  sites={siteList}
                  siteSelection={siteSelection}
                  toggleSiteSelect={(e) => setSiteSelection((prev) => !prev)}
                  handleClick={(e) => {
                    toggleSelectedIndex(e);
                  }}
                  handleRedirect={(e) => {
                    return `/settings`;
                  }}
                />

                <LanguageSelector customStyle="ml-auto" />
              </div>
            </>
          }
        >
          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/3 mr-4">
              <Card>
                <span className="sm:inline-flex p-1 border bg-gray-200 rounded-md mx-4 mt-4">
                  <Link
                    to="/settings"
                    className="transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded bg-white shadow-md"
                    data-tagcontent="0"
                  >
                    {upperCase(t("ad integration"))}
                  </Link>
                  <Link
                    to="/settings/adstxt"
                    className="transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded"
                    data-tagcontent="1"
                  >
                    ADS.TXT
                  </Link>
                </span>
                <Card.Body>
                  <p className="text-xs text-gray-600">
                    {capitalizeFirst(
                      t(
                        "you can see our best practice ad placements and their ad unit codes below, which we’ve decided on based ..."
                      )
                    )}
                  </p>
                  <p className="text-xs text-gray-600 mt-2">
                    {capitalizeFirst(
                      t(
                        "the main aim of those placements is making the most money while still providing a great user experience ..."
                      )
                    )}
                  </p>
                  <h2 className="text-lg block font-semibold text-gray-800 pt-4">
                    1) {capitalize(t("integration"))}
                  </h2>
                  <div className="text-sm text-gray-700 mt-2">
                    {i18n.language === "en" ? (
                      <div>
                        Make sure that you've added
                        {props.user && props.user.integrationType === 2
                          ? virgulSDKIntegration()
                          : empowerSDKIntegration()}
                        <span className="my-2">
                          before your <strong>&#60;/body&#62;</strong> tag.
                        </span>
                      </div>
                    ) : (
                      <div>
                        <strong>&#60;/body&#62;</strong> tag’inizden önce
                        aşağıdaki kodu eklediğinizden emin olun:
                        {props.user && props.user.integrationType === 2
                          ? virgulSDKIntegration()
                          : empowerSDKIntegration()}
                        <span className="my-2"></span>
                      </div>
                    )}
                  </div>
                  <h2 className="text-lg block font-semibold text-gray-800 pt-4">
                    2) {capitalize(t("ad placements"))}
                  </h2>
                  <div className="grid grid-cols-2 gap-8 mt-4">
                    {zones
                      .filter((zone) => zone?.id > 0)
                      .map((zone) => (
                        <div
                          key={zone?.id}
                          className="flex justify-between items-center flex-wrap"
                        >
                          <span className="text-md font-semibold text-gray-700 py-2">
                            {zone?.name + " (" + zone?.id + ")"}
                          </span>
                          <button
                            onClick={(e) => {
                              setSelectedPlacement(
                                zone?.zoneType === ""
                                  ? "" + zone?.id
                                  : zone?.zoneType
                              );
                              setSelectedPlacementName(zone?.name);
                              setSelectedPlacementId(zone?.id);
                            }}
                            to=""
                            className="bg-white border border-gray-100 shadow-md px-3 py-1 inline-flex justify-center rounded-lg text-xs hover:bg-empower-500 hover:text-white hover:border-empower-600"
                          >
                            <Icon.Code
                              className="cursor-pointer text-gray-600 hover:text-gray-900 mr-2"
                              width="16"
                              height="16"
                            />
                            {upperCase(t("get code"))}
                          </button>
                        </div>
                      ))}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="md:w-1/3">
              {typeof adPlacements[selectedPlacement] !== "undefined" ? (
                <Card title={adPlacements[selectedPlacement].name}>
                  <Card.Body>
                    {adPlacements[selectedPlacement].desc && (
                      <p className="text-xs text-gray-600">
                        {t(adPlacements[selectedPlacement].desc)}
                      </p>
                    )}
                    {!["preroll", "sticky"].includes(
                      adPlacements[selectedPlacement].zoneType
                    ) ? (
                      <div>
                        <div className="text-md text-gray-700 my-4">
                          {capitalize(t("get your code below"))}
                        </div>
                        <div className="block text-sm text-gray-800 font-semibold my-2">
                          {adPlacements[selectedPlacement].mobile ? (
                            <Badge color="info" className="mr-1">
                              mobile
                            </Badge>
                          ) : null}
                          {adPlacements[selectedPlacement].desktop ? (
                            <Badge color="purple" className="mr-1">
                              desktop
                            </Badge>
                          ) : null}
                        </div>
                        {_.map(
                          zones.filter(
                            (zone) =>
                              zone?.name === selectedPlacementName &&
                              zone?.id === selectedPlacementId
                          ),
                          (zoneObj, i) => {
                            return (
                              <SyntaxHighlighter
                                key={i}
                                className="block mb-2"
                                language="html"
                                style={codeStyle}
                                useInlineStyles="false"
                              >
                                {`<div data-empower-zone="${
                                  zoneObj.id
                                }" class="empower-ad${
                                  adPlacements[selectedPlacement].zoneType ===
                                  "sticky"
                                    ? " empower-sticky empower-sticky-bottom"
                                    : ""
                                }${
                                  adPlacements[selectedPlacement].zoneType ===
                                  "tower_left"
                                    ? " empower-tower-left"
                                    : ""
                                }${
                                  adPlacements[selectedPlacement].zoneType ===
                                  "tower_right"
                                    ? " empower-tower-right"
                                    : ""
                                }"></div>`}
                              </SyntaxHighlighter>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                    {adPlacements[selectedPlacement].placeholder.length !==
                      0 && (
                      <div className="text-md text-gray-700 my-4">
                        {capitalize(t("example placements"))}
                      </div>
                    )}
                    {_.map(
                      adPlacements[selectedPlacement].placeholder,
                      (img, i) => {
                        return (
                          <img
                            alt=""
                            src={img}
                            key={i}
                            className="mx-auto shadow-md mb-4"
                          ></img>
                        );
                      }
                    )}
                  </Card.Body>
                </Card>
              ) : (
                zones
                  .filter((zone) => zone?.id === parseInt(selectedPlacement))
                  .map((zone) => {
                    return (
                      <Card
                        key={zone?.id}
                        title={zone?.name + " (" + zone?.id + ")"}
                      >
                        <Card.Body>
                          {zone?.name.toLowerCase().indexOf("roll") !== -1 ? (
                            <p className="text-xs text-gray-600">
                              {t(adPlacements.preroll.desc)}
                            </p>
                          ) : zone?.name
                              .toLowerCase()
                              .indexOf("interstitial") !== -1 ? (
                            <div>
                              <p className="text-xs text-gray-600">
                                {t(
                                  "There's no need for you to do anything. The interstitial ad codes tailored to your website will be deployed automatically by your account manager. Should you have any inquiries, don't hesitate to get in touch with us."
                                )}
                              </p>
                            </div>
                          ) : (
                            <p className="text-xs text-gray-600">
                              {zone?.name} ad unit with {zone?.id} ID to display
                              banner ads.
                            </p>
                          )}
                          {zone?.name.toLowerCase().indexOf("roll") === -1 &&
                          zone?.name.toLowerCase().indexOf("interstitial") ===
                            -1 ? (
                            <div>
                              <div className="text-md text-gray-700 my-4">
                                {capitalize(t("get your code below"))}
                              </div>
                              <div className="block text-sm text-gray-800 font-semibold my-2">
                                {zone?.mobile ? (
                                  <Badge color="info" className="mr-1">
                                    mobile
                                  </Badge>
                                ) : null}
                                {zone?.desktop ? (
                                  <Badge color="purple" className="mr-1">
                                    desktop
                                  </Badge>
                                ) : null}
                              </div>
                              <SyntaxHighlighter
                                className="block mb-2"
                                language="html"
                                style={codeStyle}
                                useInlineStyles="false"
                              >
                                {`<div data-empower-zone="${
                                  zone?.id
                                }" class="empower-ad${
                                  zone?.name.toLowerCase().indexOf("ticky") !==
                                  -1
                                    ? " empower-sticky empower-sticky-bottom"
                                    : ""
                                }${
                                  zone?.name
                                    .toLowerCase()
                                    .toLowerCase()
                                    .indexOf("tower") !== -1 &&
                                  zone?.name
                                    .toLowerCase()
                                    .toLowerCase()
                                    .indexOf("left") !== -1
                                    ? " empower-tower-left"
                                    : ""
                                }${
                                  zone?.name
                                    .toLowerCase()
                                    .toLowerCase()
                                    .indexOf("tower") !== -1 &&
                                  zone?.name
                                    .toLowerCase()
                                    .toLowerCase()
                                    .indexOf("right") !== -1
                                    ? " empower-tower-right"
                                    : ""
                                }"></div>`}
                              </SyntaxHighlighter>
                            </div>
                          ) : null}
                        </Card.Body>
                      </Card>
                    );
                  })
              )}
            </div>
          </div>
        </Page>
      )}
    </>
  );
};

AdIntegration.propTypes = {
  site: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  site: state.site.selectedSite,
  sites: state.site.sites,
});

export default connect(mapStateToProps)(AdIntegration);
