const url = {
  getQueryParameter(name, defaultValue) {
    var query = window.location.search.substring(1);
    var params = query.split("&");
    for (var i = 0; i < params.length; i++) {
      var pair = params[i].split("=");
      if (decodeURIComponent(pair[0]) === name) {
        return decodeURIComponent(pair[1]);
      }
    }
    return defaultValue === undefined ? "/" : defaultValue;
  },
};

export default url;
