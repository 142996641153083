const playerAppearanceParams = {
  backgroundColor: '#2F2F2F',
  foregroundColor: '#FFFFFF',
  highlightColor: '#0C43A8',
  showFullScreenButton: true,
  showLogo: null,
  showReplay: true,
  showSeekBar: true,
  showShareButton: false,
  showTimeDisplay: true,
  showTitleBar: true
};

const playerBehaviorParams = {
  autoplay: true,
  loop: false
};

const playerStatusMap = {
  AVAILABLE: {
    color: 'success'
  },
  DELETED: {
    color: 'danger'
  }
};

const player = {
  getDefaultAppearanceParams: () => playerAppearanceParams,
  getDefaultBehaviorParams: () => playerBehaviorParams,
  getPlayerStatus: status => playerStatusMap[status]
};

export default player;
