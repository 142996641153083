import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import * as Icon from "react-feather";
import { NavLink, withRouter } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/manipulateText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppActionCreators } from "../../store/actions/app";

const Wrapper = ({ header, location, children }) => {
  const { t } = useTranslation();

  const [popupVisibilty, setPopupVisibility] = useState(false);
  const [isSidebarWide, setIsSidebarWide] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const popupRef = useRef();
  const dispatch = useDispatch();
  const selectedLang = useSelector((state) => state.app.language);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisibility(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  function togglePopup() {
    if (popupVisibilty === true) {
      setPopupVisibility(false);
    } else {
      setPopupVisibility(true);
    }
  }

  function toggleSidebar() {
    if (windowWidth > 768) {
      setIsSidebarWide(!isSidebarWide);
    } else {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }
  }

  return (
    <div className="relative min-h-screen md:flex">
      {/* <!-- mobile menu bar --> */}
      <div className="bg-gray-50 text-black shadow flex justify-between md:hidden sticky">
        {/* <!-- logo --> */}
        <header className="flex-none pt-5">
          <div className="mx-5">
            <NavLink to={header.logo.target}>
              {/* <img src={header.logo.image.small} alt={header.logo.title} className="outline-none block xl:hidden" width="40" /> */}
              <img
                src={header.logo.image.large}
                alt={header.logo.title}
                className="outline-none block"
                width="120"
              />
            </NavLink>
          </div>
        </header>

        {/* <!-- mobile menu button --> */}
        <button
          onClick={toggleSidebar}
          className="mobile-menu-button p-4 focus:outline-none focus:bg-indigo-500"
        >
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* <!-- sidebar --> */}

      <div
        className={`sidebar flex flex-col h-screen bg-white pt-8 pl-4 z-70 md:pl-4 md:pr-2 w-3/4 ${
          isSidebarWide ? "md:w-72" : "md:w-24"
        } space-y-6 py-7 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition-all duration-300 ease-in-out shadow md:rounded-tr-lg md:rounded-br-lg`}
      >
        {windowWidth > 768 && (
          <button
            onClick={toggleSidebar}
            className="absolute right-0 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold p-2 rounded-l-lg border-l focus:outline-none"
          >
            <FontAwesomeIcon
              icon={faAngleDoubleRight}
              className={`transform duration-300 ${
                isSidebarWide ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
        )}
        <header
          className={`flex flex-none md:items-center ${
            isSidebarWide ? "mt-8 px-3" : "mt-5"
          }`}
        >
          <div
            className={`mx-5 md:mx-1 md:flex ${
              windowWidth >= 768 ? "" : ""
            } md:w-full`}
          >
            <NavLink to={header.logo.target}>
              <img
                src={
                  windowWidth < 768 || isSidebarWide
                    ? header.logo.image.large
                    : header.logo.image.small
                }
                alt={header.logo.title}
                className="header-logo outline-none block transition-all ease-in-out duration-100"
                style={{ width: "120px" }}
              />
            </NavLink>
          </div>
        </header>

        <div className="mx-2 mt-4 z-30 flex-grow">
          <nav>
            {(header.account.integrationType === 3 ||
              header.account.integrationType === 4) && (
              <NavLink
                to="/apps"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-base font-semibold ${
                  location.pathname.indexOf("/apps") === 0
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="App Revenue"
              >
                <div className="flex items-center justify-center">
                  <Icon.DollarSign
                    className={`pt-1 ${
                      location.pathname.indexOf("/apps") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2">{capitalize(t("app revenue"))}</span>
                  )}
                </div>
              </NavLink>
            )}

            {header.account.integrationType === 4 ||
            header.account.integrationType === 0 ||
            header.account.integrationType === 2 ? (
              <NavLink
                to="/sites"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-base font-semibold ${
                  location.pathname.indexOf("/sites") === 0
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="Revenue"
              >
                <div className="flex items-center justify-center">
                  <Icon.DollarSign
                    className={`pt-1 ${
                      location.pathname.indexOf("/sites") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2 inline group-hover:inline">
                      {capitalize(t("revenue"))}
                    </span>
                  )}
                </div>
              </NavLink>
            ) : (
              ""
            )}

            {header.account.integrationType === 0 ||
            header.account.integrationType === 1 ? (
              <NavLink
                to="/analytics"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold ${
                  location.pathname.indexOf("/analytics") === 0
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="Analytics"
              >
                <div className="flex items-center justify-center">
                  <Icon.BarChart2
                    className={`pt-1 ${
                      location.pathname.indexOf("/analytics") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2">
                      {capitalize(t("video analytics"))}
                    </span>
                  )}
                </div>
              </NavLink>
            ) : (
              ""
            )}

            {header.account.integrationType === 0 ||
            header.account.integrationType === 1 ? (
              <NavLink
                to="/videos"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold ${
                  location.pathname.indexOf("/videos") === 0 &&
                  location.pathname.indexOf("analytics") === -1
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="Videos"
              >
                <div className="flex items-center justify-center">
                  <Icon.Film
                    className={`pt-1 ${
                      location.pathname.indexOf("/videos") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2">
                      {capitalize(t("video manager"))}
                    </span>
                  )}
                </div>
              </NavLink>
            ) : (
              ""
            )}

            {header.account.integrationType === 0 ||
            header.account.integrationType === 1 ? (
              <NavLink
                to="/players"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold ${
                  location.pathname.indexOf("/players") === 0
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="Players"
              >
                <div className="flex items-center justify-center">
                  <Icon.Play
                    className={`pt-1 ${
                      location.pathname.indexOf("/players") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2">{capitalize(t("players"))}</span>
                  )}
                </div>
              </NavLink>
            ) : (
              ""
            )}

            {header.account.integrationType === 0 ||
            header.account.integrationType === 2 ||
            header.account.integrationType === 3 ||
            header.account.integrationType === 4 ? (
              <NavLink
                to="/payments"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold ${
                  location.pathname.indexOf("/payments") === 0
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="Payments"
              >
                <div className="flex items-center justify-center">
                  <Icon.CreditCard
                    className={`pt-1 ${
                      location.pathname.indexOf("/payments") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2">{capitalize(t("payments"))}</span>
                  )}
                </div>
              </NavLink>
            ) : (
              ""
            )}

            <NavLink
              to="/reports"
              className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold ${
                location.pathname.indexOf("/reports") === 0
                  ? "text-indigo-500"
                  : "text-gray-600"
              }`}
              title="Reports"
            >
              <div className="flex items-center justify-center">
                <Icon.List
                  className={`pt-1 ${
                    location.pathname.indexOf("/reports") === 0
                      ? "text-indigo-500"
                      : "text-gray-600"
                  }`}
                  width="23"
                  height="23"
                />
                {(isSidebarWide || windowWidth < 768) && (
                  <span className="ml-2">{capitalize(t("reports"))}</span>
                )}
              </div>
            </NavLink>

            {header.account.integrationType !== 3 && (
              <NavLink
                to="/settings"
                className={`px-3 py-2 mb-1 rounded-md cursor-pointer hover:text-indigo-500 outline-none flex items-center justify-between text-md font-semibold ${
                  location.pathname.indexOf("/settings") === 0
                    ? "text-indigo-500"
                    : "text-gray-600"
                }`}
                title="Settings"
              >
                <div className="flex items-center justify-center">
                  <Icon.Settings
                    className={`pt-1 ${
                      location.pathname.indexOf("/settings") === 0
                        ? "text-indigo-500"
                        : "text-gray-600"
                    }`}
                    width="23"
                    height="23"
                  />
                  {(isSidebarWide || windowWidth < 768) && (
                    <span className="ml-2">
                      {capitalize(t("site settings"))}
                    </span>
                  )}
                </div>
              </NavLink>
            )}
          </nav>
        </div>

        <div className="flex-none items-center transition-transform ease-in-out duration-200">
          <div className="p-1 md:p-3 pb-8 flex w-100 relative justify-center items-center">
            <img
              src={header.account.avatar}
              alt=""
              className="block rounded-full object-cover flex-none w-10 h-10 cursor-pointer"
              onClick={togglePopup}
            />

            <div
              ref={popupRef}
              className={`popup-menu ${
                popupVisibilty === true ? "absolute" : "hidden"
              } bg-white border bg-r shadow-md rounded-md p-4 pb-2 z-50`}
              onClick={() => setPopupVisibility(false)}
            >
              <NavLink
                to="/auth/changepassword"
                className="block text-gray-600 font-semibold text-sm pb-2 hover:text-empower-500"
              >
                {capitalize(t("change password"))}
              </NavLink>
              <div
                className="block text-gray-600 font-semibold text-sm pb-2 hover:text-empower-500 cursor-pointer"
                onClick={() =>
                  dispatch(
                    AppActionCreators.setLanguage(
                      selectedLang === "en" ? "tr" : "en"
                    )
                  )
                }
              >
                {`${capitalize(t("change language"))}`}
              </div>
              <NavLink
                to="/auth/logout"
                className="block text-gray-600 font-semibold text-sm pb-2 hover:text-red-500"
              >
                {capitalize(t("logout"))}
              </NavLink>
            </div>

            {(isSidebarWide || windowWidth < 768) && (
              <>
                <div className="block group-hover:block flex-1 ml-2 text-left font-semibold text-gray-600">
                  <span className="block">{header.account.name}</span>
                  <span className="block font-normal text-xs">
                    {header.account.description}
                  </span>
                </div>
                <div className="relative">
                  <button onClick={togglePopup}>
                    <Icon.MoreHorizontal className="w-6 h-6 mt-2 text-gray-600 focus:outline-none"></Icon.MoreHorizontal>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <main className="flex-1 bg-gray-50 overflow-y-auto h-screen">
        {children}
      </main>
    </div>
  );
};

Wrapper.propTypes = {
  header: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.any,
};

export default withRouter(Wrapper);
