import * as React from 'react';

import Button from '../common/Button';
import Card from '../common/Card';
import Page from '../common/Page';

const Authorize = (errors, values, errorMessage, onBlur, onChange, onSubmit) => (
  <Page>
    <Card className="max-w-xl m-auto" title={`Authorize ${values.client.name}`}>
      <Card.Body>
        <div className="flex">
          <div className="flex-1">
            <p>{values.client.name} would like permission to access your account.</p>
            {(values.client.readAccess || values.client.writeAccess) &&
              <>
                <p className="py-2">Authorizing will grant:</p>
                <ul className="list-disc ml-5">
                  {values.client.readAccess &&
                    <li><strong>Read access</strong> to your players, sites and videos</li>
                  }
                  {values.client.writeAccess &&
                    <li><strong>Write access</strong> to your players, sites and videos</li>
                  }
                </ul>
              </>
            }
            {(!values.client.readAccess || !values.client.writeAccess) &&
              <>
                <p className="py-2">Authorizing will <strong>not</strong> grant:</p>
                <ul className="list-disc ml-5">
                  {!values.client.readAccess &&
                    <li><strong>Read access</strong> to your players, sites and videos</li>
                  }
                  {!values.client.writeAccess &&
                    <li><strong>Write access</strong> to your players, sites and videos</li>
                  }
                </ul>
              </>
            }
          </div>
          <div className="flex-none">
            <img src={values.client.logo} className="ml-10" alt={values.client.name} width="100" />
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="align-right">
        <Button className="inline" color="primary" onClick={onSubmit}>Authorize</Button>
      </Card.Footer>
    </Card>
    {values.client.verified
      ? <p className="text-center text-gray-600 text-sm">
        This application is developed by Empower.
      </p>
      : <p className="text-center text-gray-600 text-sm">
        This application is <strong>not</strong> developed by Empower.
      </p>
    }
  </Page>
);

export default Authorize;
