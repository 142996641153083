import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppActionCreators } from "../../store/actions/app";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  capitalizeFirst,
  upperCase,
} from "../../utils/manipulateText";
import Form from "./Form";
import Button from "./Button";
import APIManager from "../../managers/APIManager";
import { modalConstants } from "../reports/ScheduledReports/constants/modalConstants";
import MultiselectBox from "./MultiselectBox";
import LoadingBackDrop from "./LoadingBackDrop";

const QuickFilterModal = ({ sites, setRefreshItems }) => {
  const { t } = useTranslation();
  const selectedQuickFilter = useSelector(
    (state) => state.app.selectedQuickFilter
  );
  const quickFilterStatus = useSelector((state) => state.app.quickFilterStatus);
  const selectedSite = useSelector((state) => state.site.selectedSite);
  const integrationType = useSelector(
    (state) => state.auth.user.integrationType
  );

  const dispatch = useDispatch();

  const [site, setSite] = useState(
    selectedQuickFilter?.site === null ? "all-sites" : selectedSite.id
  );
  const [name, setName] = useState(selectedQuickFilter?.name || "");
  const [dateRange, setDateRange] = useState(
    selectedQuickFilter?.dateRange || ""
  );
  const [selectedBreakdowns, setSelectedBreakdowns] = useState(
    selectedQuickFilter?.breakdowns || ["daily"]
  );
  const [selectedPlatforms, setSelectedPlatforms] = useState(
    selectedQuickFilter?.platform || "all"
  );
  const [selectedPlacements, setSelectedPlacements] = useState(
    selectedQuickFilter?.placements || []
  );
  const [siteData, setSiteData] = useState({});
  const [loading, setLoading] = useState(false);

  const clearData = () => {
    setSite(
      selectedQuickFilter?.site
        ? selectedSite?.id
          ? selectedSite?.virgulId
          : ""
        : ""
    );
    setName("");
    setDateRange(modalConstants.initialQuickDateButtons[0].range);
    setSelectedBreakdowns(["daily"]);
    setSelectedPlatforms("all");
    setSelectedPlacements([]);
  };

  const handleClose = () => {
    dispatch(AppActionCreators.setSelectedQuickFilter(null));
    dispatch(AppActionCreators.setQuickFilterModal(false));
    clearData();
  };

  const handleSubmit = () => {
    const filter = {
      siteId: site === "all-sites" ? null : site === "" ? null : site,
      name: name,
      breakdowns: selectedBreakdowns,
      platform: selectedPlatforms,
      placements: selectedPlacements,
      dateRange: dateRange,
    };
    if (selectedQuickFilter.mode === 1) {
      setLoading(true);
      APIManager.updateFilter(selectedQuickFilter.id, filter)
        .then((res) => {
          setLoading(false);
          handleClose();
          setRefreshItems((prev) => !prev);
        })
        .catch((e) => {
          console.error("Failed to update filter:", e);
          setLoading(false);
        });
    } else {
      setLoading(true);
      APIManager.saveFilter(filter)
        .then((res) => {
          setLoading(false);
          handleClose();
          setRefreshItems((prev) => !prev);
        })
        .catch((e) => {
          console.error("Failed to save filter:", e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedQuickFilter) {
      if (selectedQuickFilter.mode === 1) {
        setSite(
          selectedQuickFilter.site === null
            ? integrationType !== 3 && integrationType !== 4
              ? "all-sites"
              : ""
            : selectedQuickFilter.site
        );
      } else {
        setSite(
          selectedQuickFilter?.site
            ? selectedQuickFilter.site
            : selectedSite.id
            ? selectedSite.id
            : selectedSite.virgulId
        );
      }
    }
    setName(selectedQuickFilter?.name || "");
    setDateRange(
      selectedQuickFilter?.dateRange ||
        modalConstants.initialQuickDateButtons[0].range
    );
    setSelectedBreakdowns(selectedQuickFilter?.breakdowns || ["daily"]);
    setSelectedPlatforms(selectedQuickFilter?.platform || "all");
    setSelectedPlacements(selectedQuickFilter?.placements || []);
    var data = { ...selectedSite };

    if (data?.filters?.length > 1) {
      data.filters = ["all", ...data.filters];
    }

    if (data?.breakdowns) {
      data.breakdowns = [...data.breakdowns, "site"];
    }
    setSiteData({ ...data });
  }, [selectedQuickFilter, selectedSite.id]);

  useEffect(() => {
    if (!site || site === selectedSite.id || site === selectedSite.virgulId)
      return;
    setLoading(true);
    APIManager.getSite(site)
      .then((res) => {
        console.log("here");
        if (integrationType !== 3 && integrationType !== 4) {
          const data = { ...res.data };
          if (data?.filters?.length > 1) data.filters.unshift("all");
          if (data?.breakdowns) data.breakdowns.push("site");
          setSiteData(data);
          setDateRange(
            selectedQuickFilter?.dateRange ||
              modalConstants.initialQuickDateButtons[0].range
          );
          setSelectedBreakdowns(selectedQuickFilter?.breakdowns || ["daily"]);
          setSelectedPlatforms(selectedQuickFilter?.platform || "all");
          setSelectedPlacements(selectedQuickFilter?.placements || []);
        } else {
          setSiteData(res.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch site data:", error);
        setLoading(false);
      });
  }, [site, integrationType]);

  const onBreakdownChange = (e) => {
    e.preventDefault();
    const breakdownElement = e.target.dataset.breakdown;

    if (!breakdownElement) return;

    let newBreakdowns = selectedBreakdowns.includes(breakdownElement)
      ? selectedBreakdowns.filter((bd) => bd !== breakdownElement)
      : [...selectedBreakdowns, breakdownElement];

    setSelectedBreakdowns(newBreakdowns);
  };

  const onPlatformChange = (e) => {
    e.preventDefault();
    const platformElement = e.target.dataset.platform;

    if (integrationType === 3 || integrationType === 4) {
      let newPlatforms = selectedPlatforms.includes(platformElement)
        ? selectedPlatforms.filter((pl) => pl !== platformElement)
        : [...selectedPlatforms, platformElement];

      setSelectedPlatforms(newPlatforms);
    } else {
      setSelectedPlatforms([platformElement]);
    }
  };

  const onAdPlacementChange = (event) => {
    if (selectedPlacements?.length === event?.length) {
      let hasDifference = true;
      selectedPlacements.forEach((e) => {
        if (event.indexOf(e) === -1) {
          hasDifference = false;
        }
      });
      if (hasDifference) {
        return;
      }
    }
    setSelectedPlacements([].concat(event));
  };

  var platformsSection;
  var breakdownsSection;
  breakdownsSection = (
    <>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="reportBD"
      >
        {capitalizeFirst(t("breakdowns"))}
      </label>
      <span
        className="md:inline-block bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="reportBD"
      >
        {siteData?.breakdowns?.map((bd, index) => (
          <button
            key={index}
            className={`transition-all ease-out transition-fast text-xs font-bold px-2 py-2 rounded mr-2 cursor-pointer ${
              selectedBreakdowns.includes(bd === "placement" ? "zone" : bd)
                ? "bg-white shadow"
                : ""
            }`}
            onClick={onBreakdownChange}
            data-breakdown={bd === "placement" ? "zone" : bd}
          >
            {upperCase(t(modalConstants.siteBreakdownsAll[bd] || bd))}
          </button>
        ))}
      </span>
    </>
  );

  platformsSection = (
    <>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="reportFilters"
      >
        {capitalizeFirst(t("platforms"))}
      </label>
      <span className="md:inline-block bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
        {modalConstants.platformData
          .filter((pl) => siteData?.filters?.includes(pl.filterText))
          .map((platform, index) => (
            <button
              key={index}
              className={`transition-all ease-out transition-fast text-xs font-bold px-2 py-2 rounded mr-2 cursor-pointer ${
                selectedPlatforms.includes(platform.dataTag)
                  ? "bg-white shadow"
                  : ""
              }`}
              onClick={onPlatformChange}
              data-platform={platform.dataTag}
            >
              {upperCase(t(platform.printText))}
            </button>
          ))}
      </span>
    </>
  );

  const modalContent = (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-80">
      <div className="bg-white p-3 flex flex-col items-center justify-between rounded-md border border-gray-300">
        {loading && <LoadingBackDrop />}

        <div className="w-full p-3 flex items-center justify-between">
          <h3 className="font-semibold text-m mr-16">
            {capitalize(
              t(
                selectedQuickFilter?.mode === 0
                  ? "create new filter"
                  : "edit quick filter"
              )
            )}
          </h3>
          <span className="cursor-pointer" onClick={handleClose}>
            <Icon.X width={20} height={20} />
          </span>
        </div>
        <div className="mx-5 my-3 w-auto">
          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
              <div>
                <Form.Select
                  className="w-full col-span-1"
                  name="Site"
                  label={capitalize(t("site"))}
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                >
                  {sites.map((site, index) => (
                    <option key={index} value={site?.id}>
                      {site?.fqdn}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="col-span-2">
                <Form.FormTextInput
                  name="filter-name"
                  label="filter name"
                  placeholder={capitalize(t("filter name"))}
                  value={name}
                  className=""
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <Form.Select
                  className="w-full col-span-1"
                  name="Date Range"
                  label={capitalize(t("date range"))}
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  {modalConstants.initialQuickDateButtons.map((db, index) => (
                    <option key={index} value={db.range}>
                      {capitalize(t(db.text))}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="md:my-2 md:flex md:flex-row">
              <div className="mr-0 my-2 md:mr-3 md:my-0 ">
                {breakdownsSection}
              </div>
              <div className="mt-6 md:my-0">{platformsSection}</div>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="reportFilters"
                >
                  {capitalizeFirst(t("ad placement"))}
                </label>
                <div>
                  {siteData?.zones && (
                    <MultiselectBox
                      title={capitalize(t("ad placement"))}
                      options={siteData?.zones?.map((a) => ({
                        id: a.id,
                        name: a.name + " (" + a.id + ")",
                      }))}
                      onChange={onAdPlacementChange}
                      onConfig={true}
                      maxHeight="300px"
                      maxWidth="400px"
                      customStyle="overflow-x-scroll"
                      buttonCustomStyle=""
                      selectedItems={selectedPlacements}
                      setSelectedItems={setSelectedPlacements}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end w-full mt-2">
            <Button
              className="px-4 mr-2 whitespace-nowrap overflow-hidden"
              color="login"
              type="button"
              onClick={(e) => handleSubmit()}
              loading={loading}
            >
              {upperCase(t(selectedQuickFilter?.mode === 0 ? "save" : "edit"))}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  if (!quickFilterStatus) return null;

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById("quick-filter-modal-root")
  );
};

export default QuickFilterModal;
