import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import Loading from "../../components/common/Loading";

import AddSite from "./AddSite";
import SiteDetail from "./SiteDetail";
import SiteOnboarding from "./SiteOnboarding";

import S from "../../store";
import { SiteActionCreators } from "../../store/actions/site";
import { AppActionCreators } from "../../store/actions/app";

const Sites = (props) => {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state?.site?.sites);

  // normally we can blacklist a reducer on redux config, but in this case
  // quickFilterStatus and selectedQuickFilter are all part of the app reducer
  // hence I just close the modal whenever Site component is rendered the first time.

  useEffect(() => {
    dispatch(AppActionCreators.setQuickFilterModal(false));
    dispatch(AppActionCreators.setSelectedQuickFilter({}));

    if (props.auth.user.integrationType === 1) {
      return;
    }
    if (props.match.params.site === "undefined") {
      dispatch(SiteActionCreators.getSites()).then((res) => {
        window.location = `/sites/${res.data[0].id}`;
      });
    }
  }, []);

  if (props.auth.user.integrationType === 1) {
    props.history.push("/analytics/videos");
    return null;
  }
  if (!sites) {
    return <Loading />;
  }
  let childComponent = <Loading />;
  if (props.match.params.site === "add") {
    childComponent = <AddSite />;
  } else if (
    props.match.params.site &&
    props.match.params.subaction === "onboarding"
  ) {
    childComponent = <SiteOnboarding site={props.match.params.site} />;
  } else if (props.match.params.site) {
    childComponent = <SiteDetail site={props.match.params.site} />;
  } else {
    const selectedSite = S.store.getState().site.selectedSite;

    if (
      (selectedSite === "undefined" || selectedSite === null) &&
      sites.length === 0
    ) {
      return <AddSite />;
    }

    props.history.push(
      `/sites/${
        selectedSite === null || typeof selectedSite === "undefined"
          ? sites[0].id
          : selectedSite.id
      }`
    );
  }
  return childComponent;
};

Sites.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(Sites));
