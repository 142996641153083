import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../components/common/Button';
import Card from '../../components/common/Card';
import Form from '../../components/common/Form';
import Page from '../../components/common/Page';
import APIManager from '../../managers/APIManager';

const AddSite = (props) => {
  const [errors, setErrors] = useState({fqdn: ''})
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({fqdn: '',onboardingStep: 1})

  const onBlur = (e) => {
    e.preventDefault();
    const errorsVar = errors;
    switch (e.target.name) {
      case 'fqdn':
        if (e.target.value.indexOf('http') !== -1) {
          errorsVar.fqdn = 'Please only enter your domain name';
        } else if (!e.target.value.length) {
          errorsVar.fqdn = 'You must enter your domain name';
        } else {
          errorsVar.fqdn = '';
        }
        break;
      default:
        break;
    }
    setErrors(errorsVar);
  }

  const onChange = (e) => {
    e.preventDefault();
    const valuesVar = values;
    valuesVar[e.target.name] = e.target.value;
    setValues(valuesVar)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (errors.fqdn !== '') {
      setLoading(false);
      return;
    }

    const valuesVar = values;
    if (valuesVar.fqdn.split('.').length > 2) {
      valuesVar.fqdn = valuesVar.fqdn.slice(valuesVar.fqdn.indexOf('.') + 1);
    }

    APIManager.getVideos()
      .then(res => {
        if (res.data.length > 0) {
          valuesVar.onboardingStep = 2;
        }
        return valuesVar;
      })
      .then(values => APIManager.createSite(values))
      .then(site => props.history.push(`/sites/${site.data.id}`));
  }

    return (
      <Page title="Add Site">
        <Card className="max-w-4xl">
          <Card.Body>
            <div className="bg-blue-100 border-l-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 mb-3" role="alert">
              <div className="flex">
                <div>
                  <p>Let's start with adding your site to Empower. Simply paste the URL of your web site.</p>
                </div>
              </div>
            </div>
            {errors.fqdn !== '' &&
              <div className="bg-red-100 border-l-4 border-red-500 rounded-b text-red-900 px-4 py-3 mb-3" role="alert">
                <div className="flex">
                  <div>
                    <p>Error : {errors.fqdn}</p>
                  </div>
                </div>
              </div>
            }
            <Form onSubmit={onSubmit}>
              <Form.FormTextInput
                name="fqdn"
                label="Domain Name"
                placeholder="example.com"
                onBlur={onBlur}
                onChange={onChange}
                error={errors && errors.fqdn}
                value={values && values.fqdn}
              />
              <Button
                color="primary"
                disabled={
                  (values && !values.fqdn.length) ||
                  loading
                }
                loading={loading}
                onClick={onSubmit}
                icon="plus"
              >
                Add Site
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Page>
    );
}

AddSite.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(AddSite);
