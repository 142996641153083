import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  debug: false,
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    tr: {
      translations: require("./locales/tr/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  // debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
});

i18n.languages = ["en", "tr"];

export default i18n;
