export const AppActions = {
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_QUICK_FILTER_MODAL: "TOGGLE_QUICK_FILTER_MODAL",
  SET_SELECTED_QUICK_FILTER: "SET_SELECTED_QUICK_FILTER",
};

export const AppActionCreators = {
  toggleSidebar: () => ({
    type: AppActions.TOGGLE_SIDEBAR,
  }),
  setLanguage: (language) => ({
    type: AppActions.SET_LANGUAGE,
    payload: { language },
  }),
  setQuickFilterModal: (newState) => {
    return {
      type: AppActions.SET_QUICK_FILTER_MODAL,
      payload: { newState },
    };
  },
  setSelectedQuickFilter: (newFilter) => ({
    type: AppActions.SET_SELECTED_QUICK_FILTER,
    payload: { newFilter },
  }),
};
