import APIManager from '../../managers/APIManager';

export const PlayerActions = {
  CREATE_PLAYER: 'CREATE_PLAYER',
  DELETE_PLAYER: 'DELETE_PLAYER',
  GET_PLAYER: 'GET_PLAYER',
  GET_PLAYERS: 'GET_PLAYERS',
  INVALIDATE_PLAYERS: 'INVALIDATE_PLAYERS',
  UPDATE_PLAYER: 'UPDATE_PLAYER'
};

export const PlayerActionCreators = {
  createPlayer: player => dispatch => APIManager.createPlayer(player).then(res => dispatch({
    type: PlayerActions.CREATE_PLAYER,
    payload: {
      player: res.data
    }
  })),
  deletePlayer: id => dispatch => APIManager.deletePlayer(id).then(res => dispatch({
    type: PlayerActions.DELETE_PLAYER,
    payload: {
      player: res.data
    }
  })),
  getPlayer: id => dispatch => APIManager.getPlayer(id).then(res => dispatch({
    type: PlayerActions.GET_PLAYER,
    payload: {
      player: res.data
    }
  })),
  getPlayers: () => dispatch => APIManager.getPlayers().then(res => dispatch({
    type: PlayerActions.GET_PLAYERS,
    payload: {
      players: res.data
    }
  })),
  updatePlayer: player => dispatch => APIManager.updatePlayer(player).then(res => dispatch({
    type: PlayerActions.UPDATE_PLAYER,
    payload: {
      player: res.data
    }
  }))
};
