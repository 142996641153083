import * as React from "react";
import * as Icon from "react-feather";

import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";

import logo from "./../../assets/images/empower-logo-box.png";

const Login = (
  errors,
  values,
  errorMessage,
  loading,
  onBlur,
  onChange,
  onSubmit,
  email,
  password
) => (
  <div className="max-w-sm m-auto h-screen flex flex-col">
    <div className="my-auto">
      <img src={logo} width="150" className="mb-3 m-auto" alt="Empower Logo" />
      {errorMessage ? (
        <div
          className="bg-red-100 border-l-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow mb-3"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-red-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Login Failed</p>
              <p className="text-sm">{errorMessage}</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Card className="p-2">
        <Card.Body>
          <span className="block text-lg mb-5 text-center">
            Login to your account
          </span>
          <Form onSubmit={onSubmit}>
            <Form.FormTextInput
              name="email"
              label="Email Address"
              placeholder="Enter email"
              onChange={onChange}
              onBlur={onBlur}
              value={email}
              error={errors && errors.email}
            />
            <Form.FormTextInput
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              onChange={onChange}
              onBlur={onBlur}
              value={password}
              error={errors && errors.password}
            />
            <Button
              className="w-full"
              color="login"
              disabled={loading}
              loading={loading}
              onClick={onSubmit}
              type="submit"
            >
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <p className="text-center text-gray-600 text-sm">
        Don't have an account yet?{" "}
        <a href="/auth/register" className="font-bold text-blue-700">
          Create Account
        </a>
      </p>
      <p className="text-center text-gray-600 text-sm">
        Need help?{" "}
        <a href="/auth/resetpassword" className="font-bold text-blue-700">
          Forgot your password?
        </a>
      </p>
    </div>
  </div>
);

export default Login;
