import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";
import Page from "../common/Page";

import APIManager from "../../managers/APIManager";

import { Translation } from "react-i18next";
import { capitalize } from "../../utils/manipulateText";

const UploadVideo = (props) => {
  const [errors, setErrors] = useState({ source: "" });
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ source: "" });

  const onChange = (e) => {
    e.preventDefault();
    const valuesVar = values;
    valuesVar[e.target.name] = e.target.value;
    setValues(valuesVar);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    APIManager.createVideo(values).then((res) => {
      props.history.push("/videos");
    });
  };

  return (
    <Page title="Upload Video">
      <Card className="max-w-lg m-auto">
        <Card.Body>
          <Form onSubmit={onSubmit}>
            <Form.FormTextInput
              autoFocus={true}
              name="source"
              label="Video URL"
              placeholder="https://www.youtube.com/watch?v=1A2B3C4D"
              onChange={onChange}
              value={values && values.source}
              error={errors && errors.source}
            />
            <div className="text-right">
              <Button
                color="primary"
                disabled={!values.source.length || loading}
                loading={loading}
                onClick={onSubmit}
                icon="upload"
              >
                <Translation>
                  {(t) => capitalize(t("upload video"))}
                </Translation>
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Page>
  );
};

UploadVideo.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(UploadVideo);
