import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import APIManager from "../../managers/APIManager";
import { countryNames, getCountryCodes } from "./countryNames";
import Control from "./Control";
import moment from "moment-timezone";
import Page from "../common/Page";
import LanguageSelector from "../common/LanguageSelector";
import Loading from "../common/Loading";
import TotalStats from "./TotalStats";
import AppsReportTable from "./AppsReportTable";
import { withRouter } from "react-router-dom";
import ConfigModal from "../reports/ScheduledReports/ConfigModal";
import { initialState } from "../reports/ScheduledReports/constants/actionTypes";
import { capitalizeFirst } from "../../utils/manipulateText";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";
import UserSelector from "../common/UserSelector";
import MessageText from "../common/MessageText";

const AppsRevenue = ({
  match,
  users,
  selectedUser,
  setSelectedUser,
  selectedUserApps,
  integrationType,
  userEmail,
  currency,
}) => {
  const [appReport, setAppReport] = useState({});
  const countries = Object.keys(countryNames);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "days").format("DD.MM.YYYY")
  );
  const [endDate, setEndDate] = useState(moment().format("DD.MM.YYYY"));
  const [loading, setLoading] = useState(true);
  const [selectedBreakdowns, setSelectedBreakdowns] = useState(["appNames"]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedAppNames, setSelectedAppNames] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [refreshData, setRefreshData] = useState(true);
  const [totalData, setTotalData] = useState({});
  const [tableDims, setTableDims] = useState([]);
  const [userSelection, setUserSelection] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [initState, setInitState] = useState({ ...initialState });
  const [tableFooter, setTableFooter] = useState({});
  const [timeoutErr, setTimeoutErr] = useState(false);
  const { t } = useTranslation();
  const toggleFilters = () => {
    const filters = document.querySelector(".filters");
    filters.classList.toggle("hidden");
    filters.classList.toggle("flex");
    filters.classList.toggle("flex-row");
  };

  function getTotalStats(result) {
    const reducer = (accumulator, currentValue) => {
      return {
        impressions: accumulator.impressions + currentValue.impression,
        clicks: accumulator.clicks + currentValue.click,
        revenue: accumulator.revenue + currentValue.revenue,
        adRequest: accumulator.adRequest + currentValue.adRequest,
        match: accumulator.match + currentValue.match,
      };
    };

    const initialStats = {
      impressions: 0,
      clicks: 0,
      revenue: 0,
      adRequest: 0,
      match: 0,
    };

    return result.reduce(reducer, initialStats);
  }

  const getUserName = (userId) => {
    var uname;
    users.forEach((user) => {
      if (user.id === userId) {
        uname = user.name;
      }
    });

    if (uname === "") return "All Users";
    else return uname;
  };

  const controller = new AbortController();

  const fetchReport = (reportParams, refreshData, controller) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      APIManager.getAppReport(reportParams, selectedUser, {
        signal: controller.signal,
      })
        .then((res) => {
          if (!controller.signal.aborted) {
            var results = res.data.results;
            results.forEach((item) => {
              item["ecpm"] =
                item.impression === 0
                  ? 0
                  : (item.revenue * 1000) / item.impression;
              item["ctr"] =
                item.impression === 0
                  ? 0
                  : (item.click * 100) / item.impression;
            });

            const totalStats = getTotalStats(res.data.results);
            totalStats["totalCtr"] =
              (totalStats.clicks * 100) / totalStats.impressions;
            totalStats["totalEcpm"] =
              (totalStats.revenue * 1000) / totalStats.impressions;
            setTotalData({ ...totalStats });
            var dims =
              res.data.results.length > 0
                ? Object.keys(res.data.results[0])
                : [];
            const grandTotal = {
              adRequest: totalStats.adRequest,
              click: totalStats.clicks,
              ctr: totalStats.totalCtr,
              ecpm: totalStats.totalEcpm,
              impression: totalStats.impressions,
              match: totalStats.match,
              revenue: totalStats.revenue,
            };

            dims.forEach((dim) => {
              if (!grandTotal.hasOwnProperty(dim)) {
                grandTotal[dim] = "";
              }
            });

            grandTotal[dims[0]] = "Grand Total";
            setTableFooter({ ...grandTotal });

            setAppReport(results);

            setTableDims([...dims]);
            setLoading(false);
            resolve("Success");
          }
        })
        .catch((e) => {
          if (!controller.signal.aborted) {
            reject(e);
          }
        });
    });
  };

  const timeoutPromise = new Promise((resolve) => {
    setTimeout(() => {
      controller.abort();
      resolve("Timeout");
    }, 15000);
  });

  useEffect(() => {
    var reportParams = {
      tag: selectedBreakdowns.indexOf("tag") !== -1,
      appNames: selectedBreakdowns.indexOf("appNames") !== -1,
      day: selectedBreakdowns.indexOf("day") !== -1,
      useCountry: selectedBreakdowns.indexOf("useCountry") !== -1,
      countries:
        selectedCountries.length === countries.length
          ? []
          : getCountryCodes(selectedCountries),
      apps:
        selectedAppNames.length === selectedUserApps?.length
          ? []
          : selectedAppNames,
      startDate: startDate,
      endDate: endDate,
      platforms: selectedPlatforms,
    };

    var selectedUser = match.params.user || "";

    setTimeoutErr(false);
    Promise.race([
      fetchReport(reportParams, refreshData, controller),
      timeoutPromise,
    ])
      .then((result) => {
        if (result === "Timeout") {
          setTimeoutErr(true);
          APIManager.reportsFallback(
            reportParams,
            selectedUser,
            userEmail,
            currency,
            integrationType === 3 || integrationType === 4 ? "app" : "site"
          );
        } else if (result === "Success") {
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
        } else {
          console.error("Error:", error);
        }
      });
  }, [refreshData]);

  return (
    <>
      <ConfigModal
        isVisible={showConfig}
        onClose={() => {
          setShowConfig(false);
          if (
            initState.selectedSite === "" ||
            initState.selectedSite === "all-sites"
          ) {
            setInitState({
              ...initialState,
              siteBreakdowns: initState.siteBreakdowns,
              sitePlatforms: initState.sitePlatforms,
              selectedSite: initState.selectedSite,
              selectedSitePlacements: initState.selectedSitePlacements,
              selectedUserApps: initState.selectedUserApps,
              selectedUserCountries: initState.selectedUserCountries,
              selectedPlatforms:
                integrationType === 3 || integrationType === 4 ? [] : ["all"],
              selectedBreakdowns:
                integrationType === 3 || integrationType === 4
                  ? ["appNames"]
                  : ["daily"],
            });
          } else {
            setInitState({
              ...initialState,
            });
          }
          const bodyEl = document.getElementsByTagName("BODY")[0];
          bodyEl.style.overflow = "hidden";
        }}
        integrationType={integrationType}
        initialState={initState}
      />
      <Page
        controlClass="w-full items-center justify-between"
        title={
          <>
            <div className={"flex justify-between"}>
              <UserSelector
                users={users}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setRefreshData={setRefreshData}
              />

              <div className="md:hidden flex ml-auto">
                <button className={"filter-icon"} onClick={toggleFilters}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V4.586C1.00006 4.85119 1.10545 5.10551 1.293 5.293L7.707 11.707C7.89455 11.8945 7.99994 12.1488 8 12.414V19L12 15V12.414C12.0001 12.1488 12.1055 11.8945 12.293 11.707L18.707 5.293C18.8946 5.10551 18.9999 4.85119 19 4.586V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289Z"
                      stroke="#728195"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <LanguageSelector customStyle="hidden md:block ml-auto" />
            </div>
          </>
        }
        controls={
          <Control
            apps={selectedUserApps}
            countries={countries}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            loading={loading}
            setLoading={setLoading}
            selectedBreakdowns={selectedBreakdowns}
            setSelectedBreakdowns={setSelectedBreakdowns}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedAppNames={selectedAppNames}
            setSelectedAppNames={setSelectedAppNames}
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
            selectedUser={selectedUser}
            setRefreshData={setRefreshData}
            setShowConfig={setShowConfig}
            initState={initState}
            setInitState={setInitState}
          />
        }
      >
        {timeoutErr && (
          <div className="w-full m-auto py-4 text-orange-600 text-md text-center">
            <Icon.Info className="inline mr-2" width="16" height="16" />
            {capitalizeFirst(
              t(
                "Your report took a long time to generate. You can check its status on the Async Reports tab of the reports page."
              )
            )}
          </div>
        )}
        {!timeoutErr && appReport.length == 0 && (
          <MessageText
            message={"there is no data to show for this time period."}
          />
        )}
        {loading && <Loading />}
        {!timeoutErr && appReport.length > 0 && !loading && (
          <>
            <TotalStats totalData={totalData} />
            <AppsReportTable
              tableData={appReport}
              footerData={tableFooter}
              setTableData={setAppReport}
              breakdowns={selectedBreakdowns}
              tableDims={tableDims}
              refreshData={refreshData}
              selectedUser={selectedUser}
              getUsername={getUserName}
            />
          </>
        )}
      </Page>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    integrationType: state.auth.user.integrationType,
    userEmail: state.auth.user.email,
    currency: state.auth.user.currency,
  };
};

export default connect(mapStateToProps)(withRouter(AppsRevenue));
