import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const Player = ({ className = "", id, optional = {} }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (!player) return;
    const empowerPlayer = new window.EmpowerPlayer(
      `preview-${id}`,
      Object.assign(optional, {
        adTimeout: 1,
        playerName: "empower_console",
      })
    );
    player.setInfoLoadURL(
      `${process.env.REACT_APP_API_BASE}/videos/{VIDEO_ID}/playback`
    );
    empowerPlayer.loadVideo(id);
    setPlayer(empowerPlayer);
  }, [player, optional, id]);

  return (
    <div
      id={`preview-${id}`}
      className={`empower-player ${className}`}
      data-empower-id={id}
    />
  );
};

Player.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  optional: PropTypes.object,
};

export default Player;
