import React, { useEffect } from "react";
import APIManager from "../../managers/APIManager";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../common/Loading";

const SwitchPage = () => {
  const loc = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const userId = loc.search.split("=")[1];
    if (userId === auth?.user?.virgulId) {
      window.location = "/";
    }
    APIManager.switchAccount(userId).then((res) => {
      dispatch({ type: "INVALIDATE_SITES" });
      dispatch({
        type: "SWITCH_ACCOUNT",
        payload: {
          status: res.data.user.id ? 1 : 0,
          tokens: res.data.tokens,
          user: res.data.user,
        },
      });
      window.location = "/";
    });
  }, []);
  return (
    <p className="text-lg margin-auto">Switching to requested account...</p>
  );
};

export default SwitchPage;
