import axios from "axios";

import S from "../store";
import { AuthActionCreators } from "../store/actions/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

const requestAuthErrorInterceptor = (err) => {
  if (
    err.response &&
    err.response.status === 401 &&
    err.config.url.indexOf("/auth/") === -1
  ) {
    const refreshToken = S.store.getState().auth.tokens.refreshToken;
    if (typeof refreshToken !== "undefined") {
      return APIManager.refreshToken(refreshToken)
        .then((res) => {
          S.store.dispatch(
            AuthActionCreators.refreshAccessToken(res.data.tokens.accessToken)
          );
          axios.defaults.headers.Authorization = `Bearer ${res.data.tokens.accessToken}`;
          return axios.request(err.config);
        })
        .catch((_) => {
          S.store.dispatch(AuthActionCreators.logout());
          const referrer = window.location.pathname + window.location.search;
          window.location.href =
            "/auth/login?referrer=" + encodeURIComponent(referrer);
        });
    } else {
      S.store.dispatch(AuthActionCreators.logout());
      const referrer = window.location.pathname + window.location.search;
      window.location.href =
        "/auth/login?referrer=" + encodeURIComponent(referrer);
    }
  }
  return Promise.reject(err);
};

axios.interceptors.request.use((config) => {
  if (
    typeof config.url !== "undefined" &&
    config.url.indexOf("/auth/") === -1
  ) {
    const accessToken = S?.store?.getState().auth?.tokens?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  return config;
}, requestAuthErrorInterceptor);

axios.interceptors.response.use(
  (response) => response,
  requestAuthErrorInterceptor
);

const APIManager = {
  activate: (credentials) => {
    return axios
      .post("/auth/activate", credentials, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => res.data);
  },

  authorize: (clientID, redirectURI, state) => {
    return axios
      .post(
        `/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&state=${state}`
      )
      .then((res) => {
        return res.data;
      });
  },

  getClient: (clientId) => {
    return axios.get(`/oauth/clients/${clientId}`).then((res) => res.data);
  },

  login: (credentials) => {
    return axios
      .post("/auth/login", credentials, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  register: (credentials) => {
    return axios
      .post("/auth/register", credentials, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => res.data);
  },

  resetPassword: (credentials) => {
    return axios
      .post("/auth/resetpassword", credentials, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => res.data);
  },

  changepassword: (credentials) => {
    return axios
      .put("/auth/changepassword", credentials, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => res.data);
  },

  switchAccount: (account) => {
    return axios
      .get(`/auth/switch/${account}`, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  getProfile: () => {
    return axios.get("/auth/profile").then((res) => res.data);
  },

  refreshToken: (refreshToken) => {
    return axios.post("/auth/token", { refreshToken }).then((res) => res.data);
  },

  createPlayer: (data) => {
    return axios.post("/players", data).then((res) => res.data);
  },

  deletePlayer: (playerId) => {
    return axios.delete(`/players/${playerId}`).then((res) => res.data);
  },

  getPlayer: (playerId) => {
    return axios.get(`/players/${playerId}`).then((res) => res.data);
  },

  getPlayers: () => {
    return axios.get("/players").then((res) => res.data);
  },

  updatePlayer: (data) => {
    return axios.put(`/players/${data.id}`, data).then((res) => res.data);
  },

  createSite: (data) => {
    return axios.post("/sites", data).then((res) => res.data);
  },

  getSites: () => {
    return axios.get("/sites").then((res) => {
      return res.data;
    });
  },

  getSite: (siteId) => {
    return axios.get(`/sites/${siteId}`).then((res) => res.data);
  },

  getPayments: () => {
    return axios.get("/users/payments").then((res) => res.data);
  },

  getPaymentInfo: () => {
    return axios.get("/users/paymentinfo").then((res) => res.data);
  },

  savePaymentInfo: (params) => {
    return axios.post("/users/paymentinfo", params).then((res) => res.data);
  },

  updateSite: (data) => {
    return axios.put(`/sites/${data.id}`, data).then((res) => res.data);
  },

  getSiteReport: (siteId, params) => {
    return axios
      .post(`/sites/${siteId}/report`, params)
      .then((res) => res.data);
  },

  getAppReport: (params, selectedUser) => {
    return axios
      .post("/apps/report", { ...params, user: selectedUser })
      .then((res) => res.data);
  },

  createVideo: (data) => {
    return axios.post("/videos", data).then((res) => res.data);
  },

  getVideos: () => {
    return axios.get("/videos").then((res) => res.data);
  },

  getVideo: (videoId) => {
    return axios.get(`/videos/${videoId}`).then((res) => res.data);
  },

  deleteVideo: (videoId) => {
    return axios.delete(`/videos/${videoId}`).then((res) => res.data);
  },

  updateVideo: (data) => {
    return axios.put(`/videos/${data.id}`, data).then((res) => res.data);
  },

  getVideoPlayback: (videoId) => {
    return axios.get(`/videos/${videoId}/playback`).then((res) => res.data);
  },

  getOverallVideoAnalytics: (startDate, endDate) => {
    return axios
      .get(`/analytics/players?start=${startDate}&end=${endDate}`)
      .then((res) => res.data);
  },

  getRealtimePlayerData: () => {
    return axios.get("/analytics/players/realtime").then((res) => res.data);
  },

  getRealtimeVideoData: (videoId) => {
    return axios
      .get(`/analytics/videos/${videoId}/realtime`)
      .then((res) => res.data);
  },

  getSingleVideoAnalytics: (videoId, startDate, endDate) => {
    return axios
      .get(`/analytics/videos/${videoId}?start=${startDate}&end=${endDate}`)
      .then((res) => res.data);
  },

  getScheduledReports: (reportType) => {
    return axios.post("/reports", { reportType }).then((res) => res.data);
  },

  getScheduledReport: (reportId) => {
    return axios.post("/reports/get", { reportId }).then((res) => res.data);
  },

  updateScheduledReport: (reportId, newData) => {
    return axios
      .put("/reports/update", { reportId, newData })
      .then((res) => res.data);
  },

  deleteScheduledReport: (reportId) => {
    return axios.post("/reports/delete", { reportId }).then((res) => res.data);
  },

  createScheduledReport: (reportData) => {
    return axios
      .post("/reports/create", { reportData })
      .then((res) => res.data);
  },

  exportToDrive: (fileName, csvString) => {
    return axios
      .post("/export/drive", { fileName, csvString })
      .then((res) => res.data);
  },

  reportsFallback: (params, selectedUser, email, currency, reportType) => {
    return axios
      .post("/reports/fallback", {
        params: { ...params, selectedUser },
        email,
        currency,
        reportType,
      })
      .then((res) => res.data);
  },

  getAsyncReports: () => {
    return axios.get("/reports/fallback/reports").then((res) => res.data);
  },

  deleteAsyncReport: () => {
    return axios
      .delete("/reports/fallback/delete/:reportId")
      .then((res) => res.data);
  },

  getFilters: () => {
    return axios.get("/filters").then((res) => res.data);
  },

  getFilter: (filterId) => {
    return axios.get(`/filters/${filterId}`).then((res) => res.data);
  },

  saveFilter: (filterData) => {
    return axios.post("/filters", filterData).then((res) => res.data);
  },

  updateFilter: (filterId, updateData) => {
    return axios
      .put(`/filters/${filterId}`, updateData)
      .then((res) => res.data);
  },

  deleteFilter: (filterId) => {
    return axios.delete(`/filters/${filterId}`).then((res) => res.data);
  },
};

export default APIManager;
