import React, { useEffect } from "react";
import StatsBox from "../common/StatsBox";
import formatter from "../../helpers/formatter";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const TotalStats = ({ totalData, auth }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row mb-2">
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 md:grid-cols-5 md:gap-x-4 md:w-full">
          <div className="md:flex" style={{ height: "fit-content" }}>
            <StatsBox
              series={[
                {
                  name: "Total Revenue",
                  data: totalData.revenue,
                  type: "area",
                },
              ]}
              label="total revenue"
              displayChart={false}
              formatter={formatter.currency(auth.user.currency)}
            />
          </div>
          <div className="md:flex" style={{ height: "fit-content" }}>
            <StatsBox
              series={[
                {
                  name: "Total Clicks",
                  data: totalData.clicks,
                  type: "area",
                },
              ]}
              label="total clicks"
              displayChart={false}
              formatter={formatter.number}
            />
          </div>
          <div className="md:flex" style={{ height: "fit-content" }}>
            <StatsBox
              series={[
                {
                  name: "Total Impressions",
                  data: totalData.impressions,
                  type: "area",
                },
              ]}
              label="total impressions"
              displayChart={false}
              formatter={formatter.number}
            />
          </div>
          <div className="md:flex" style={{ height: "fit-content" }}>
            <StatsBox
              series={[
                {
                  name: "Average Ctr",
                  data: totalData.totalCtr,
                  type: "area",
                },
              ]}
              label="average ctr"
              displayChart={false}
              formatter={formatter.percentage}
            />
          </div>
          <div className="md:flex" style={{ height: "fit-content" }}>
            <StatsBox
              series={[
                {
                  name: "Average Ecpm",
                  data: totalData.totalEcpm,
                  type: "area",
                },
              ]}
              label="average ecpm"
              displayChart={false}
              formatter={formatter.currency(auth.user.currency)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(TotalStats));
