import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

import Badge from '../common/Badge';
import Button from '../common/Button';
import Card from '../common/Card';
import Page from '../common/Page';
import Table from '../common/Table';

import { VideoActionCreators } from '../../store/actions/video';
import HelperVideo from '../../helpers/video';
import Loading from '../common/Loading';

import thumbnailPlaceholder from '../../assets/images/thumbnail-placeholder.png';

import { Translation } from 'react-i18next';
import { capitalize } from '../../utils/manipulateText';

const VideoList = (props) => {

  const [autoRefresh, setAutoRefresh] = useState(
    {
      interval: null,
      status: false
    }
  );
  const [filterKeywords, setFilterKeywords] = useState(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [videos, setVideos] = useState(null); 

  useEffect(() => {
    props.getVideos();
  }, [])

  useEffect(() => {
    let shouldRefresh = false;
    for (const video of props.video.videos) {
      shouldRefresh =
        shouldRefresh ||
        [
          'CREATED',
          'DOWNLOADING',
          'QUEUED',
          'TRANSCODING',
          'UPLOADED',
          'UPLOADING'
        ].indexOf(video.status) > -1;
    }
    if (shouldRefresh && !autoRefresh.interval) {
      enableAutoRefresh();
    } else if (!shouldRefresh && autoRefresh.interval) {
      disableAutoRefresh();
    }

    return () => disableAutoRefresh();

  }, [props])

  const deleteVideo = (e, video) => {
    e.preventDefault();
    e.stopPropagation();

    const confirmation = window.confirm(
      'Are you sure you want to delete this video? This operation is irreversible.'
    );
    if (!confirmation || !video) {
      return;
    }

    props.deleteVideo(video);
  }

  const disableAutoRefresh = () => {
    const autoRefreshVar = autoRefresh;
    if (autoRefreshVar.interval) {
      clearInterval(autoRefreshVar.interval);
    }
    setAutoRefresh(autoRefreshVar);
  }

  const enableAutoRefresh = () => {
    const autoRefreshVar = {
      interval: setInterval(that => that?.props?.getVideos(), 5000, this),
      status: true
    };
    setAutoRefresh(autoRefreshVar);
  }

  const redirectToVideoDetail = (e) => {
    e.preventDefault();
    props.history.push(`/videos/${e.target.closest('tr').dataset.key}`);
  }

  const setFilterKeywordsFunc = (e) =>  {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.value.trim().length) {
      setFilterKeywords(e.target.value);
      setPage(0);
    } else {
      setFilterKeywords(e.target.values.trim().toLowerCase());
      setPage(0);
    }
  }

  const nextPage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setPage(Math.min(Math.floor(props.video.videos.length / perPage), page + 1));
  }

  const prevPage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setPage(Math.max(0, page - 1));

  }

    if (props.video.videos === null) {
      return <Loading />;
    }

    const videoSet = props.video.videos
      .filter(video => !filterKeywords || video.title.toLowerCase().indexOf(filterKeywords) > -1)
      .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);

    return (
      <Page title="Videos" controls={
        <>
          <Button
            RootComponent={withRouter(NavLink)}
            to="/videos/upload"
            color="primary"
            disabled={props.auth.user.integrationType === 1}
          >
            <Icon.Plus className="inline mr-1" width="16" height="16" />
            <Translation>
              {(t) => capitalize(t('upload video'))}
            </Translation>
          </Button>
        </>
      }
      >
        <div>
          <div className="flex rounded-md shadow-md mb-4">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Icon.Search className="h-5 w-5 text-gray-400" width="16" height="16" />
              </div>
              <input onChange={setFilterKeywords} className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150" placeholder="Search your videos" />
            </div>
          </div>
        </div>
        <Card>
          <div className="overflow-x-scroll">
            <Table headers={[
              { content: 'video', className: 'text-left' },
              { content: 'duration', className: 'text-right' },
              { content: 'status', className: 'text-left' },
              { content: 'date', className: 'text-left' },
              { content: 'actions' }
            ]}>
              {videoSet.slice(page * perPage, (page + 1) * perPage).map((video, index) => (
                <Table.Row
                  key={video.id}
                  dataKey={video.id}
                  className={`even:bg-gray-100 hover:bg-gray-200 ${video.status !== 'DELETED' ? 'cursor-pointer' : ''}`}
                  onClick={props.auth.user.integrationType === 0 && video.status !== 'DELETED' ? redirectToVideoDetail : () => {}}
                >
                  <Table.Col>
                    <div className="flex">
                      <div className="flex-none">
                        {video.status === 'AVAILABLE' ? (
                          <img
                            className="mr-3"
                            src={video.thumbnail}
                            onError={e => {
                              e.target.src = thumbnailPlaceholder;
                            }}
                            alt="Video Thumbnail"
                            width="100"
                            height="56"
                          />
                        ) : (
                          <img
                            className="mr-3"
                            src={thumbnailPlaceholder}
                            alt="Video Thumbnail"
                            width="100"
                            height="56"
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        {video.title}
                        <div className="font-mono text-gray-500 text-xs">{video.id}</div>
                      </div>
                    </div>
                  </Table.Col>
                  <Table.Col className="text-right">
                    {HelperVideo.formatDuration(video.duration)}
                  </Table.Col>
                  <Table.Col>
                    {HelperVideo.getVideoStatus(video.status)
                      .percentage ? (
                        <>
                          <Badge color={HelperVideo.getVideoStatus(video.status).color}>
                            {video.status.replace('_', ' ')}
                          </Badge>
                        </>
                      ) : (
                        <Badge color={HelperVideo.getVideoStatus(video.status).color}>
                          {video.status.replace('_', ' ')}
                        </Badge>
                      )}
                  </Table.Col>
                  <Table.Col>
                    {moment(video.createdAt).format('MMM D, YYYY')}
                  </Table.Col>
                  <Table.Col className="text-center">
                    {video.status === 'AVAILABLE' || video.status === 'DELETED' ? (
                      <Link to={`/analytics/videos/${video.id}`} className="mr-3" onClick={e => e.stopPropagation()}>
                        <Icon.BarChart2 className="inline mr-1 cursor-pointer fill-current text-gray-600 hover:text-gray-900" width="20" height="20" />
                      </Link>
                    ) : (
                      <Icon.BarChart2 className="inline cursor-not-allowed fill-current text-gray-400 mr-3" width="20" height="20" />
                    )}
                    {props.auth.user.integrationType === 0 && video.status === 'AVAILABLE' ? (
                      <Link to={`/videos/${video.id}/embed`} className="mr-3" onClick={e => e.stopPropagation()}>
                        <Icon.Code className="inline-block cursor-pointer text-gray-600 hover:text-gray-900" width="20" height="20" />
                      </Link>
                    ) : (
                      <Icon.Code className="inline-block cursor-not-allowed text-gray-400 mr-3" width="20" height="20" />
                    )}
                    {props.auth.user.integrationType !== 0 || video.status === 'DELETED' ? (
                      <Icon.Trash2 className="inline-block cursor-not-allowed text-gray-400" width="20" height="20" />
                    ) : (
                      <Link to={''} onClick={e => deleteVideo(e, video.id)}>
                        <Icon.Trash2 className="inline-block cursor-pointer text-gray-600 hover:text-pink-600" width="20" height="20" />
                      </Link>
                    )}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table>
          </div>
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm leading-5 text-gray-700">
                  Showing <span className="font-medium">{Math.max(1, page * perPage)}</span> to <span className="font-medium">{Math.min((page + 1) * perPage, videoSet.length)}</span> of <span className="font-medium">{videoSet.length}</span> videos
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex shadow-sm">
                  {page === 0 ? (
                    <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-400 transition ease-in-out duration-150 cursor-not-allowed" aria-label="Previous">
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                  ) : (
                    <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-600 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-600 transition ease-in-out duration-150" aria-label="Previous" onClick={prevPage}>
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                  )}
                  {page === Math.floor(videoSet.length / perPage) ? (
                    <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-400 transition ease-in-out duration-150 cursor-not-allowed" aria-label="Next">
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                  ) : (
                    <button type="button" className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-600 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-600 transition ease-in-out duration-150" aria-label="Next" onClick={nextPage}>
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </Card>
      </Page>
    );
}

VideoList.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  video: PropTypes.object.isRequired,
  createVideo: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func.isRequired,
  getVideo: PropTypes.func.isRequired,
  getVideoPlayback: PropTypes.func.isRequired,
  getVideos: PropTypes.func.isRequired,
  updateVideo: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    state => ({
      auth: state.auth,
      video: state.video
    }),
    dispatch => ({
      createVideo: video => dispatch(VideoActionCreators.createVideo(video)),
      deleteVideo: id => dispatch(VideoActionCreators.deleteVideo(id)),
      getVideo: id => dispatch(VideoActionCreators.getVideo(id)),
      getVideoPlayback: id => dispatch(VideoActionCreators.getVideoPlayback(id)),
      getVideos: () => dispatch(VideoActionCreators.getVideos()),
      updateVideo: video => dispatch(VideoActionCreators.updateVideo(video))
    })
  )(VideoList)
);
