import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/manipulateText";

const ReportBadge = ({ className = "", color = "primary", children }) => {
  const { t } = useTranslation();
  const colorMap = {
    primary: {
      border: "border-blue-200",
      color: "bg-blue-100",
      text: "text-blue-800",
    },
    secondary: {
      border: "border-gray-400",
      color: "bg-gray-300",
      text: "text-gray-800",
    },
    danger: {
      border: "border-pink-200",
      color: "bg-pink-100",
      text: "text-pink-800",
    },
    orange: {
      border: "border-orange-200",
      color: "bg-orange-100",
      text: "text-orange-800",
    },
    purple: {
      border: "border-purple-200",
      color: "bg-purple-100",
      text: "text-purple-800",
    },
    info: {
      border: "border-blue-200",
      color: "bg-blue-100",
      text: "text-blue-800",
    },
    success: {
      border: "border-green-200",
      color: "bg-green-100",
      text: "text-green-800",
    },
    indigo: {
      border: "border-indigo-200",
      color: "bg-indigo-100",
      text: "text-indigo-800",
    },
    pink: {
      border: "border-pink-200",
      color: "bg-pink-100",
      text: "text-pink-800",
    },
  };

  return (
    <span
      className={`${colorMap[color].color} ${colorMap[color].border} ${colorMap[color].text} text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-indigo-900 dark:text-indigo-300 ${className}`}
    >
      {capitalize(t(children.toLowerCase()))}
    </span>
  );
};

ReportBadge.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default ReportBadge;
