import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Form from "../common/Form";
import Button from "../common/Button";
import Page from "../common/Page";
import Card from "../common/Card";
import Loading from "../common/Loading";
import * as Icon from "react-feather";

import APIManager from "../../managers/APIManager";

import { useTranslation } from "react-i18next";
import {
  capitalize,
  capitalizeFirst,
  upperCase,
} from "../../utils/manipulateText";
import LanguageSelector from "../common/LanguageSelector";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MessageText from "../common/MessageText";

const PaymentEdit = ({
  auth,
  loading,
  setLoading,
  paymentInfo,
  paymentData,
}) => {
  const { t } = useTranslation();
  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [iban, setIban] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routing, setRouting] = useState("");
  const [swift, setSwift] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankCountry, setBankCountry] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [paymentCurrency, setPaymentCurrency] = useState("");
  const [initIban, setInitIban] = useState("");
  const history = useHistory();

  useEffect(() => {
    setBank(paymentInfo?.bank || "");
    setAddress(paymentInfo?.address || "");
    setPaymentCurrency(paymentInfo?.paymentCurrency || "");
    setBranch(paymentInfo?.branch || "");
    setIban(paymentInfo?.iban || "");
    setInitIban(paymentInfo?.iban || "");
    setAccountNumber(paymentInfo?.accountNumber || "");
    setRouting(paymentInfo?.routing || "");
    setSwift(paymentInfo?.swift || "");
    setBankAddress(paymentInfo?.bankAddress || "");
    setBankCountry(paymentInfo?.bankCountry || "");
    setName(paymentInfo?.name || "");
  }, [paymentInfo]);

  const validateForm = () => {
    const fields = [
      bank,
      branch,
      iban,
      accountNumber,
      routing,
      swift,
      bankAddress,
      bankCountry,
      name,
      address,
      paymentCurrency,
    ];

    const isFormValid = fields.every((field) => field.trim() !== "");

    if (!isFormValid) {
      window.alert(t(capitalizeFirst("please fill in all required fields.")));
    }

    return isFormValid;
  };

  const savePaymentInfo = () => {
    if (validateForm()) {
      setLoading(true);
      APIManager.savePaymentInfo({
        bank,
        branch,
        iban,
        routing,
        swift,
        bankAddress,
        accountNumber,
        bankCountry,
        name,
        address,
        paymentCurrency,
      }).then((res) => {
        window.alert(
          t(
            capitalizeFirst(
              "your payment information has been updated successfully!"
            )
          )
        );
        setLoading(false);
      });
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Page
        controlClass="w-full items-center justify-between"
        title={
          <>
            <div className={"flex justify-between my-1"}>
              <h2 className="my-auto text-xl font-semibold text-gray-700 tracking-tight leading-tight">
                {capitalize(t("payment details"))}
              </h2>
              <LanguageSelector customStyle="ml-auto my-auto" />
            </div>
          </>
        }
      >
        <div className="w-full">
          <Card className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-16">
              <div>
                <Form.FormTextInput
                  name="owner-name"
                  label="payment account owner name"
                  placeholder={capitalizeFirst(t("account owner's name"))}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Textarea
                  name="owner-address"
                  label="payment account owner address"
                  placeholder={capitalizeFirst(t("account owner's address"))}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="paymentCurrency"
                  >
                    {capitalize(t("payment currency"))}
                  </label>
                  <select
                    id="paymentCurrency"
                    className="px-3 py-2 w-full rounded bg-gray-100 border border-gray-200"
                    value={paymentCurrency}
                    onChange={(e) => setPaymentCurrency(e.target.value)}
                  >
                    <option value="" disabled>
                      {capitalizeFirst(t("select currency"))}
                    </option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
              <div>
                <Form.FormTextInput
                  name="bank-name"
                  label="bank name"
                  placeholder={capitalizeFirst(t("enter bank name"))}
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                />
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col justify-end">
                    <Form.FormTextInput
                      name="branch-name"
                      Breakdowns
                      Platforms
                      label="branch name"
                      placeholder={capitalizeFirst(t("enter branch name"))}
                      value={branch}
                      onChange={(e) => setBranch(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col justify-end">
                    <Form.FormTextInput
                      name="bank-counrty"
                      label="bank country"
                      placeholder={capitalizeFirst(t("enter bank country"))}
                      value={bankCountry}
                      onChange={(e) => setBankCountry(e.target.value)}
                    />
                  </div>
                </div>
                <Form.Textarea
                  name="bank-address"
                  label="bank address"
                  placeholder={capitalizeFirst(t("enter bank address"))}
                  value={bankAddress}
                  onChange={(e) => setBankAddress(e.target.value)}
                />
              </div>
              <div>
                <Form.FormTextInput
                  name="iban"
                  label="IBAN"
                  placeholder={capitalizeFirst(t("enter IBAN"))}
                  value={iban}
                  onChange={(e) => setIban(e.target.value)}
                />
                <Form.FormTextInput
                  name="account-number"
                  label="account number"
                  placeholder={capitalizeFirst(t("enter account number"))}
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
                <div className="pb-4 text-gray-600 text-xs">
                  <Icon.Info className="inline mr-2" width="16" height="16" />
                  {capitalizeFirst(t("you can enter iban or account number"))}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Form.FormTextInput
                    name="routing-code"
                    label="routing code"
                    value={routing}
                    onChange={(e) => setRouting(e.target.value)}
                  />
                  <Form.FormTextInput
                    name="swift-code"
                    label="swift code"
                    value={swift}
                    onChange={(e) => setSwift(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:flex-row md:justify-evenly">
              <div className="flex-1">
                <MessageText
                  message={"please fill in all required fields."}
                  textAlignment="text-left"
                  customClass=""
                />
              </div>

              <div className="flex flex-row">
                <Button
                  className="px-4 mr-2 whitespace-nowrap overflow-hidden"
                  color="secondary"
                  type="button"
                  onClick={(e) => history.push("/payments")}
                >
                  {upperCase(t("go back"))}
                </Button>
                <Button
                  className="px-4"
                  color="login"
                  type="submit"
                  onClick={(e) => savePaymentInfo()}
                >
                  {upperCase(t("save"))}
                </Button>
              </div>
            </div>
            <div className="clearfix"></div>
          </Card>
        </div>
      </Page>
    );
  }
};

PaymentEdit.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  site: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(PaymentEdit));
