import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { upperCase } from '../../utils/manipulateText';

const Badge = ({ className = '', color = 'primary', children }) => {
  const { t } = useTranslation();
  const colorMap = {
    primary: {
      border: 'border-blue-200',
      color: 'bg-blue-100',
      text: 'text-blue-900'
    },
    secondary: {
      border: 'border-gray-400',
      color: 'bg-gray-300',
      text: 'text-gray-800'
    },
    danger: {
      border: 'border-pink-200',
      color: 'bg-pink-100',
      text: 'text-pink-900'
    },
    orange: {
      border: 'border-orange-200',
      color: 'bg-orange-100',
      text: 'text-orange-900'
    },
    purple: {
      border: 'border-purple-200',
      color: 'bg-purple-100',
      text: 'text-purple-900'
    },
    info: {
      border: 'border-blue-200',
      color: 'bg-blue-100',
      text: 'text-blue-900'
    },
    success: {
      border: 'border-green-200',
      color: 'bg-green-100',
      text: 'text-green-900'
    }
  };

  return (
    <span className={`${colorMap[color].color} ${colorMap[color].border} border px-2 py-1 leading-tight inline-flex items-center rounded ${className}`}>
      <span className={`text-xs font-medium ${colorMap[color].text}`}>
        {upperCase(t(children.toLowerCase()))}
      </span>
    </span>
  );
};

Badge.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string
};

export default Badge;
