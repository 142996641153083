import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ScheduledReports from "./ScheduledReports";
import AsyncReports from "./AsyncReports";

const Reports = (props) => {
  if (props.match.params.subaction === "scheduled") {
    return <ScheduledReports />;
  }
  return <AsyncReports />;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(Reports));
