import PropTypes from 'prop-types';
import * as React from 'react';

const Progress = ({ className = '', value }) => (
  <progress className={className} id="file" max="100" value="32"></progress>
);

Progress.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  value: PropTypes.any
};

export default Progress;
