import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { NavLink, withRouter } from "react-router-dom";

import Button from "../common/Button";
import Badge from "../common/Badge";
import Page from "../common/Page";
import Card from "../common/Card";
import Table from "../common/Table";
import Loading from "../common/Loading";

import { useTranslation } from "react-i18next";
import { capitalize, capitalizeFirst } from "../../utils/manipulateText";
import LanguageSelector from "../common/LanguageSelector";
import { Translation } from "react-i18next";
import MessageText from "../common/MessageText";

const PaymentList = ({
  auth,
  loading,
  setLoading,
  paymentInfo,
  paymentData,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <Loading />;
  }

  return (
    <Page
      title={
        <>
          <div className={"flex justify-between items-center my-1"}>
            <h2 className="text-xl font-semibold text-gray-700 tracking-tight leading-tight">
              <Translation>{(t) => capitalizeFirst(t("payments"))}</Translation>
            </h2>
            <LanguageSelector customStyle="ml-auto" />
          </div>
        </>
      }
      controls={
        <Button
          RootComponent={withRouter(NavLink)}
          to="/payments/edit"
          color="primary"
          disabled={false}
        >
          <Icon.CreditCard className="inline mr-2" width="16" height="16" />
          {t("update payment info")}
        </Button>
      }
    >
      {paymentData?.length > 0 ? (
        <Card className="w-2/3">
          <div className="overflow-x-scroll">
            <Table
              headers={[
                { content: "period", className: "text-left" },
                { content: "status", className: "text-left" },
                { content: "action date", className: "text-left" },
                { content: "this period", className: "text-left" },
                { content: "total", className: "text-left" },
                { content: "note", className: "text-left" },
              ]}
            >
              {paymentData?.map((payment, index) => (
                <Table.Row
                  key={payment.term}
                  dataKey={payment.termStr}
                  className={"even:bg-gray-100 hover:bg-gray-200"}
                >
                  <Table.Col>{payment.termStr}</Table.Col>
                  <Table.Col>
                    {payment.paid === 0 ? (
                      <Badge
                        key={payment.paid}
                        color="danger"
                        className="ml-1 inline-block"
                      >
                        unpaid
                      </Badge>
                    ) : (
                      [
                        payment.paid === 1 ? (
                          <Badge
                            key={payment.paid}
                            color="danger"
                            className="ml-1 inline-block"
                          >
                            unpaid
                          </Badge>
                        ) : (
                          [
                            payment.paid === 2 ? (
                              <Badge
                                key={payment.paid}
                                color="danger"
                                className="ml-1 inline-block"
                              >
                                unpaid
                              </Badge>
                            ) : (
                              <Badge
                                key={payment.paid}
                                color="success"
                                className="ml-1 inline-block"
                              >
                                paid
                              </Badge>
                            ),
                          ]
                        ),
                      ]
                    )}
                  </Table.Col>
                  <Table.Col>
                    {payment.paymentDate
                      ? moment(payment.paymentDate).format("ddd, MM/D/YYYY")
                      : "-"}
                  </Table.Col>
                  <Table.Col className="font-semibold">
                    ${payment.amount}
                  </Table.Col>
                  <Table.Col>${payment.total}</Table.Col>
                  <Table.Col className="text-xs">
                    {payment.paid === 0
                      ? capitalizeFirst(
                          t("earn x to get paid.", {
                            more: `$${250 - payment.total}`,
                          })
                        )
                      : ""}
                    {payment.note ? payment.note : ""}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table>
          </div>

          <MessageText
            message={"you get paid when your total earnings reach to $250."}
            customClass={"p-4 text-blue-600 text-xs justify-center"}
            textAlignment="text-left"
          />
        </Card>
      ) : paymentInfo?.iban ? (
        <MessageText
          message={
            "Your payment information has been received. You can view the details on this page once your payment is processed."
          }
        />
      ) : (
        <MessageText
          message={
            "No payment information is attached to your account. Please update your payment information."
          }
        />
      )}
    </Page>
  );
};

PaymentList.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  site: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(PaymentList));
