import { capitalize } from "lodash";
import React from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
const TagsInput = ({ tags, setTags, isTagsEmpty, setIsTagsEmpty }) => {
  const { t } = useTranslation();
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  return (
    <div
      id="tags-input"
      onClick={() => setIsTagsEmpty(false)}
      className={`tags-input flex flex-wrap w-96 py-1 overflow-auto ${
        isTagsEmpty ? "border border-red-400 rounded" : "border-none"
      } bg-gray-200`}
      style={{ maxHeight: "10rem", minHeight: "4rem" }}
    >
      <ul id="tags" className="flex flex-wrap p-0 m-2">
        {tags.map((tag, index) => (
          <li
            key={index}
            className="tag rounded-lg bg-gray-500 text-white px-2 py-1 m-1 flex justify-center items-center"
          >
            <span className="text-md font-medium text-white mr-2">{tag}</span>
            <span onClick={() => removeTags(index)} className="cursor-pointer">
              <Icon.XCircle width={16} height={16} />
            </span>
          </li>
        ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder={capitalize(t("press enter to add"))}
        className="flex-1 border-none h-10 text-md p-3 mt-2 focus:outline-none bg-gray-200"
      />
    </div>
  );
};

export default TagsInput;
