export const actionTypes = {
  SET_SELECTED_BREAKDOWNS: "SET_SELECTED_BREAKDOWNS",
  SET_SELECTED_PLATFORMS: "SET_SELECTED_PLATFORMS",
  SET_SELECTED_SITE: "SET_SELECTED_SITE",
  SET_SELECTED_USER_APPS: "SET_SELECTED_USER_APPS",
  SET_SELECTED_USER_COUNTRIES: "SET_SELECTED_USER_COUNTRIES",
  SET_SELECTED_COUNTRIES: "SET_SELECTED_COUNTRIES",
  SET_SELECTED_APPNAMES: "SET_SELECTED_APPNAMES",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SET_MONTH_DAY: "SET_MONTH_DAY",
  SET_WEEK_DAY: "SET_WEEK_DAY",
  SET_TIMEZONE: "SET_TIMEZONE",
  SET_SITE_BREAKDOWNS: "SET_SITE_BREAKDOWNS",
  SET_SITE_PLATFORMS: "SET_SITE_PLATFORMS",
  SET_SELECTED_HOUR: "SET_SELECTED_HOUR",
  SET_STATUS: "SET_STATUS",
  SET_REPORT_NAME: "SET_REPORT_NAME",
  SET_REPORT_PERIOD: "SET_REPORT_PERIOD",
  SET_EMAILS: "SET_EMAILS",
  SET_SELECTED_PLACEMENTS: "SET_SELECTED_PLACEMENTS",
  SET_SELECTED_SITE_PLACEMENTS: "SET_SELECTED_SITE_PLACEMENTS",
  REPLACE_ALL: "REPLACE_ALL",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_BREAKDOWNS:
      return { ...state, selectedBreakdowns: action.payload };
    case actionTypes.SET_SELECTED_PLATFORMS:
      return { ...state, selectedPlatforms: action.payload };
    case actionTypes.SET_SELECTED_SITE:
      return { ...state, selectedSite: action.payload };
    case actionTypes.SET_SELECTED_USER_APPS:
      return { ...state, selectedUserApps: action.payload };
    case actionTypes.SET_SELECTED_USER_COUNTRIES:
      return { ...state, selectedUserCountries: action.payload };
    case actionTypes.SET_SELECTED_SITE_PLACEMENTS:
      return { ...state, selectedSitePlacements: action.payload };
    case actionTypes.SET_SELECTED_COUNTRIES:
      return { ...state, selectedCountries: action.payload };
    case actionTypes.SET_SELECTED_APPNAMES:
      return { ...state, selectedAppNames: action.payload };
    case actionTypes.SET_DATE_RANGE:
      return { ...state, dateRange: action.payload };
    case actionTypes.SET_MONTH_DAY:
      return { ...state, monthDay: action.payload };
    case actionTypes.SET_WEEK_DAY:
      return { ...state, weekDay: action.payload };
    case actionTypes.SET_TIMEZONE:
      return { ...state, timezone: action.payload };
    case actionTypes.SET_SITE_BREAKDOWNS:
      return { ...state, siteBreakdowns: action.payload };
    case actionTypes.SET_SITE_PLATFORMS:
      return { ...state, sitePlatforms: action.payload };
    case actionTypes.SET_SELECTED_HOUR:
      return { ...state, selectedHour: action.payload };
    case actionTypes.SET_STATUS:
      return { ...state, status: action.payload };
    case actionTypes.SET_REPORT_NAME:
      return { ...state, reportName: action.payload };
    case actionTypes.SET_REPORT_PERIOD:
      return { ...state, reportPeriod: action.payload };
    case actionTypes.SET_EMAILS:
      return { ...state, emails: action.payload };
    case actionTypes.SET_SELECTED_PLACEMENTS:
      return { ...state, selectedPlacements: action.payload };
    case actionTypes.REPLACE_ALL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const initialState = {
  name: "Create New Report",
  operation: "create",
  id: "",
  selectedBreakdowns: [],
  selectedPlatforms: [],
  selectedSite: "",
  selectedUserApps: [],
  selectedUserCountries: [],
  selectedCountries: [],
  selectedAppNames: [],
  dateRange: "daily",
  monthDay: 1,
  weekDay: 0,
  timezone: 0,
  siteBreakdowns: [],
  sitePlatforms: [],
  selectedHour: 0,
  status: 1,
  reportName: "",
  reportPeriod: "daily",
  emails: [],
  selectedPlacements: [],
  selectedSitePlacements: [],
};
