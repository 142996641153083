import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atelierLakesideDark as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Page from "../common/Page";
import Card from "../common/Card";

import { useTranslation } from "react-i18next";
import {
  capitalize,
  capitalizeFirst,
  upperCase,
} from "../../utils/manipulateText";
import styled from "styled-components";
import LanguageSelector from "../common/LanguageSelector";
import { Translation } from "react-i18next";
import dropdown from "../../assets/images/down.png";
import check from "../../assets/images/check.png";
import { NavLink } from "react-router-dom";
import S from "../../store";
import { SiteActionCreators } from "../../store/actions/site";
import SiteSelector from "../common/SiteSelector";

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  max-height: 400px;
`;

const AdsTxt = ({ site, sites }) => {
  const { t, i18n } = useTranslation();
  const [siteSelection, setSiteSelection] = useState(false);
  const [siteList, setSiteList] = useState(sites);
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState(site);

  useEffect(() => {
    const siteListTmp = sites.filter(
      (site) => site.virgulId != "all-sites" && site.virgulId != ""
    );
    const selectedSiteTmp =
      selectedSite.virgulId === "all-sites" || selectedSite.virgulId === ""
        ? sites[0]
        : selectedSite;
    setSiteList([...siteListTmp]);
    setSelectedSite(selectedSiteTmp);
  }, [selectedSite]);

  const toggleSelectedIndex = (e) => {
    setSiteSelection(false);
    setLoading(true);
    S.store
      .dispatch(SiteActionCreators.getSite(e.id))
      .then(() => setLoading(false));
  };

  return (
    <Page
      title={
        <>
          <div className={"flex justify-between items-center"}>
            <SiteSelector
              site={site}
              sites={siteList}
              siteSelection={siteSelection}
              toggleSiteSelect={(e) => setSiteSelection((prev) => !prev)}
              handleClick={(e) => {
                toggleSelectedIndex(e);
              }}
              handleRedirect={(e) => {
                return `/settings/adstxt`;
              }}
            />

            <LanguageSelector customStyle="ml-auto" />
          </div>
        </>
      }
    >
      <div className="flex">
        <div className="w-full mr-4">
          <Card>
            <span className="sm:inline-flex p-1 border bg-gray-200 rounded-md mx-4 mt-4">
              <Link
                to="/settings"
                className="transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded"
                data-tagcontent="0"
              >
                {upperCase(t("ad integration"))}
              </Link>
              <Link
                to="/settings/adstxt"
                className="transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded bg-white shadow-md"
                data-tagcontent="1"
              >
                ADS.TXT
              </Link>
            </span>
            <Card.Body>
              {i18n.language === "en" && (
                <p className="text-xs text-gray-600">
                  You need to set up an Ads.txt file to list Empower's
                  authorized selling seas on the ad exchanges to help prevent ad
                  fraud. The Ads.txt lines will help you to prevent your site
                  from two main types of problems– unauthorized reselling of
                  your ad inventory through arbitration, and more importantly,
                  ad fraud through misrepresentation or counterfeit ad
                  impressions.
                </p>
              )}
              <p className="text-xs text-gray-600 mt-2">
                {t(
                  "Here are your site's specific ads.txt lines. You can download it from the button below that says Download Ads.txt. You'll need to take this file and upload it to the root directory of your site so it's accessible when you visit this page of your site"
                )}
                <span>: domain.com</span>
              </p>
              <p className="text-xs text-gray-600 mt-2 mb-4">
                {t(
                  "You may need to get FTP access in order to upload a file to your root directory, so please check with your hosting company or your CMS's support portal."
                )}
              </p>
              <a
                className="bg-white border border-gray-100 shadow-md px-3 py-1 inline-flex justify-center rounded-lg text-xs mr-3 mb-4 group hover:bg-empower-500 hover:text-white"
                href="/ads.txt"
                target="_blank"
              >
                <Icon.Download
                  className="cursor-pointer text-gray-600 group-hover:text-white mr-2"
                  width="16"
                  height="16"
                />
                {capitalizeFirst(t("download ads.txt file"))}
              </a>
              <StyledSyntaxHighlighter
                language="xml"
                style={codeStyle}
                useInlineStyles="true"
              >
                {selectedSite?.adsTxt?.join("\n")}
              </StyledSyntaxHighlighter>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Page>
  );
};

AdsTxt.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    site: state.site.selectedSite,
    sites: state.site.sites,
  };
};

export default connect(mapStateToProps)(withRouter(AdsTxt));
