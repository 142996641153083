import React, { useEffect, useState } from "react";
import { capitalize, capitalizeFirst } from "../../../utils/manipulateText";
import PopupMenu from "./PopupMenu";
import { useTranslation, Translation } from "react-i18next";
import { modalConstants } from "./constants/modalConstants";
import MessageText from "../../common/MessageText";

const ReportTable = ({
  reports,
  integrationType,
  setRefreshData,
  setShowDeleteModal,
  setModalId,
  setModalReportName,
  setShowConfig,
  initialState,
  setInitialState,
}) => {
  const { t } = useTranslation();

  const headerNames = [
    "report name",
    "recipients",
    "report period",
    "date range",
    "breakdowns",
    "platforms",
    "last update",
    "report status",
    "",
  ];
  var tableHeaders = (
    <thead>
      <tr className="border-gray-200 bg-white text-sm">
        {headerNames.map((name) => {
          return (
            <th
              key={Math.random()}
              className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider"
            >
              {capitalize(t(name))}
            </th>
          );
        })}
      </tr>
    </thead>
  );

  var tableBody = (
    <tbody>
      {reports.map((report) => (
        <tr
          data-id={report.id}
          key={Math.random()}
          className="border-gray-200 bg-white text-sm hover:bg-gray-200 even:bg-gray-100 "
        >
          <td className="px-5 py-5 border-b text-center">
            <p className="text-gray-900 whitespace-no-wrap font-semibold ">
              {report.reportName}
            </p>
          </td>
          <td className="px-5 py-5 border-b text-center font-medium">
            {report.recipients.split(";").map((rec) => (
              <>
                <span
                  key={Math.random()}
                  className="bg-gray-200 text-black-800 px-3 py-1 my-1 font-medium rounded-full inline-block "
                >
                  {rec}
                </span>
                <br />
              </>
            ))}
          </td>
          <td className="px-5 py-5 border-b text-center font-medium">
            {capitalizeFirst(t(report.period))}
          </td>
          <td className="px-5 py-5 border-b text-center font-medium">
            {report.reportDateRange === "daily" && capitalize(t("last day"))}
            {report.reportDateRange === "monthly" &&
              capitalize(t("last 30 days"))}
            {report.reportDateRange === "weekly" &&
              capitalize(t("last 7 days"))}
            {report.reportDateRange === "lastWeek" &&
              capitalize(t("last week"))}
            {report.reportDateRange === "lastMonth" &&
              capitalize(t("last month"))}
          </td>
          <td className="px-5 py-5 border-b text-center">
            <div className="flex flex-row flex-wrap justify-center">
              {report.breakdowns.map((bd) => (
                <span
                  key={Math.random()}
                  className="relative inline-block p-1 m-1 font-semibold leading-tight"
                >
                  <span
                    aria-hidden
                    className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
                  ></span>
                  <span className="relative">
                    {report.reportType === "app"
                      ? capitalizeFirst(t(bd))
                      : capitalizeFirst(
                          t(modalConstants.siteBreakdownsAll[bd])
                        )}
                  </span>
                </span>
              ))}
            </div>
          </td>
          <td className="px-5 py-5 border-b text-center">
            <div className="flex flex-row flex-wrap justify-center">
              {
                <>
                  {report.platforms.map((platform) => (
                    <span
                      key={Math.random()}
                      className="relative inline-block p-1 m-1 font-semibold leading-tight text-center"
                    >
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-blue-200 opacity-50 rounded-full w-full"
                      ></span>
                      <span className="relative">
                        {report.reportType === "site"
                          ? capitalizeFirst(t(platform))
                          : platform}
                      </span>
                    </span>
                  ))}
                </>
              }
            </div>
          </td>
          <td className="px-5 py-5 border-b text-center">{report.updatedAt}</td>
          <td className="px-5 py-5 border-b text-center">
            <span className="relative inline-block px-3 py-1 font-semibold leading-tight">
              <span
                aria-hidden
                className={`absolute inset-0 bg-${
                  report.reportStatus ? "green" : "red"
                }-200 opacity-50 rounded-full`}
              ></span>
              <span className="relative">
                {report.reportStatus
                  ? capitalize(t("active"))
                  : capitalize(t("inactive"))}
              </span>
            </span>
          </td>
          <td className={`px-5 py-5 border-b text-right `}>
            <PopupMenu
              integrationType={integrationType}
              report={report}
              setRefreshData={setRefreshData}
              setShowDeleteModal={setShowDeleteModal}
              setModalId={setModalId}
              setModalReportName={setModalReportName}
              setShowConfig={setShowConfig}
              initialState={initialState}
              setInitialState={setInitialState}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      {!reports.length && (
        <div className="w-full m-auto max-w-4xl text-center text-gray-700">
          <MessageText
            message={"there are no reports scheduled for your account."}
            textAlignment="text-center"
            customClass=""
          />
        </div>
      )}
      {reports.length > 0 && (
        <div className="overflow-x-scroll lg:overflow-x-visible">
          <div className="relative">
            <table className="min-w-full leading-normal bg-gray-100">
              {tableHeaders}
              {tableBody}
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportTable;
