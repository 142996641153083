import { PlayerActions } from '../actions/player';

const INITIAL_STATE = {
  players: []
};

function player(state = INITIAL_STATE, action) {
  let players;
  switch (action.type) {
    case PlayerActions.CREATE_PLAYER:
      players = state.players.slice();
      players.push(action.payload.player);
      return {
        ...state,
        players
      };
    case PlayerActions.DELETE_PLAYER:
    case PlayerActions.GET_PLAYER:
    case PlayerActions.UPDATE_PLAYER:
      players = state.players.slice().filter(player => player.id !== action.payload.player.id);
      players.push(action.payload.player);
      return {
        ...state,
        players
      };
    case PlayerActions.GET_PLAYERS:
      return {
        ...state,
        players: action.payload.players
      };
    case PlayerActions.INVALIDATE_PLAYERS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default { player };
