import React, { useState, useEffect, useRef } from "react";
import { capitalize, upperCase } from "../../utils/manipulateText";
import { useTranslation } from "react-i18next";
import dropdown from "../../assets/images/dropdown.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDisplay } from "@fortawesome/free-solid-svg-icons";
import { Translation } from "react-i18next";

const PlatformSelectBox = ({
  title,
  customClass,
  onPlatformChange,
  platformFilter,
  filters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const platformData = [
    {
      filterText: "all",
      dataTag: "all",
      printText: "all",
    },
    {
      filterText: "desktop",
      dataTag: "Desktop",
      printText: "desktop",
    },
    {
      filterText: "mobile",
      dataTag: "Mobile",
      printText: "mobile",
    },
    {
      filterText: "android",
      dataTag: "Android",
      printText: "Android App",
    },
    {
      filterText: "ios",
      dataTag: "iOS",
      printText: "iOS App",
    },
    {
      filterText: "amp",
      dataTag: "AMP",
      printText: "amp",
    },
  ];

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative dropdown ${title} ${customClass}`}
      ref={dropdownRef}
    >
      <button
        className="sm:inline-flex items-center justify-start border bg-gray-200 rounded-md transition-all ease-out transition-fast text-xs font-bold py-2"
        onClick={handleButtonClick}
      >
        <div className="flex items-center ">
          <FontAwesomeIcon
            style={{ width: "18", height: "18" }}
            className="mr-2 ml-3 text-gray-600"
            icon={faDisplay}
          />
          <div className="flex items-center ">
            <Translation>
              {(t) => (
                <span className="ml-1">
                  {upperCase(t(title.toLowerCase()))}
                </span>
              )}
            </Translation>
            <img
              style={{ width: "15%" }}
              alt=""
              className="mx-auto"
              src={dropdown}
            />
          </div>
        </div>
      </button>
      {isOpen && (
        <div
          className="absolute bg-white z-50 p-3 rounded-md border text-md font-medium font-sans"
          style={{ minWidth: "150px" }}
        >
          <div className="flex flex-col space-y-2 font-semibold text-gray-600">
            <div
              key="all"
              className="flex items-center justify-between rounded-md cursor-pointer p-1 px-2 hover:text-indigo-500 hover:bg-gray-200"
              onClick={() => onPlatformChange("all")}
              data-platform="all"
            >
              <p>{capitalize(t("all"))}</p>
              {platformFilter === "all" && <FontAwesomeIcon icon={faCheck} />}
            </div>
            {platformData.map(
              (platform) =>
                filters.includes(platform.filterText) && (
                  <div
                    key={platform.filterText}
                    className="flex items-center justify-between rounded-md cursor-pointer p-1 px-2 hover:text-indigo-500 hover:bg-gray-200"
                    onClick={() => onPlatformChange(platform.dataTag)}
                    data-platform={platform.dataTag}
                  >
                    <p>
                      {platform.filterText.includes("ios")
                        ? t("iOS App")
                        : capitalize(t(platform.printText))}
                    </p>
                    {platformFilter === platform.dataTag && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformSelectBox;
