import PropTypes from 'prop-types';
import * as React from 'react';

import UploadVideo from './UploadVideo';
import VideoEdit from './VideoEdit';
import VideoEmbed from './VideoEmbed';
import VideoList from './VideoList';

const Videos = (props) => {
    let childComponent;
    if (props.match.params.subaction === 'embed') {
      childComponent = <VideoEmbed video={props.match.params.video} />;
    } else if (props.match.params.video === 'upload') {
      childComponent = <UploadVideo />;
    } else if (props.match.params.video) {
      childComponent = <VideoEdit video={props.match.params.video} />;
    } else {
      childComponent = <VideoList />;
    }
    return childComponent;
}

Videos.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default Videos;
