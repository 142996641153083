import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, forwardRef } from "react";

import dropdown from "../../assets/images/dropdown.png";
import selectClose from "../../assets/images/select-close.png";
import * as Icon from "react-feather";

import { Translation } from "react-i18next";
import { capitalizeFirst, upperCase } from "../../utils/manipulateText";
import { useTranslation } from "react-i18next";
const MultiselectBox = forwardRef(function MultiselectBox(props, ref) {
  const [mode, setMode] = useState(props.mode || "multiple");
  const selectedItemsRef = useRef([...props.selectedItems]);
  const [selectedItemsLength, setSelectedItemsLength] = useState(0);
  const element = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    selectedItemsRef.current = [...props.selectedItems];
    setSelectedItemsLength(props.selectedItems.length);
  }, [props.selectedItems]);

  useEffect(() => {
    const actionEl = element.current;
    const selectionDiv = actionEl.nextSibling;
    window.addEventListener("click", (e) => {
      let target = e.target;
      while (target != null) {
        if (
          target === selectionDiv ||
          (target.classList && target.classList.contains("close-image"))
        ) {
          return;
        } else if (target === actionEl) {
          if (!selectionDiv.classList.contains("hidden"))
            props.onChange(selectedItemsRef.current);
          selectionDiv.classList.toggle("hidden");
          return;
        } else {
          target = target.parentNode;
        }
      }
      if (selectionDiv.classList.contains("hidden")) {
        return;
      }
      selectionDiv.classList.toggle("hidden");
      props.onChange(selectedItemsRef.current);
    });
  }, []);

  const onChange = (e) => {
    const selected =
      e.target.dataset.select ||
      e.target.parentNode.dataset.select ||
      e.target.parentNode.parentNode.dataset.select;
    const selectedItemsVar = JSON.parse(
      JSON.stringify(selectedItemsRef.current)
    );
    if (mode === "single") {
      if (selectedItemsVar.indexOf(selected) === -1) {
        selectedItemsVar.push(selected);
      }
      if (selectedItemsVar.length > 1) {
        selectedItemsVar.splice(0, 1);
      }
    } else {
      if (selectedItemsVar.indexOf(selected) === -1) {
        selectedItemsVar.push(selected);
      } else {
        selectedItemsVar.splice(selectedItemsVar.indexOf(selected), 1);
      }
    }
    selectedItemsRef.current = selectedItemsVar;
    props.setSelectedItems(selectedItemsVar);
  };
  const [filterValue, setFilterValue] = useState("");

  const filteredOptions = props.options.filter(
    (el) =>
      el.name.toLowerCase().includes(filterValue.toLowerCase()) ||
      el.id.toString().includes(filterValue)
  );

  return (
    <div
      className={`w-full relative ${props.onConfig ? "md:w-1/2" : "md:w-full"}`}
    >
      {!props.onConfig && (
        <button
          className={`sm:inline-flex items-center justify-start ${
            selectedItemsRef.current.length > 0 ? "pt-1 px-1" : "p-1"
          } border bg-gray-200 rounded-md transition-all ease-out transition-fast text-xs font-bold py-2`}
          ref={element}
        >
          <div className="flex items-center">
            {selectedItemsRef.current.length > 0 && (
              <label className="transition-all ease-out transition-fast text-xs font-bold px-2 py-0 rounded m-0 my-0 mx-1 bg-white shadow">
                {selectedItemsRef.current.length}
              </label>
            )}
            <Translation>
              {(t) => (
                <span className="ml-1">
                  {upperCase(t(props.title.toLowerCase()))}
                </span>
              )}
            </Translation>
            <img
              style={{ width: "15%" }}
              alt=""
              className="mx-auto"
              src={dropdown}
            />
          </div>
        </button>
      )}
      {props.onConfig && (
        <button
          className={`block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${props.buttonCustomStyle}`}
          ref={element}
        >
          {selectedItemsLength > 0 && (
            <label
              className={`px-2 mr-3 transition-all ease-out transition-fast text-sm font-bold rounded bg-white shadow`}
            >
              {selectedItemsLength}
            </label>
          )}
          {capitalizeFirst(props.title)}
        </button>
      )}
      <div
        className={`border p-3 rounded-md text-sm font-semibold bg-white absolute z-50 hidden overflow-y-scroll ${props.customStyle}`}
        style={{ width: "350px", maxHeight: props.maxHeight }}
      >
        <div
          style={{ fontSize: 11.5 }}
          className="md:font-light text-md py-1 pl-5"
        >
          Select {props.title.toLowerCase()} in order to view selected data.
        </div>
        <div className="p-2 mx-2">
          <input
            type="text"
            className="border p-1 w-full rounded-md"
            placeholder={capitalizeFirst(t("adPlacementFilterText"))}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </div>
        <span className="inline-block pb-1 align-middle m-2">
          {filteredOptions.map((el) => (
            <div
              key={el.id}
              className="md:bg-gray-50 p-1 m-1 mb-2 float-left cursor-pointer"
              onClick={onChange}
              data-select={el.id}
            >
              <button
                className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded m-1 cursor-pointer ${
                  selectedItemsRef.current.indexOf("" + el.id) !== -1
                    ? "bg-white shadow"
                    : "bg-gray"
                }`}
              >
                <label className="mr-1 float-left cursor-pointer">
                  {el.name}
                </label>
                {selectedItemsRef.current.indexOf("" + el.id) !== -1 && (
                  <Icon.X
                    width="12"
                    height="12"
                    className="mx-auto my-auto text-gray-600"
                  />
                )}
              </button>
            </div>
          ))}
        </span>
      </div>
    </div>
  );
});

MultiselectBox.defaultProps = {
  mode: "multiple",
};

MultiselectBox.propTypes = {
  mode: PropTypes.string,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiselectBox;
