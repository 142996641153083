import React, { useEffect } from "react";
import { Translation, useTranslation } from "react-i18next";
import { capitalize, capitalizeFirst } from "../../../utils/manipulateText";
import { modalConstants } from "../ScheduledReports/constants/modalConstants";
import ReportBadge from "../../common/ReportBadge";
import MessageText from "../../common/MessageText";
const headerNames = [
  "report status",
  "created at",
  "completed at",
  "date range",
  "breakdowns",
  "platforms",
  "report type",
  "",
];

const AsyncReportTable = ({ reports, setRefreshData }) => {
  const { t } = useTranslation();

  var tableHeaders = (
    <thead>
      <tr className="border-gray-200 bg-white text-sm">
        {headerNames.map((name) => {
          return (
            <th
              key={Math.random()}
              className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider"
            >
              {capitalize(t(name))}
            </th>
          );
        })}
      </tr>
    </thead>
  );

  var tableBody = (
    <tbody>
      {reports.map((report) => (
        <tr
          data-id={report.id}
          key={Math.random()}
          className="border-gray-200 bg-white text-sm hover:bg-gray-200 even:bg-gray-100 "
        >
          <td className="px-5 py-3 border-b text-center">
            {report.reportStatus === "pending" ? (
              <span className="bg-yellow-100 text-yellow-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                {`${capitalizeFirst(t(report.reportStatus))}`}
              </span>
            ) : report.reportStatus === "resolved" ? (
              <span className="bg-green-100 text-green-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
                {`${capitalizeFirst(t(report.reportStatus))}`}
              </span>
            ) : (
              <span className="bg-red-100 text-red-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-red-900 dark:text-red-300">
                {`${capitalizeFirst(t(report.reportStatus))}`}
              </span>
            )}
          </td>

          <td className="px-5 py-3 border-b text-center">
            <p className="text-gray-900 whitespace-no-wrap font-medium ">
              {`${new Date(report.createdAt).toLocaleDateString()} - ${new Date(
                report.createdAt
              ).toLocaleTimeString()}`}
            </p>
          </td>
          <td className="px-5 py-3 border-b text-center font-medium">
            {report.completedAt != null
              ? `${new Date(
                  report.completedAt
                ).toLocaleDateString()} - ${new Date(
                  report.completedAt
                ).toLocaleTimeString()}`
              : "-"}
          </td>
          <td className="px-5 py-3 border-b text-center font-medium">
            {`${report.params.startDate} - ${report.params.endDate}`}
          </td>
          <td className="px-5 py-3 border-b text-center">
            {report.reportType === "app" ? (
              <div className="flex flex-row flex-wrap justify-center">
                {Object.keys(modalConstants.appBreakdownsAll).map((bd) => {
                  return report.params?.[bd] ? (
                    <span className="bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-indigo-900 dark:text-indigo-300">
                      {report.params?.[bd]
                        ? capitalize(t(modalConstants.appBreakdownsAll[bd]))
                        : null}
                    </span>
                  ) : null;
                })}
              </div>
            ) : (
              <div className="flex flex-row flex-wrap justify-center">
                {report.params.day && <ReportBadge>date</ReportBadge>}
                {report.params.month && <ReportBadge>month</ReportBadge>}
                {report.params.platform && <ReportBadge>platform</ReportBadge>}
                {report.params.tag && <ReportBadge>ad placement</ReportBadge>}
                {report.params.site && <ReportBadge>site</ReportBadge>}
                {report.params.appNames && <ReportBadge>app names</ReportBadge>}
                {report.params.zone && <ReportBadge>ad unit</ReportBadge>}
              </div>
            )}
          </td>
          <td className="px-5 py-3 border-b text-center">
            {report.reportType === "app" ? (
              <div className="flex flex-row flex-wrap justify-center">
                {report.params.platforms.length == 0 ||
                report.params.platforms.length == 2 ? (
                  <>
                    <span className="bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-indigo-900 dark:text-indigo-300">
                      {`iOS`}
                    </span>
                    <span className="bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-indigo-900 dark:text-yellow-300">
                      {`Android`}
                    </span>
                  </>
                ) : report.params.platforms.indexOf("iOS") != -1 ? (
                  <span className="bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-indigo-900 dark:text-indigo-300">
                    {`iOS`}
                  </span>
                ) : (
                  <span className="bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-indigo-900 dark:text-yellow-300">
                    {`Android`}
                  </span>
                )}
              </div>
            ) : (
              <div className="flex flex-row flex-wrap justify-center">
                <>
                  <ReportBadge>{report.params.platformFilter}</ReportBadge>
                </>
              </div>
            )}
          </td>

          <td className="px-5 py-3 border-b text-center">
            <span className="bg-pink-100 text-pink-800 text-md font-medium mr-2 px-3 py-1 rounded-full dark:bg-pink-900 dark:text-pink-300">
              {capitalizeFirst(t(report.reportType))}
            </span>
          </td>
          <td className="px-5 py-3 border-b text-center">
            {report.downloadUrl ? (
              <button
                className="font-bold"
                onClick={() => {
                  window.open(report.downloadUrl);
                }}
              >
                Download
              </button>
            ) : (
              "-"
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      {!reports.length && (
        <div className="w-full m-auto max-w-4xl text-center text-gray-700">
          <MessageText
            message={"there are no reports available for your account."}
            textAlignment="text-center"
            customClass=""
          />
        </div>
      )}
      {reports.length > 0 && (
        <div className="overflow-x-scroll lg:overflow-x-visible">
          <div className="relative">
            <table className="min-w-full leading-normal bg-gray-100">
              {tableHeaders}
              {tableBody}
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default AsyncReportTable;
