import { useState } from 'react';

const useObject = initialValue => {
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    const targetName = e.target.name;
    const targetType = e.target.type;
    const targetValue = e.target.value;

    setValue(prevState => {
      const newState = { ...prevState };
      if (targetType === 'checkbox') {
        newState[targetName] = !value[targetName];
      } else {
        newState[targetName] = targetValue;
      }
      return newState;
    });
  };

  return { onChange, setValue, value };
};

export default useObject;
