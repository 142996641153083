import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { Link, withRouter } from "react-router-dom";

import APIManager from "../../managers/APIManager";
import HelperVideo from "../../helpers/video";
import ClipboardButton from "../common/ClipboardButton";
import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";
import Page from "../common/Page";
import Loading from "../common/Loading";

import { Translation } from "react-i18next";
import { capitalizeFirst } from "../../utils/manipulateText";

const VideoEmbed = (props) => {
  const [embedCode, setEmbedCode] = useState("");
  const [options, setOptions] = useState({
    autoplay: true,
    player: "",
    sticky: true,
  });
  const [player, setPlayer] = useState(null);
  const [players, setPlayers] = useState([]);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    APIManager.getVideo(props.video).then((res) => {
      if (res.data.status === "AVAILABLE") {
        updateEmbedCode();
      }
      setVideo(res.data);
    });
    APIManager.getPlayers().then((res) => {
      const optionsVar = options;
      const playersVar = res.data;
      players.forEach((player) => {
        if (player.isDefault) {
          optionsVar.player = player.id;
        }
      });
      setOptions(optionsVar);
      setPlayers(playersVar);

      // setState({ options, players }, updateEmbedCode());
    });
  }, []);

  // the callback function to setState
  useEffect(() => {
    updateEmbedCode();
  }, [options, players]);

  useEffect(() => {
    if (!video || player || video.status !== "AVAILABLE") {
      return;
    }
    const playerVar = new window.EmpowerPlayer("preview", {
      adTimeout: 1,
      language: "en",
      logo: { image: "" },
      playerName: "empower_console",
    });
    playerVar.setInfoLoadURL(
      `${process.env.REACT_APP_API_BASE}/videos/{VIDEO_ID}/playback`
    );
    playerVar.loadVideo(props.video);
    setPlayer(playerVar);
  }, [video, player]);

  const onChange = (e) => {
    const optionsVar = options;
    if (e.target.name === "player") {
      optionsVar[e.target.name] = e.target.value;
    } else {
      optionsVar[e.target.name] = !optionsVar[e.target.name];
    }
    setOptions(optionsVar);
    updateEmbedCode(optionsVar);
  };

  const updateEmbedCode = (optionsArg) => {
    optionsArg = Object.assign({}, optionsArg || options);
    let embedCodeTmp = `<script async src="https://cdn.empower.net/sdk/EmpowerSDK.min.js"></script>
<div class="empower-player" 
\tdata-empower-id="${props.video}"`;
    if (optionsArg.player && optionsArg.player.length) {
      embedCodeTmp += `\n\tdata-empower-player="${optionsArg.player}"`;
    }
    if (!optionsArg.autoplay || !optionsArg.sticky) {
      embedCodeTmp += '\n\tdata-empower-optionsArg="';
      Object.keys(optionsArg).map(
        (option) => optionsArg[option] && delete optionsArg[option]
      );
      embedCodeTmp += JSON.stringify(optionsArg);
      embedCodeTmp += '"';
    }
    embedCodeTmp += ">\n</div>";
    setEmbedCode(embedCodeTmp);
  };

  if (video === null) {
    return <Loading />;
  }

  return (
    <Page
      title={video.title}
      controls={
        <>
          <Button
            className="mr-1"
            RootComponent={Link}
            to={`/analytics/videos/${video.id}`}
            color="primary"
            icon="trending-up"
          >
            <Icon.BarChart2
              className="inline mr-1 text-empower-500"
              width="16"
              height="16"
            />
            Analytics
          </Button>
          <Button
            RootComponent={Link}
            to={`/videos/${video.id}`}
            color="primary"
            icon="edit-2"
          >
            <Icon.Edit2
              className="inline mr-1 text-empower-500"
              width="16"
              height="16"
            />
            Edit Video
          </Button>
        </>
      }
    >
      {HelperVideo.getVideoStatusAlert(video)}
      <div className="flex">
        <div className="flex-1 mr-5">
          <Card title="embed code">
            <Card.Body>
              <p className="pb-3">
                <Translation>
                  {(t) =>
                    capitalizeFirst(
                      t(
                        "place the following embed code on your website where you wish the player to be displayed."
                      )
                    )
                  }
                </Translation>
              </p>
              <p className="pb-3">
                You may alter the default behavior of the player using by
                toggling the options below. For more advanced usage, please
                refer to the documentation by clicking{" "}
                <a
                  href="https://developers.empower.net/"
                  className="text-blue-700"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
              <code className="code-block bg-gray-200 text-xs">
                {embedCode}
              </code>
              <Form.Select
                className="w-full"
                name="player"
                label="Player"
                onChange={onChange}
              >
                {players.map((player, index) => (
                  <option
                    key={index}
                    selected={player.isDefault}
                    value={player.id}
                  >
                    {player.name +
                      (player.isDefault ? " (Default Player)" : "")}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>options</Form.Label>
              <div className="flex">
                <div className="flex-1 mr-5">
                  <Form.FormCheckboxInput
                    name="autoplay"
                    label="autoplay"
                    onChange={onChange}
                    checked={options.autoplay}
                  />
                </div>
                <div className="flex-1 mr-5">
                  <Form.FormCheckboxInput
                    name="sticky"
                    label="sticky"
                    onChange={onChange}
                    checked={options.sticky}
                  />
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <ClipboardButton content={embedCode} />
            </Card.Footer>
          </Card>
        </div>
        <div className="flex-1">
          <Card>
            <div
              id="preview"
              className="empower-player"
              data-empower-id={video.id}
              style={{
                backgroundColor: "#000",
                height: 280,
                width: 498,
              }}
            />
          </Card>
        </div>
      </div>
    </Page>
  );
};

VideoEmbed.propTypes = {
  video: PropTypes.string.isRequired,
};

export default withRouter(VideoEmbed);
