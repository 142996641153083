import React, { useEffect, useReducer, useRef, useState } from "react";
import * as Icon from "react-feather";
import APIManager from "../../../managers/APIManager";
import TagsInput from "../../common/TagsInput";
import { capitalizeFirst, upperCase } from "../../../utils/manipulateText";
import { useTranslation, Translation } from "react-i18next";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { modalConstants } from "./constants/modalConstants";
import { countryNames, getCountryCodes } from "../../apps/countryNames";
import MultiselectBox from "../../common/MultiselectBox";
import LoadingBackDrop from "../../common/LoadingBackDrop";
import { actionTypes, reducer } from "./constants/actionTypes";
import { capitalize } from "lodash";

const ConfigModal = ({
  site,
  auth,
  isVisible,
  setIsVisible,
  onClose,
  integrationType,
  initialState,
  setRefreshData,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [siteArr, setSiteArr] = useState([]);
  const adPlacementsRef = useRef([...state.selectedPlacements]);
  const [selectedCountries, setSelectedCountries] = useState(
    state.selectedCountries
  );
  const [selectedAppNames, setSelectedAppNames] = useState(
    state.selectedAppNames
  );
  const [selectedEmails, setSelectedEmails] = useState(state.emails);
  const [adPlacements, setAdPlacements] = useState([]);
  const [initRender, setInitRender] = useState(true);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isReportNameEmpty, setIsReportNameEmpty] = useState(false);
  const { t } = useTranslation();
  var allUsersObj = {
    id: "",
    name: "All Users",
    app: site?.info?.user?.apps,
    email: "",
  };
  var allSitesObj = {
    id: "all-sites",
    name: "All Sites",
    fqdn: "All Sites",
    virgulId: "all-sites",
  };

  useEffect(() => {
    if (!isVisible) return;
    setAdPlacements([...state.selectedPlacements]);
    adPlacementsRef.current = [...state.selectedPlacements];
  }, [state]);

  useEffect(() => {
    if (!isVisible) return;
    dispatch({ type: actionTypes.REPLACE_ALL, payload: { ...initialState } });
    setSelectedEmails([...initialState.emails]);
    if (integrationType === 3 || integrationType === 4) {
      setSelectedCountries([...initialState.selectedCountries]);
      setSelectedAppNames([...initialState.selectedAppNames]);
      const tmpSelectedSite = [allUsersObj, ...site.info.user.appUsers].filter(
        (user) => user.id === state.selectedSite
      );

      dispatch({
        type: actionTypes.SET_SELECTED_USER_APPS,
        payload: tmpSelectedSite[0].app,
      });
      dispatch({
        type: actionTypes.SET_SITE_BREAKDOWNS,
        payload: Object.keys(modalConstants.appBreakdownsAll),
      });
      dispatch({
        type: actionTypes.SET_SITE_PLATFORMS,
        payload: Object.keys(modalConstants.appPlatformsAll),
      });
    } else {
      setAdPlacements([...state.selectedPlacements]);

      var tmpSite =
        state.selectedSite === "" ? "all-sites" : state.selectedSite;

      if (state.selectedSite === "") return;
      setLoading(true);
      APIManager.getSite(state.selectedSite).then((res) => {
        dispatch({
          type: actionTypes.SET_SELECTED_SITE_PLACEMENTS,
          payload: res.data.zones,
        });

        const siteBdAll = Object.keys(modalConstants.siteBreakdownsAll);
        const filteredBd = res.data.breakdowns?.filter(
          (bd) => siteBdAll.indexOf(bd) !== -1
        );
        filteredBd.push("site");
        dispatch({
          type: actionTypes.SET_SITE_BREAKDOWNS,
          payload: filteredBd,
        });

        const platformsAll = Object.keys(modalConstants.sitePlatformsAll);

        var filteredPlatforms = res.data.filters.filter(
          (platform) => platformsAll.indexOf(platform) !== -1
        );
        dispatch({
          type: actionTypes.SET_SITE_PLATFORMS,
          payload: ["all", ...filteredPlatforms],
        });

        setLoading(false);
      });
    }
    // }
  }, [initialState]);

  useEffect(() => {
    if (!isVisible) return;
    if (integrationType === 3 || integrationType === 4) {
      if (site?.info?.user?.appUsers?.length === 0) {
        setSiteArr([allUsersObj]);
      } else if (site?.info?.user?.appUsers?.length > 1) {
        setSiteArr([allUsersObj, ...site?.info?.user?.appUsers]);
      } else {
        setSiteArr(site.info.user.appUsers);
      }

      const tmpSelectedSite = [allUsersObj, ...site.info.user.appUsers].filter(
        (user) => user.id === state.selectedSite
      );

      setSelectedAppNames([]);
      setSelectedCountries([]);
      dispatch({
        type: actionTypes.SET_SELECTED_USER_APPS,
        payload: tmpSelectedSite[0].app,
      });
      dispatch({
        type: actionTypes.SET_SITE_BREAKDOWNS,
        payload: Object.keys(modalConstants.appBreakdownsAll),
      });
      dispatch({
        type: actionTypes.SET_SITE_PLATFORMS,
        payload: Object.keys(modalConstants.appPlatformsAll),
      });
    } else {
      if (!initRender) {
        adPlacementsRef.current = [];
        setAdPlacements([]);
        dispatch({
          type: actionTypes.SET_SELECTED_BREAKDOWNS,
          payload: ["daily"],
        });
        dispatch({
          type: actionTypes.SET_SELECTED_PLATFORMS,
          payload: ["all"],
        });
        dispatch({ type: actionTypes.SET_SELECTED_PLACEMENTS, payload: [] });
      }

      var tmpArr = [];

      if (site?.sites && site?.sites?.length === 0) {
        setLoading(true);
        APIManager.getSites().then((res) => {
          tmpArr = res.data;
          if (tmpArr.length > 1) {
            tmpArr = tmpArr.filter((s) => s.id !== "all-sites");
            setSiteArr([allSitesObj, ...tmpArr]);
          } else {
            setSiteArr(tmpArr);
          }
          setLoading(false);
        });
      } else {
        tmpArr = site.sites;
        if (tmpArr.length > 1) {
          tmpArr = tmpArr.filter((s) => s.id !== "all-sites");
          setSiteArr([allSitesObj, ...tmpArr]);
        } else {
          setSiteArr(tmpArr);
        }
      }

      var tmpSite =
        state.selectedSite === "" ? "all-sites" : state.selectedSite;
      setLoading(true);
      APIManager.getSite(tmpSite).then((res) => {
        dispatch({
          type: actionTypes.SET_SELECTED_SITE_PLACEMENTS,
          payload: res.data.zones,
        });

        const siteBdAll = Object.keys(modalConstants.siteBreakdownsAll);
        const filteredBd = res.data.breakdowns?.filter(
          (bd) => siteBdAll.indexOf(bd) !== -1
        );
        filteredBd.push("site");
        dispatch({
          type: actionTypes.SET_SITE_BREAKDOWNS,
          payload: filteredBd,
        });

        const platformsAll = Object.keys(modalConstants.sitePlatformsAll);

        var filteredPlatforms = res.data.filters.filter(
          (platform) => platformsAll.indexOf(platform) !== -1
        );
        dispatch({
          type: actionTypes.SET_SITE_PLATFORMS,
          payload: ["all", ...filteredPlatforms],
        });

        setLoading(false);
      });
    }
  }, [state.selectedSite]);

  if (!isVisible) return null;

  const handleAdd = () => {
    var reportParams = {
      userId: auth.user.virgulId,
      reportStatus: state.status,
      recipients: selectedEmails.join(";"),
      reportName: state.reportName,
      period: state.reportPeriod,
      periodDay:
        state.reportPeriod === "weekly"
          ? state.weekDay
          : state.reportPeriod === "monthly"
          ? state.monthDay
          : -1,
      periodHour: state.selectedHour,
      timezone: state.timezone,
      reportDateRange: state.dateRange,
      site: state.selectedSite,
    };

    var reportFilter = {};
    if (integrationType === 3 || integrationType === 4) {
      if (reportParams.site === "all-sites") reportParams.site = "";
      reportParams["reportType"] = "app";
      reportFilter["appNames"] =
        state.selectedBreakdowns.indexOf("appNames") !== -1 ? true : false;
      reportFilter["day"] =
        state.selectedBreakdowns.indexOf("day") !== -1 ? true : false;
      reportFilter["tag"] =
        state.selectedBreakdowns.indexOf("tag") !== -1 ? true : false;
      reportFilter["useCountry"] =
        state.selectedBreakdowns.indexOf("useCountry") !== -1 ? true : false;
      reportFilter["apps"] =
        selectedAppNames.length === state.selectedUserApps.length
          ? []
          : selectedAppNames;
      reportFilter["platforms"] =
        state.selectedPlatforms.length === state.sitePlatforms.length
          ? []
          : state.selectedPlatforms;
      var countriesArr =
        selectedCountries.length === Object.keys(countryNames).length
          ? []
          : selectedCountries;
      reportFilter["countries"] = getCountryCodes(countriesArr);
    } else {
      if (reportParams.site === "") reportParams.site = "all-sites";
      reportParams["reportType"] = "site";
      reportFilter["platform"] = state.selectedPlatforms[0];
      reportFilter["appNames"] =
        state.selectedBreakdowns.indexOf("appNames") !== -1;
      reportFilter["day"] = state.selectedBreakdowns.indexOf("daily") !== -1;
      reportFilter["month"] =
        state.selectedBreakdowns.indexOf("monthly") !== -1;
      reportFilter["site"] = state.selectedBreakdowns.indexOf("site") !== -1;
      reportFilter["tag"] = false;
      reportFilter["tagContent"] =
        state.selectedBreakdowns.indexOf("tag") !== -1;
      reportFilter["zone"] =
        state.selectedBreakdowns.indexOf("placement") !== -1;
      reportFilter["platformTag"] =
        state.selectedBreakdowns.indexOf("platform") !== -1;
      reportFilter["zoneIds"] = adPlacementsRef.current;
      reportParams["site"] = siteArr.find((s) => s.id === state.selectedSite)[
        "virgulId"
      ];
    }
    reportParams["reportFilter"] = reportFilter;

    if (!state.reportName || !selectedEmails.length) {
      if (!state.reportName) {
        setIsReportNameEmpty(true);
      }

      if (!selectedEmails.length) {
        setIsEmailEmpty(true);
      }
      return;
    }

    setLoading(true);
    if (state.operation === "create") {
      APIManager.createScheduledReport(reportParams).then((res) => {
        setLoading(false);
        setRefreshData((prev) => !prev);
        setIsVisible(false);
      });
    } else if (state.operation === "update") {
      APIManager.updateScheduledReport(state.id, reportParams).then((res) => {
        setLoading(false);
        setRefreshData((prev) => !prev);
        setIsVisible(false);
      });
    } else {
      APIManager.createScheduledReport(reportParams).then((res) => {
        setLoading(false);
        window.location = "/reports";
      });
    }
  };

  const onBreakdownChange = (e) => {
    e.preventDefault();
    var currDataSet = e.target.parentNode.dataset.breakdown;
    var parentDataSet = e.target.dataset.breakdown;
    var breakdownElement = null;
    if (currDataSet) breakdownElement = currDataSet;
    if (parentDataSet) breakdownElement = parentDataSet;

    if (breakdownElement === undefined) return;

    var bdList = [...state.selectedBreakdowns];
    if (bdList.indexOf(breakdownElement) === -1) {
      bdList.push(breakdownElement);
    } else {
      bdList.splice(bdList.indexOf(breakdownElement), 1);
    }
    dispatch({
      type: actionTypes.SET_SELECTED_BREAKDOWNS,
      payload: [...bdList],
    });
  };

  const onPlatformChange = (e) => {
    e.preventDefault();
    if (integrationType === 3 || integrationType === 4) {
      var platformElement = e.target.dataset.platform;
      var platformList = state.selectedPlatforms;
      if (platformList.indexOf(platformElement) === -1) {
        platformList.push(platformElement);
      } else {
        platformList.splice(platformList.indexOf(platformElement), 1);
      }

      dispatch({
        type: actionTypes.SET_SELECTED_PLATFORMS,
        payload: [...platformList],
      });
    } else {
      dispatch({
        type: actionTypes.SET_SELECTED_PLATFORMS,
        payload: [e.target.dataset.platform],
      });
    }
  };

  const onAdPlacementChange = (event) => {
    if (adPlacementsRef.current.length === event.length) {
      let hasDifference = true;
      adPlacementsRef.current.forEach((e) => {
        if (event.indexOf(e) === -1) {
          hasDifference = false;
        }
      });
      if (hasDifference) {
        return;
      }
    }
    dispatch({
      type: actionTypes.SET_SELECTED_PLACEMENTS,
      payload: [].concat(event),
    });

    adPlacementsRef.current = [].concat(event);
    setAdPlacements([].concat(event));
  };

  var platFormsSection;
  var breakdownsSection;
  platFormsSection = (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="reportFilters"
      >
        <Translation>{(t) => capitalizeFirst(t("platforms"))}</Translation>
      </label>

      <span className="md:inline-block bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
        {state.sitePlatforms.map((platform) => (
          <button
            className={`transition-all ease-out transition-fast text-xs font-bold px-2 py-2 rounded mr-2 cursor-pointer  ${
              state.selectedPlatforms.indexOf(platform) !== -1
                ? "bg-white shadow"
                : ""
            }`}
            onClick={(e) => onPlatformChange(e)}
            data-platform={platform}
          >
            {integrationType === 3 || integrationType === 4
              ? upperCase(t(modalConstants.appPlatformsAll?.[platform]))
              : upperCase(t(modalConstants.sitePlatformsAll?.[platform]))}
          </button>
        ))}
      </span>
    </>
  );

  breakdownsSection = (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="reportBD"
      >
        <Translation>{(t) => capitalizeFirst(t("breakdowns"))}</Translation>
      </label>
      <span
        className="md:inline-block bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="reportBD"
      >
        {state.siteBreakdowns.map((bd) => (
          <button
            className={`transition-all ease-out transition-fast text-xs font-bold px-2 py-2 rounded mr-2 cursor-pointer ${
              state.selectedBreakdowns.indexOf(bd) !== -1
                ? "bg-white shadow"
                : ""
            }`}
            onClick={(e) => onBreakdownChange(e)}
            data-breakdown={bd}
          >
            <label className="mr-1 float-left cursor-pointer">
              {upperCase(
                t(
                  integrationType === 3 || integrationType === 4
                    ? modalConstants.appBreakdownsAll[bd]
                    : modalConstants.siteBreakdownsAll[bd]
                )
              )}
            </label>
          </button>
        ))}
      </span>
    </>
  );

  return (
    <div
      id="wrapper"
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-80 overflow-y:auto"
    >
      <div className="bg-white max-w-sm sm:max-w-lg md:max-w-5xl rounded-md w-full max-h-screen overflow-auto">
        {loading && <LoadingBackDrop />}
        <>
          <div className="p-3 flex items-center justify-between border-b border-b-gray-300">
            <h3 className="font-semibold text-m">
              {capitalize(t(state.name))}
            </h3>
            <span className="cursor-pointer" onClick={onClose}>
              <Icon.X width={20} height={20} />
            </span>
          </div>
          <div className="mx-5 my-3">
            <div className="flex flex-wrap -mx-3">
              <div className="w-1/3 md:w-1/5 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="reportStatus"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("report status"))}
                  </Translation>
                </label>
                <select
                  className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="reportStatus"
                  onChange={(e) =>
                    dispatch({
                      type: actionTypes.SET_STATUS,
                      payload: e.target.value,
                    })
                  }
                  defaultValue={initialState.status}
                >
                  <option value={1}>{capitalizeFirst(t("active"))}</option>
                  <option value={0}>{capitalizeFirst(t("inactive"))}</option>
                </select>
              </div>
              <div className="w-2/3 md:w-3/5 px-2 mb-3">
                <label
                  className={` ${
                    !isReportNameEmpty ? "text-gray-700" : "text-red-400"
                  } block uppercase tracking-wide  text-xs font-bold mb-2`}
                  htmlFor="reportName"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("report name"))}
                  </Translation>
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    !isReportNameEmpty ? "border-gray-200" : "border-red-400"
                  } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="reportName"
                  type="text"
                  placeholder={capitalize(t("enter report name"))}
                  onClick={() => {
                    setIsReportNameEmpty(false);
                  }}
                  onChange={(e) =>
                    dispatch({
                      type: actionTypes.SET_REPORT_NAME,
                      payload: e.target.value,
                    })
                  }
                  defaultValue={initialState.reportName}
                />
              </div>

              <div className="w-full md:w-1/5 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="reportPeriod"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("report period"))}
                  </Translation>
                </label>
                <select
                  className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="reportPeriod"
                  onChange={(e) =>
                    dispatch({
                      type: actionTypes.SET_REPORT_PERIOD,
                      payload: e.target.value,
                    })
                  }
                  defaultValue={initialState.reportPeriod}
                >
                  <option value="daily">{capitalizeFirst(t("daily"))} </option>
                  <option value="weekly">{capitalizeFirst(t("weekly"))}</option>
                  <option value="monthly">
                    {capitalizeFirst(t("monthly"))}
                  </option>
                </select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/4 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="dateRange"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("date range"))}
                  </Translation>
                </label>
                <select
                  className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="dateRange"
                  onChange={(e) =>
                    dispatch({
                      type: actionTypes.SET_DATE_RANGE,
                      payload: e.target.value,
                    })
                  }
                  defaultValue={initialState.dateRange}
                >
                  <option value="daily">
                    {capitalizeFirst(t("yesterday"))}
                  </option>
                  <option value="thisWeek">
                    {capitalizeFirst(t("this week"))}
                  </option>
                  <option value="thisMonth">
                    {capitalizeFirst(t("this month"))}
                  </option>
                  <option value="thisYear">
                    {capitalizeFirst(t("this year"))}
                  </option>
                  <option value="lastWeek">
                    {capitalizeFirst(t("last week"))}
                  </option>
                  <option value="weekly">
                    {capitalizeFirst(t("last 7 days"))}
                  </option>
                  <option value="monthly">
                    {capitalizeFirst(t("last 30 days"))}
                  </option>
                  <option value="lastMonth">
                    {capitalizeFirst(t("last month"))}
                  </option>
                  <option value="last3M">
                    {capitalizeFirst(t("last 3 months"))}
                  </option>
                  <option value="last6M">
                    {capitalizeFirst(t("last 6 months"))}
                  </option>
                  <option value="lastYear">
                    {capitalizeFirst(t("last year"))}
                  </option>
                </select>
              </div>

              {state.reportPeriod !== "daily" && (
                <div className="w-1/2 md:w-1/4 px-2 mb-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="periodDay"
                  >
                    <Translation>
                      {(t) => capitalizeFirst(t("delivery day"))}
                    </Translation>
                  </label>
                  {state.reportPeriod === "monthly" && (
                    <select
                      className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="periodDay"
                      onChange={(e) =>
                        dispatch({
                          type: actionTypes.SET_MONTH_DAY,
                          payload: e.target.value,
                        })
                      }
                      defaultValue={initialState.monthDay}
                    >
                      {modalConstants.dayOfTheMonth.map((day) => (
                        <option value={day}>{day}</option>
                      ))}
                    </select>
                  )}
                  {state.reportPeriod === "weekly" && (
                    <select
                      className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="periodDay"
                      onChange={(e) =>
                        dispatch({
                          type: actionTypes.SET_WEEK_DAY,
                          payload: e.target.value,
                        })
                      }
                      defaultValue={initialState.weekDay}
                    >
                      {Object.keys(modalConstants.daysOfTheWeek).map((day) => (
                        <option value={modalConstants.daysOfTheWeek[day]}>
                          {day}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
              <div className="w-1/2 md:w-1/4 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="periodHour"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("delivery time"))}
                  </Translation>
                </label>
                <select
                  className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="periodHour"
                  onChange={(e) => {
                    dispatch({
                      type: actionTypes.SET_SELECTED_HOUR,
                      payload: e.target.value,
                    });
                  }}
                  defaultValue={initialState.selectedHour}
                >
                  {Object.keys(modalConstants.hoursInDay).map((hour) => (
                    <option value={modalConstants.hoursInDay[hour]}>
                      {hour}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="timezone"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("timezone"))}
                  </Translation>
                </label>
                <select
                  className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="timezone"
                  onChange={(e) => {
                    dispatch({
                      type: actionTypes.SET_TIMEZONE,
                      payload: e.target.value,
                    });
                  }}
                  defaultValue={initialState.timezone}
                >
                  {Object.keys(modalConstants.utcTimeZones).map((timeZone) => (
                    <option value={modalConstants.utcTimeZones[timeZone]}>
                      {timeZone}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full md:w-1/4 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="reportBD"
                >
                  {integrationType === 3 || integrationType === 4
                    ? "User"
                    : "Site"}
                </label>
                <select
                  className=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="timezone"
                  onChange={(e) => {
                    setInitRender(false);
                    dispatch({
                      type: actionTypes.SET_SELECTED_SITE,
                      payload: e.target.value,
                    });
                  }}
                  defaultValue={initialState.selectedSite}
                >
                  {integrationType === 3 || integrationType === 4
                    ? siteArr.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))
                    : siteArr.map((user) => (
                        <option value={user.id}>{user.fqdn}</option>
                      ))}
                </select>
              </div>
              <div className="w-full md:w-3/4 px-2 mb-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="reportBD"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("filters"))}
                  </Translation>
                </label>
                <div className="flex flex-row">
                  {(integrationType === 3 || integrationType === 4) && (
                    <>
                      <MultiSelectDropdown
                        title="app names"
                        data={state.selectedUserApps}
                        customWidth="max-w-min md:max-w-auto"
                        customHeight="25vh"
                        customClass="mr-3 w-1/2"
                        selectedParam={selectedAppNames}
                        setSelectedParam={setSelectedAppNames}
                        onConfigModal={true}
                      />
                      <MultiSelectDropdown
                        title="countries"
                        data={Object.keys(countryNames)}
                        customWidth="max-w-min md:max-w-auto"
                        customHeight="25vh"
                        customClass="w-1/2"
                        selectedParam={selectedCountries}
                        setSelectedParam={setSelectedCountries}
                        onConfigModal={true}
                      />
                    </>
                  )}
                  {integrationType !== 3 && integrationType !== 4 && (
                    <>
                      <MultiselectBox
                        title={capitalize(t("ad placement"))}
                        options={state.selectedSitePlacements.map((a) => {
                          return {
                            id: a.id,
                            name: a.name + " (" + a.id + ")",
                          };
                        })}
                        onChange={onAdPlacementChange}
                        onConfig={true}
                        maxHeight="250px"
                        maxWidth="400px"
                        customStyle="overflow-x-scroll"
                        buttonCustomStyle=""
                        selectedItems={adPlacements}
                        setSelectedItems={setAdPlacements}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className=" px-2 mb-3">{breakdownsSection}</div>
              <div className="px-2 mb-3">{platFormsSection}</div>
            </div>

            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-2 ">
                <label
                  className={`${
                    !isEmailEmpty ? "text-gray-700" : "text-red-400"
                  } block uppercase tracking-wide text-xs font-bold mb-2`}
                  htmlFor="grid-password"
                >
                  <Translation>
                    {(t) => capitalizeFirst(t("recipients"))}
                  </Translation>
                </label>

                <TagsInput
                  tags={selectedEmails}
                  setTags={setSelectedEmails}
                  isTagsEmpty={isEmailEmpty}
                  setIsTagsEmpty={setIsEmailEmpty}
                />
              </div>
            </div>
          </div>
          <div className="p-3 flex items-center float-right">
            <div>
              <button
                className="text-sm text-white bg-empower-500 rounded-md px-4 py-2 mx-2"
                onClick={handleAdd}
              >
                {state.operation === "update"
                  ? capitalize(t("update"))
                  : capitalize(t("create"))}
              </button>
              <button
                className="text-sm text-white border rounded-md px-4 py-2 bg-gray-500"
                onClick={onClose}
              >
                <Translation>{(t) => capitalizeFirst(t("cancel"))}</Translation>
              </button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    site: state.site,
  };
};

export default connect(mapStateToProps)(withRouter(ConfigModal));
