import React, { useEffect, useState, useRef } from "react";
import APIManager from "../../managers/APIManager";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { upperCase } from "../../utils/manipulateText";
import Button from "./Button";
import { AppActionCreators } from "../../store/actions/app";
import { useDispatch } from "react-redux";
import QuickFilterModal from "./QuickFilterModal";
import { capitalize } from "lodash";
import { modalConstants } from "../reports/ScheduledReports/constants/modalConstants";
import Loading from "./Loading";
import MessageText from "./MessageText";
import { NavLink, Redirect } from "react-router-dom/cjs/react-router-dom.min";

const QuickFilters = ({
  customStyle = "",
  sites,
  setRefreshReport,
  handleClick,
  handleRedirect,
}) => {
  const [showItems, setShowItems] = useState(false);
  const [items, setItems] = useState([]);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [refreshItems, setRefreshItems] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSiteNameFromId = (siteId) => {
    var siteName = "All-Sites";
    for (var site of sites) {
      if (site.id === siteId || site.virgulId === siteId) {
        siteName = site.fqdn;
      }
    }
    return siteName;
  };

  const getDateRangeFromKey = (key) => {
    var dateRange = "Y";
    for (var dr of modalConstants.initialQuickDateButtons) {
      if (dr.range === key) {
        dateRange = dr.text;
      }
    }
    return dateRange;
  };

  useEffect(() => {
    setLoading(true);
    APIManager.getFilters().then((res) => {
      setItems(res?.data || []);
      setLoading(false);
    });
  }, [refreshItems]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowItems(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleItems = () => {
    setShowItems(!showItems);
  };

  const handleDelete = (filterId) => {
    APIManager.deleteFilter(filterId).then(() => {
      setRefreshItems((prev) => !prev);
    });
  };

  const updateAndOpenModal = (item) => (dispatch, getState) => {
    dispatch(
      AppActionCreators.setSelectedQuickFilter({
        mode: 1,
        sites: null,
        site: item?.siteId,
        name: item?.name || "",
        dateRange: item?.dateRange || "",
        breakdowns: item?.breakdowns || ["daily"],
        platform: item?.platform || "all",
        placements: item?.placements || [],
        id: item?.id,
      })
    );

    dispatch(AppActionCreators.setQuickFilterModal(true));
  };

  return (
    <>
      <QuickFilterModal sites={sites} setRefreshItems={setRefreshItems} />
      <div ref={wrapperRef} className={`relative min-w-64 z-70 ${customStyle}`}>
        <div
          onClick={toggleItems}
          className="flex flex-row items-center cursor-pointer p-1 rounded-md px-2"
        >
          <Icon.Filter className="h-5 w-5 text-gray-600 my-auto mx-1" />
          <span className="bg-transparent rounded-md p-1 font-semibold text-xs">
            {upperCase(t("quick filters"))}
          </span>
          <Icon.ChevronDown
            className={`transform ${
              showItems ? "rotate-180" : ""
            } transition-transform`}
            width="10"
            height="10"
          />
        </div>
        {showItems && (
          <div
            className="absolute right-0 mt-1 bg-gray-100 shadow-lg rounded-lg z-10 w-auto "
            style={{ minWidth: "150%" }}
          >
            <div
              className="p-2 border border-gray-300 rounded-md divide-y overflow-scroll"
              style={{ maxHeight: "400px" }}
            >
              {loading && <Loading />}
              {items.length < 1 && (
                <MessageText
                  message={"there are no filters available for your account."}
                  textAlignment="text-center"
                  customClass=""
                />
              )}
              {items.length > 0 &&
                items.map((item, index) => (
                  <div
                    key={index}
                    className="px-2 py-2 hover:bg-gray-200 rounded-md"
                  >
                    <div className="flex flex-row justify-between whitespace-no-wrap divide-x mx-1">
                      <NavLink
                        className="mx-1 px-1 w-full cursor-pointer hover:text-indigo-500 mr-2"
                        onClick={() => {
                          handleClick(item);
                        }}
                        to={() => handleRedirect(item.siteId || "all-sites")}
                      >
                        <div className="font-semibold text-base">
                          {item.name === "" || item.name === null
                            ? `<Filter ${index + 1}>`
                            : item.name}
                          <span className="font-normal text-xs">{` \u2022 ${
                            getSiteNameFromId(item.siteId) || "All-Sites"
                          }`}</span>
                        </div>
                        <span className="font-normal text-xs">
                          {`${item.breakdowns
                            .map((i) =>
                              capitalize(t(modalConstants.siteBreakdownsAll[i]))
                            )
                            .join(", ")} \u2022 ${capitalize(
                            t(item.platform)
                          )} \u2022 ${capitalize(
                            t(getDateRangeFromKey(item.dateRange))
                          )}`}
                        </span>
                      </NavLink>
                      <div className="flex flex-row">
                        <div
                          className="px-1 cursor-pointer flex justify-center items-center"
                          onClick={() => {
                            setShowItems(false);
                            dispatch(updateAndOpenModal(item));
                          }}
                        >
                          <Icon.Settings className="h-5 w-5 text-gray-600 my-auto mx-1 hover:text-indigo-500" />
                        </div>
                        <div
                          className="px-1 cursor-pointer flex justify-center items-center"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Icon.Trash2 className="h-5 w-5 text-gray-600 my-auto mx-1 hover:text-red-500" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex flex-row justify-center m-2 items-stretch">
              <Button
                color="primary"
                className="m-1 flex-auto"
                onClick={() => {
                  setShowItems(false);
                  dispatch(
                    AppActionCreators.setSelectedQuickFilter({
                      mode: 0,
                      sites: null,
                    })
                  );
                  dispatch(AppActionCreators.setQuickFilterModal(true));
                }}
              >
                {upperCase(t("create new filter"))}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuickFilters;
