import React, { useEffect, useRef, useState } from "react";
import MultiSelectDropdown from "../common/MultiSelectDropdown";
import Tooltip from "../common/Tooltip";
import DatePicker from "../common/DatePicker";
import { capitalize } from "../../utils/manipulateText";
import { useTranslation } from "react-i18next";
import { upperCase } from "../../utils/manipulateText";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "../common/Button";

const Control = ({
  email,
  apps,
  countries,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedBreakdowns,
  setSelectedBreakdowns,
  selectedAppNames,
  setSelectedAppNames,
  selectedCountries,
  setSelectedCountries,
  selectedPlatforms,
  setSelectedPlatforms,
  setRefreshData,
  setShowConfig,
  initState,
  setInitState,
  selectedUser,
  loading,
}) => {
  const { t } = useTranslation();

  const onBreakdownChange = (e) => {
    e.preventDefault();
    var currDataSet = e.target.parentNode.dataset.breakdown;
    var parentDataSet = e.target.dataset.breakdown;
    var breakdownElement = null;
    if (currDataSet) breakdownElement = currDataSet;
    if (parentDataSet) breakdownElement = parentDataSet;

    if (breakdownElement === undefined) return;

    var bdList = selectedBreakdowns;
    if (bdList.indexOf(breakdownElement) === -1) {
      bdList.push(breakdownElement);
    } else {
      bdList.splice(bdList.indexOf(breakdownElement), 1);
    }

    setSelectedBreakdowns([...bdList]);
  };

  const onDateChange = (e) => {
    setEndDate(e.endDate);
    setStartDate(e.startDate);
  };

  const onPlatformChange = (e) => {
    e.preventDefault();
    var platformElement = e.target.dataset.platform;
    var platformList = selectedPlatforms;
    if (platformList.indexOf(platformElement) === -1) {
      platformList.push(platformElement);
    } else {
      platformList.splice(platformList.indexOf(platformElement), 1);
    }

    setSelectedPlatforms([...platformList]);
  };

  const breakdownSection = (
    <>
      <div className="pt-2">
        <label className="leading-8 text-gray-600 font-semibold text-sm ml-1">
          {capitalize(t("breakdown"))}
        </label>
        <br />
        <Tooltip
          customStyle="w-full"
          title="breakdown"
          content={"you can select multiple choices here."}
        >
          <div className="flex w-full items-center">
            <span className="inline-flex p-1 border bg-gray-200 rounded-md mx-1 items-center justify-center">
              {
                <button
                  className={`transition-all ease-out transition-fast cursor-pointer text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                    selectedBreakdowns.indexOf("tag") !== -1
                      ? "bg-white shadow"
                      : ""
                  }`}
                  onClick={(e) => onBreakdownChange(e)}
                  data-breakdown="tag"
                >
                  <label className="cursor-pointer mr-1 float-left">
                    {upperCase(t("ad unit"))}
                  </label>
                </button>
              }
              {
                <button
                  className={`cursor-pointer transition-all ease-out transition-fast cursor-pointer text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                    selectedBreakdowns.indexOf("day") !== -1
                      ? "bg-white shadow"
                      : ""
                  }`}
                  onClick={(e) => onBreakdownChange(e)}
                  data-breakdown="day"
                >
                  <label className="cursor-pointer mr-1 float-left">
                    {upperCase(t("date"))}
                  </label>
                </button>
              }

              {
                <button
                  className={`transition-all ease-out transition-fast cursor-pointer text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                    selectedBreakdowns.indexOf("appNames") !== -1
                      ? "bg-white shadow"
                      : ""
                  }`}
                  onClick={(e) => onBreakdownChange(e)}
                  data-breakdown="appNames"
                >
                  <label className="cursor-pointer mr-1 float-left">
                    {upperCase(t("app names"))}
                  </label>
                </button>
              }

              {
                <button
                  className={`transition-all ease-out transition-fast cursor-pointer text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                    selectedBreakdowns.indexOf("useCountry") !== -1
                      ? "bg-white shadow"
                      : ""
                  }`}
                  onClick={(e) => onBreakdownChange(e)}
                  data-breakdown="useCountry"
                >
                  <label className="cursor-pointer mr-1 float-left">
                    {upperCase(t("country"))}
                  </label>
                </button>
              }
            </span>
          </div>
        </Tooltip>
      </div>
    </>
  );

  const filterSection = (
    <>
      <div className="pt-2">
        <label className="leading-8 text-gray-600 font-semibold text-sm">
          {capitalize(t("filter"))}
        </label>
        <br />
        <div className="flex flex-wrap items-center">
          <Tooltip
            title="platform"
            content={"you can filter platform which ads are served."}
          >
            <div className="flex items-center justify-center mb-1 md:mb-0 ml-1">
              <span className="inline-flex p-1 border bg-gray-200 rounded-md mr-1 items-center justify-center">
                {
                  <button
                    className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mr-2 ${
                      selectedPlatforms.indexOf("Android") !== -1
                        ? "bg-white shadow"
                        : ""
                    }`}
                    onClick={(e) => onPlatformChange(e)}
                    data-platform="Android"
                  >
                    {upperCase("Android App")}
                  </button>
                }

                {
                  <button
                    className={`transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded ${
                      selectedPlatforms.indexOf("iOS") !== -1
                        ? "bg-white shadow"
                        : ""
                    }`}
                    onClick={(e) => onPlatformChange(e)}
                    data-platform="iOS"
                  >
                    {upperCase("iOS App")}
                  </button>
                }
              </span>
            </div>
          </Tooltip>
          <Tooltip title="app names" content={"you can filter app names."}>
            <div className="mb-1 md:mb-0 mx-1">
              <MultiSelectDropdown
                onConfigModal={false}
                title="apps"
                data={apps}
                customWidth="300px"
                customHeight="300px"
                selectedParam={selectedAppNames}
                setSelectedParam={setSelectedAppNames}
              />
            </div>
          </Tooltip>
          <Tooltip title="countries" content={"you can filter countries. "}>
            <div className="mb-1 md:mb-0 mx-1">
              <MultiSelectDropdown
                onConfigModal={false}
                title="countries"
                data={countries}
                customWidth="250px"
                customHeight="300px"
                selectedParam={selectedCountries}
                setSelectedParam={setSelectedCountries}
              />
            </div>
          </Tooltip>

          <Tooltip title="date range" content={"you can filter date range."}>
            <div className="mb-1 md:mb-0 mx-1">
              <DatePicker
                mode="range"
                startDate={startDate}
                endDate={endDate}
                onChange={onDateChange}
              />
            </div>
          </Tooltip>
          <div className="mx-1 mb-1 md:mb-0 flex items-center justify-center ">
            <Tooltip
              title="schedule report"
              content={"you can schedule report."}
              customStyle="w-fit"
            >
              <span className="inline-flex p-1 border bg-gray-200 rounded-md mr-2">
                {
                  <button
                    className={`flex items-center justify-center transition-all ease-out transition-fast font-bold px-2 py-1 rounded`}
                    onClick={() => {
                      setInitState({
                        ...initState,
                        operation: "create_app",
                        id: "",
                        selectedCountries: [...selectedCountries],
                        selectedBreakdowns: [...selectedBreakdowns],
                        selectedAppNames: [...selectedAppNames],
                        selectedPlatforms: [...selectedPlatforms],
                        selectedSite: selectedUser,
                        emails: email ? [email] : [],
                      });
                      setShowConfig(true);
                    }}
                  >
                    <Icon.Clock
                      className="text-gray-600"
                      width="15"
                      height="15"
                    />
                    <span className="2xl:hidden text-xs font-bold ml-2">
                      {upperCase(t("schedule"))}
                    </span>
                  </button>
                }
              </span>
            </Tooltip>
            <Tooltip
              title="refresh report"
              content={"you can refresh report."}
              customStyle="w-fit"
            >
              <Button
                loading={loading}
                color="empower"
                onClick={() => {
                  setRefreshData((prev) => !prev);
                }}
                icon={faSyncAlt}
              >
                <span className="2xl:hidden">
                  {upperCase(t("apply filters"))}
                </span>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="filters hidden md:flex md:justify-between flex-col md:flex-row mx-1">
      {breakdownSection}
      {filterSection}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.auth.user.email,
  };
};

export default connect(mapStateToProps)(withRouter(Control));
