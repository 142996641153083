import APIManager from '../../managers/APIManager';

export const AnalyticsActions = {
  GET_OVERALL_VIDEO_ANALYTICS: 'GET_OVERALL_VIDEO_ANALYTICS',
  GET_REALTIME_PLAYER_DATA: 'GET_REALTIME_PLAYER_DATA',
  GET_REALTIME_VIDEO_DATA: 'GET_REALTIME_VIDEO_DATA',
  GET_SINGLE_VIDEO_ANALYTICS: 'GET_SINGLE_VIDEO_ANALYTICS',
  INVALIDATE_ANALYTICS_DATA: 'INVALIDATE_ANALYTICS_DATA'
};

export const AnalyticsActionCreators = {
  getOverallVideoAnalytics: (startDate, endDate) => dispatch => APIManager.getOverallVideoAnalytics(startDate, endDate).then(res => dispatch({
    type: AnalyticsActions.GET_OVERALL_VIDEO_ANALYTICS,
    payload: {
      data: res.data,
      startDate,
      endDate
    }
  })),
  getRealtimePlayerData: () => dispatch => APIManager.getRealtimePlayerData().then(res => dispatch({
    type: AnalyticsActions.GET_REALTIME_PLAYER_DATA,
    payload: {
      data: res.data
    }
  })),
  getRealtimeVideoData: videoId => dispatch => APIManager.getRealtimeVideoData(videoId).then(res => dispatch({
    type: AnalyticsActions.GET_REALTIME_VIDEO_DATA,
    payload: {
      data: res.data,
      videoId
    }
  })),
  getSingleVideoAnalytics: (videoId, startDate, endDate) => dispatch => APIManager.getSingleVideoAnalytics(videoId, startDate, endDate).then(res => dispatch({
    type: AnalyticsActions.GET_SINGLE_VIDEO_ANALYTICS,
    payload: {
      data: res.data,
      videoId,
      startDate,
      endDate
    }
  }))
};
