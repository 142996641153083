import * as React from "react";
import * as Icon from "react-feather";

import Button from "../common/Button";
import Card from "../common/Card";
import Form from "../common/Form";

import logo from "./../../assets/images/empower-logo-box.png";

const Activate = (
  errors,
  values,
  errorMessage,
  isActivationSuccess,
  onBlur,
  onChange,
  onSubmit
) => (
  <div className="max-w-sm m-auto h-screen flex flex-col">
    <div className="my-auto">
      <img src={logo} width="150" className="mb-3 m-auto" alt="Empower Logo" />
      {errorMessage ? (
        <div
          className="bg-red-100 border-l-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow mb-3"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-red-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Activation Failed</p>
              <p className="text-sm">{errorMessage}</p>
            </div>
          </div>
        </div>
      ) : isActivationSuccess ? (
        <div
          className="bg-green-100 border-l-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow mb-3"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <Icon.Info className="h-6 w-6 text-green-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Activation Successful</p>
              <p className="text-sm">You will be redirected to login page.</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Card
        className="p-3"
        title={
          values.reset === "1" ? "Reset your password" : "Activate Your Account"
        }
      >
        <Card.Body>
          {values.reset !== "1" ? (
            <Form.FormTextInput
              name="name"
              label="Full Name"
              placeholder="Full name"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.name}
              error={errors && errors.name}
            />
          ) : (
            <></>
          )}
          <Form>
            <Form.FormTextInput
              name="password"
              type="password"
              label="New Password"
              required={true}
              placeholder="Password"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.password}
              error={errors && errors.password}
            />
            <Form.FormTextInput
              name="password2"
              type="password"
              label="New Password (again)"
              required={true}
              placeholder="Password"
              onChange={onChange}
              onBlur={onBlur}
              value={values && values.password2}
              error={errors && errors.password2}
            />
            <Button
              className="w-full"
              color="primary"
              onClick={onSubmit}
              type="submit"
            >
              {values.reset === "1" ? "Reset password" : "Set Password"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  </div>
);

export default Activate;
