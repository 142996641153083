import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Icon from 'react-feather';

import Button from './Button';

const ClipboardButton = ({ className, content }) => {
  const statuses = {
    ready: {
      color: 'primary',
      icon: 'copy',
      text: 'Copy to Clipboard'
    },
    success: {
      color: 'success',
      icon: 'check',
      text: 'Copied!'
    }
  };

  const [status, setStatus] = useState('ready');

  const handleClick = e => {
    e.preventDefault();
    setStatus('success');

    const onError = () =>
      window.alert(
        'Your browser does not support copying to clipboard automatically. Please select the code and copy manually.'
      );

    const onSuccess = () =>
      setTimeout(setStatus => {
        setStatus('ready');
      }, 5000, setStatus);

    navigator.clipboard.writeText(content).then(onSuccess, onError);
  };

  return (
    <Button className={className || ''} {...statuses[status]} onClick={handleClick}>
      <Icon.Clipboard className="inline mr-2 text-empower-500" width="16" height="16" />
      {statuses[status].text}
    </Button>
  );
};

ClipboardButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired
};

export default ClipboardButton;
