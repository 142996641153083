import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "../common/Tooltip";
import { useTranslation } from "react-i18next";
import { capitalize, upperCase } from "../../utils/manipulateText";
import DatePicker from "../common/DatePicker";
import MultiselectBox from "../common/MultiselectBox";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import SelectBox from "../common/PlatformSelectBox";
import PlatformSelectBox from "../common/PlatformSelectBox";
import Button from "../common/Button";

function Control({
  auth,
  filterClass,
  state,
  platformFilterRef,
  adPlacementsRef,
  site,
  setShowConfig,
  initState,
  setInitState,
  location,
  breakdowns,
  setBreakdowns,
  setRefreshReport,
}) {
  const [selectedPlacements, setSelectedPlacements] = useState([]);
  const { t } = useTranslation();

  const onBreakdownChange = (e) => {
    e.preventDefault();
    var currDataSet = e.target.parentNode.dataset.breakdown;
    var parentDataSet = e.target.dataset.breakdown;
    var breakdownElement = null;
    if (currDataSet) breakdownElement = currDataSet;
    if (parentDataSet) breakdownElement = parentDataSet;

    if (breakdownElement === undefined) return;

    var bdList = breakdowns;
    if (bdList.indexOf(breakdownElement) === -1) {
      bdList.push(breakdownElement);
    } else {
      bdList.splice(bdList.indexOf(breakdownElement), 1);
    }

    setBreakdowns([...bdList]);
  };

  const onPlatformChange = (platformDataTag) => {
    state.setCache(null);
    state.setPlatformFilter(platformDataTag);

    platformFilterRef.current = platformDataTag;
  };

  const onAdPlacementChange = (event) => {
    if (adPlacementsRef.current.length === event.length) {
      let hasDifference = true;
      adPlacementsRef.current.forEach((e) => {
        if (event.indexOf(e) === -1) {
          hasDifference = false;
        }
      });
      if (hasDifference) {
        return;
      }
    }

    state.setCache(null);
    state.setAdPlacements([].concat(event));
    adPlacementsRef.current = [].concat(event);
    setSelectedPlacements([].concat(event));
  };

  const onDateChange = (e) => {
    state.setCache(null);
    state.setEndDate(e.endDate);
    state.setStartDate(e.startDate);
  };

  return (
    <>
      <div className="filters hidden md:flex md:justify-between flex-col md:flex-row mx-1">
        <div className="pt-2">
          <label className="leading-8 text-gray-600 font-semibold text-sm ml-1">
            {capitalize(t("breakdown"))}
          </label>
          <br />
          <Tooltip
            title="breakdown"
            content={"you can select multiple choices here."}
          >
            <div className="flex ">
              <span className="inline-flex p-1 border bg-gray-200 rounded-md mx-1 items-center justify-center">
                {site?.breakdowns?.includes("daily") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                      breakdowns.indexOf("daily") !== -1
                        ? "bg-white shadow"
                        : ""
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="daily"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("dailyBreakdown"))}
                    </label>
                  </button>
                )}
                {site?.breakdowns?.includes("monthly") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                      breakdowns.indexOf("monthly") !== -1
                        ? "bg-white shadow"
                        : ""
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="monthly"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("monthlyBreakdown"))}
                    </label>
                  </button>
                )}
                {site?.breakdowns?.includes("placement") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                      breakdowns.indexOf("zone") !== -1 ? "bg-white shadow" : ""
                    } ${
                      breakdowns.indexOf("zone") === breakdowns.length - 1
                        ? ""
                        : "mx-1"
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="zone"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("ad placement"))}
                    </label>
                  </button>
                )}
                {site?.breakdowns?.includes("tag") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                      breakdowns.indexOf("tag") !== -1 ? "bg-white shadow" : ""
                    } ${
                      breakdowns.indexOf("tag") === breakdowns.length - 1
                        ? ""
                        : "mx-1"
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="tag"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("ad unit"))}
                    </label>
                  </button>
                )}
                {site?.breakdowns?.includes("platform") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                      breakdowns.indexOf("platform") !== -1
                        ? "bg-white shadow"
                        : ""
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="platform"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("platform"))}
                    </label>
                  </button>
                )}
                {
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded mx-1 my-1 md:my-0 ${
                      breakdowns.indexOf("site") !== -1 ? "bg-white shadow" : ""
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="site"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("site"))}
                    </label>
                  </button>
                }
                {site?.dimensions?.includes("appNames") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded my-1 md:my-0 ${
                      breakdowns.indexOf("app") !== -1 ? "bg-white shadow" : ""
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="app"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("appNames"))}
                    </label>
                  </button>
                )}
                {site?.breakdowns?.includes("statType") && (
                  <button
                    className={`cursor-pointer transition-all ease-out transition-fast text-xs font-bold px-3 py-1 rounded my-1 mx-1 md:my-0 ${
                      breakdowns.indexOf("statType") !== -1
                        ? "bg-white shadow"
                        : ""
                    }`}
                    onClick={onBreakdownChange}
                    data-breakdown="statType"
                  >
                    <label className="cursor-pointer mr-1 float-left">
                      {upperCase(t("stat type"))}
                    </label>
                  </button>
                )}
              </span>
            </div>
          </Tooltip>
        </div>
        <div className="pt-2">
          <label className="leading-8 text-gray-600 font-semibold text-sm ml-1">
            {capitalize(t("filter"))}
          </label>
          <br />
          <div className="flex flex-wrap items-center">
            <Tooltip
              title="platform"
              content={"you can filter platform which ads are served."}
            >
              <div className="mx-1 mb-1 md:mb-0 flex items-center justify-center ">
                <PlatformSelectBox
                  title="platform"
                  onPlatformChange={onPlatformChange}
                  platformFilter={state.platformFilter}
                  filters={state?.site?.filters}
                />
              </div>
            </Tooltip>

            {site?.filters?.includes("placement") && (
              <Tooltip
                title="ad placement"
                content={
                  "you can filter ad placements which ads are located in. you can see placements after you click here."
                }
              >
                <div className="mb-1 md:mb-0 mx-1">
                  <MultiselectBox
                    ref={state.adPlacement}
                    title="AD PLACEMENT"
                    options={
                      state.site === null
                        ? []
                        : site.zones.map((a) => {
                            return {
                              id: a.id,
                              name: a.name + " (" + a.id + ")",
                            };
                          })
                    }
                    onChange={onAdPlacementChange}
                    onConfig={false}
                    selectedItems={state.adPlacements}
                    setSelectedItems={state.setAdPlacements}
                    maxWidth={"30%"}
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip title="date range" content={"you can filter date range."}>
              <div className="mb-1 md:mb-0 mx-1">
                <DatePicker
                  mode="range"
                  startDate={state.startDate}
                  endDate={state.endDate}
                  onChange={onDateChange}
                />
              </div>
            </Tooltip>

            <div className="mx-1 mb-1 md:mb-0 flex items-center justify-center ">
              <Tooltip
                title="schedule report"
                content={"you can schedule report."}
                customStyle="w-fit"
              >
                <span className="inline-flex p-1 border bg-gray-200 rounded-md mr-2">
                  {
                    <button
                      className={`flex items-center justify-center transition-all ease-out transition-fast font-bold px-2 py-1 rounded`}
                      onClick={() => {
                        var replacedbd = [...breakdowns];
                        if (replacedbd.indexOf("zone") !== -1) {
                          replacedbd.splice(
                            replacedbd.indexOf("zone"),
                            1,
                            "placement"
                          );
                        }

                        var replacedpl = [platformFilterRef.current];
                        if (replacedpl.indexOf("Desktop") !== -1) {
                          replacedpl.splice(
                            replacedpl.indexOf("Desktop"),
                            1,
                            "desktop"
                          );
                        }
                        if (replacedpl.indexOf("Mobile") !== -1) {
                          replacedpl.splice(
                            replacedpl.indexOf("Mobile"),
                            1,
                            "mobile"
                          );
                        }
                        const selectedSiteId = location.pathname.split("/")[2];
                        setInitState({
                          ...initState,
                          selectedSite: selectedSiteId,
                          operation: "create_site",
                          selectedBreakdowns: [...replacedbd],
                          selectedPlatforms: [...replacedpl],
                          selectedPlacements: adPlacementsRef.current,
                          selectedUserBreakdowns: [...site?.breakdowns],
                          selectedUserPlatforms: [...site?.filters],
                          selectedSitePlacements: site?.zones,
                          emails: auth?.user?.email ? [auth.user.email] : [],
                        });
                        setShowConfig(true);
                      }}
                    >
                      <Icon.Clock
                        className="text-gray-600"
                        width="15"
                        height="15"
                      />
                      <span className="2xl:hidden text-xs font-bold ml-2">
                        {upperCase(t("schedule"))}
                      </span>
                    </button>
                  }
                </span>
              </Tooltip>
              <Tooltip
                title="refresh report"
                content={"you can refresh report."}
                customStyle="w-fit"
              >
                <Button
                  loading={state.loading}
                  color="empower"
                  onClick={() => {
                    setRefreshReport((prev) => !prev);
                  }}
                  icon={faSyncAlt}
                >
                  <span className="2xl:hidden">
                    {upperCase(t("apply filters"))}
                  </span>
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Control.propTypes = {
  filterClass: PropTypes.string,
  state: PropTypes.object,
  breakdownChange: PropTypes.func,
  platformChange: PropTypes.func,
  adPlacementChange: PropTypes.func,
  dateChange: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(Control));
