import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/manipulateText";

const Form = ({ method = "POST", onSubmit, children }) => (
  <form onSubmit={onSubmit} method={method}>
    {children}
  </form>
);

Form.propTypes = {
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
};

const FormCheckboxInput = (props) => {
  const {
    className = "",
    checked = false,
    name,
    label,
    onBlur,
    onChange,
    value,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <input
        type="checkbox"
        className={`bg-gray-200 focus:bg-white form-checkbox mr-2 ${className}`}
        checked={checked}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      <label className="text-gray-700 text-sm font-bold" htmlFor={name}>
        {capitalize(t(label))}
      </label>
    </div>
  );
};

FormCheckboxInput.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const FormTextInput = ({
  className = "",
  name,
  label,
  placeholder,
  readonly = false,
  onBlur,
  onChange,
  type,
  value,
}) => {
  const { t } = useTranslation();
  const input = (
    <input
      className={`bg-gray-200 focus:bg-white form-input block w-full ${className}`}
      id={name}
      name={name}
      placeholder={placeholder}
      readOnly={readonly}
      type={type}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    />
  );
  if (!label) {
    return input;
  }
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {capitalize(t(label))}
      </label>
      {input}
    </div>
  );
};

FormTextInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
};

const Label = ({ className = "", children }) => {
  const { t } = useTranslation();
  return (
    <label
      className={`block text-gray-700 text-sm font-bold mb-2 ${className}`}
    >
      {capitalize(t(children))}
    </label>
  );
};
Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

const Select = ({
  className = "",
  name,
  label,
  onBlur,
  onChange,
  value,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {capitalize(t(label))}
      </label>
      <select
        className={`bg-gray-200 focus:bg-white form-select ${className}`}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      >
        {children}
      </select>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  children: PropTypes.any,
};

const Switch = ({
  className = "",
  type = "checkbox",
  name,
  label,
  onBlur,
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <label
        className="bg-white block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {capitalize(t(label))}
      </label>
      <input
        className={className}
        type={type}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const Textarea = ({
  className = "",
  name,
  label,
  placeholder,
  onBlur,
  onChange,
  type,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <label
        className="bg-white block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {capitalize(t(label))}
      </label>
      <textarea
        className={`form-textarea block w-full ${className}`}
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        rows="3"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
};

Form.FormCheckboxInput = FormCheckboxInput;
Form.FormTextInput = FormTextInput;
Form.Label = Label;
Form.Select = Select;
Form.Switch = Switch;
Form.Textarea = Textarea;

export default Form;
