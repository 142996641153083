import React, { useEffect, useState } from "react";
import AppsRevenue from "./AppsRevenue";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Apps = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserApps, setSelectedUserApps] = useState([]);

  var allUsersObj = {
    id: "",
    name: "All Users",
    app: props.info.user.apps,
    email: "",
  };

  useEffect(() => {
    var otherUsers = [...props.info.user.appUsers];
    var userListArr = [allUsersObj, ...otherUsers];
    var searchedId = props.match.params.user || "";
    var filteredUser = userListArr.filter((user) => user.id === searchedId);
    if (filteredUser.length) {
      setSelectedUser(searchedId);
      setSelectedUserApps(filteredUser[0].app);
    } else {
      props.history.push("/apps");
    }
    setUsers([...userListArr]);
  }, []);

  return (
    <AppsRevenue
      users={users}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      selectedUserApps={selectedUserApps}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    info: state.site.info,
  };
};

export default connect(mapStateToProps)(withRouter(Apps));
