let hours = {};
for (let i = 0; i < 24; i++) {
  let hour = i < 10 ? `0${i}:00` : `${i}:00`;
  hours[hour] = i;
}

export const modalConstants = {
  options: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZone: "UTC",
  },

  siteBreakdownsAll: {
    daily: "date",
    monthly: "month",
    placement: "ad placement",
    tag: "ad unit",
    platform: "platform",
    appNames: "app names",
    site: "site",
    statType: "income type",
    zone: "ad placement",
  },

  siteBreakdownsReq: {
    appNames: "app names",
    daily: "date",
    monthly: "month",
    tagContent: "tag",
    zone: "placement",
    site: "site",
  },

  appBreakdownsAll: {
    tag: "ad unit",
    day: "date",
    appNames: "app names",
    useCountry: "country",
  },
  sitePlatformsAll: {
    all: "ALL",
    desktop: "DESKTOP",
    mobile: "MOBILE",
    android: "ANDROID",
    iOS: "iOS",
    amp: "AMP",
  },
  appPlatformsAll: { iOS: "iOS", Android: "ANDROID" },

  dayOfTheMonth: Array.from({ length: 31 }, (_, i) => i + 1),

  hoursInDay: hours,

  utcTimeZones: {
    "Etc/UTC": 0,
    "Etc/GMT+1": 1,
    "Etc/GMT+2": 2,
    "Etc/GMT+3": 3,
    "Etc/GMT+4": 4,
    "Etc/GMT+5": 5,
    "Etc/GMT+6": 6,
    "Etc/GMT+7": 7,
    "Etc/GMT+8": 8,
    "Etc/GMT+9": 9,
    "Etc/GMT+10": 10,
    "Etc/GMT+11": 11,
    "Etc/GMT+12": 12,
    "Etc/GMT-1": -1,
    "Etc/GMT-2": -2,
    "Etc/GMT-3": -3,
    "Etc/GMT-4": -4,
    "Etc/GMT-5": -5,
    "Etc/GMT-6": -6,
    "Etc/GMT-7": -7,
    "Etc/GMT-8": -8,
    "Etc/GMT-9": -9,
    "Etc/GMT-10": -10,
    "Etc/GMT-11": -11,
    "Etc/GMT-12": -12,
  },
  daysOfTheWeek: {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  },

  initialQuickDateButtons: [
    { range: "Y", text: "last day" },
    { range: "T", text: "today" },
    { range: "7D", text: "last 7 days" },
    { range: "30D", text: "last 30 days" },
    { range: "MTD", text: "this month" },
    { range: "LM", text: "last month" },
    { range: "L3M", text: "last 3 months" },
    { range: "L6M", text: "last 6 months" },
  ],

  platformData: [
    {
      filterText: "all",
      dataTag: "all",
      printText: "all",
    },
    {
      filterText: "desktop",
      dataTag: "Desktop",
      printText: "desktop",
    },
    {
      filterText: "mobile",
      dataTag: "Mobile",
      printText: "mobile",
    },
    {
      filterText: "android",
      dataTag: "Android",
      printText: "Android App",
    },
    {
      filterText: "ios",
      dataTag: "iOS",
      printText: "iOS App",
    },
    {
      filterText: "amp",
      dataTag: "AMP",
      printText: "amp",
    },
  ],
};
