import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../utils/manipulateText";
import * as Icon from "react-feather";

const MessageText = ({
  message,
  customClass = "",
  textColor = "text-orange-600",
  textSize = "text-md",
  textAlignment = "text-center",
  icon = "Info",
  iconSize = { width: 16, height: 16 },
}) => {
  const { t } = useTranslation();
  const IconComponent = Icon[icon];

  return (
    <div
      className={`w-full m-auto py-4 ${textColor} ${textSize} ${textAlignment} ${customClass}`}
    >
      <div style={{ display: "inline", textAlign: textAlignment }}>
        {IconComponent && (
          <IconComponent
            style={{
              verticalAlign: "middle",
              marginRight: "8px",
              display: "inline",
            }}
            {...iconSize}
          />
        )}
        <span style={{ verticalAlign: "middle", display: "inline" }}>
          {capitalizeFirst(t(message))}
        </span>
      </div>
    </div>
  );
};

export default MessageText;
