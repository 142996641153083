import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Analytics from "./components/analytics";
import Auth from "./components/auth";
import SwitchPage from "./components/auth/SwitchPage";
import Dashboard from "./components/dashboard";
import Players from "./components/players";
import Sites from "./components/sites";
import Videos from "./components/videos";
import Settings from "./components/settings";
import Payments from "./components/payments";
import Apps from "./components/apps";
import AppWrapper from "./AppWrapper";

import S from "./store";
import Reports from "./components/reports";

const App = () => {
  return (
    <Provider store={S.store}>
      <PersistGate loading={null} persistor={S.persistor}>
        <Router>
          <Switch>
            <Route path="/auth/authorize">
              <AppWrapper>
                <Auth />
              </AppWrapper>
            </Route>
            <Route path="/auth/changepassword">
              <AppWrapper>
                <Auth />
              </AppWrapper>
            </Route>
            <Route path="/auth/switch">
              <SwitchPage />
            </Route>
            <Route path="/auth/:action">
              <Auth />
            </Route>
            <AppWrapper>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/analytics" component={Analytics} />
              <Route exact path="/analytics/:category" component={Analytics} />
              <Route
                exact
                path="/analytics/:category/:id"
                component={Analytics}
              />
              <Route exact path="/apps" component={Apps} />
              <Route exact path="/apps/:user" component={Apps} />
              <Route exact path="/players" component={Players} />
              <Route exact path="/players/:player" component={Players} />
              <Route exact path="/sites" component={Sites} />
              <Route exact path="/sites/:site" component={Sites} />
              <Route exact path="/sites/:site/:subaction" component={Sites} />
              <Route exact path="/videos" component={Videos} />
              <Route exact path="/videos/:video" component={Videos} />
              <Route
                exact
                path="/videos/:video/:subaction"
                component={Videos}
              />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/settings/:setting" component={Settings} />
              <Route exact path="/payments" component={Payments} />
              <Route exact path="/payments/:subaction" component={Payments} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/reports/:subaction" component={Reports} />
            </AppWrapper>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
