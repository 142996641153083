import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AddPlayer from './AddPlayer';
import PlayerEdit from './PlayerEdit';
import PlayerList from './PlayerList';

const Players = (props) => {
    if (props.auth.user.integrationType === 1) {
      props.history.push('/analytics/videos');
      return null;
    }
    if (props.match.params.player === 'new') {
      return <AddPlayer />;
    } else if (props.match.params.player) {
      return <PlayerEdit player={props.match.params.player} />;
    }
    return <PlayerList />;
}

Players.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(withRouter(Players));
