import _ from "lodash";
import { upperCase } from "../utils/manipulateText";
var headerNameMap = {
  zone: "AD PLACEMENT",
  platform: "PLATFORM",
  month: "MONTH",
  day: "DATE",
  request: "AD REQ.",
  adRequest: "AD REQ.",
  match: "MATCHED REQ.",
  coverage: "COVERAGE",
  view: "IMPRESSIONS",
  impression: "IMPRESSIONS",
  click: "CLICKS",
  ctr: "CTR",
  viewability: "VIEWABILITY",
  spent: "REVENUE",
  requestEcpm: "REQ. ECPM",
  ecpm: "ECPM",
  appName: "APP NAME",
  appId: "APP ID",
  match: "MATCH",
  revenue: "REVENUE",
  adUnit: "AD UNIT",
  country: "COUNTRY",
  date: "DATE",
  site: "SITE",
};

const exporter = {
  exportExcel: (
    data,
    reportTitle,
    headerVisibility,
    currency,
    createBuffer = false
  ) => {
    // If data is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof data !== "object" ? JSON.parse(data) : data;

    var CSV = "sep=,\r\n\n";

    var cur = currency;

    // This condition will generate the Label/Header
    if (headerVisibility) {
      var row = "";

      // This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        // Now convert each value to string and comma-seprated
        if (index === "spent" || index === "requestEcpm" || index === "ecpm") {
          row += `${headerNameMap[index]}(${upperCase(cur)}),`;
        } else if (index === "platform" && arrData[0][index] === null) {
          continue;
        } else if (index === "type") {
        } else {
          row += headerNameMap[index] + ",";
        }
      }
      row = row.slice(0, -1);
      // append Label row with line break
      CSV += row + "\r\n";
    }

    _.forEach(data, (row, rowIndex) => {
      var rowStr = "";
      _.forEach(row, (col, colIndex) => {
        if (typeof col === "object") {
          if (
            colIndex === "spent" ||
            colIndex === "requestEcpm" ||
            colIndex === "ecpm"
          ) {
            if (col?.[cur] !== undefined) rowStr += `${col[cur].toFixed(2)},`;
            else rowStr += ` ,`;
          }
          if (colIndex === "viewability") {
            if (col?.calculated !== undefined)
              rowStr +=
                '"' +
                Number(parseFloat(col.calculated) * 100).toFixed(2) +
                '%",';
            else rowStr += " ,";
          }
          if (colIndex === "zone") {
            rowStr += '"' + col.name + " (" + col.id + ")" + '",';
          }
          if (colIndex === "site") {
            if (col?.name !== undefined) {
              rowStr += '"' + col?.name + '",';
            } else rowStr += " ,";
          }
        } else if (colIndex === "coverage") {
          rowStr += '"' + parseFloat(col).toFixed(2) + '%",';
        } else if (colIndex === "ctr") {
          rowStr += '"' + Number(parseFloat(col) * 100).toFixed(2) + '%",';
        } else if (colIndex === "type") {
        } else {
          rowStr += '"' + col + '",';
        }
      });
      rowStr.slice(0, row.length - 1);
      // add a line break after each row
      CSV += rowStr + "\r\n";
    });

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    if (createBuffer) return CSV;
    // Generate a file name
    var fileName = Date.now() + "_";
    // this will remove the blank-spaces from the title and replace it with an underscore
    fileName += reportTitle.replace(/ /g, "_");

    // Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + encodeURI(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    // this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    // Set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    // This part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};

export default exporter;
