import PropTypes from "prop-types";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { upperCase } from "../../utils/manipulateText";
import { useTranslation } from "react-i18next";

const Button = ({
  className = "",
  color = "primary",
  disabled = false,
  icon = null,
  loading = false,
  onClick,
  size = "sm",
  type = "submit",
  children,
  RootComponent,
  to = null,
}) => {
  const { t } = useTranslation();
  const colorMap = {
    primary: {
      color: "bg-white",
      hover: "hover:bg-empower-500 hover:shadow-lg hover:text-white",
      text: "text-xs text-empower-500",
      padding: "py-2 px-4",
    },
    inverse_primary: {
      color: "bg-empower-500",
      hover: "hover:bg-white hover:shadow-lg hover:text-empower-500",
      text: "text-xs text-white",
      padding: "py-2 px-4",
    },
    secondary: {
      color: "bg-gray-300",
      hover: "hover:bg-gray-200",
      text: "text-xs text-gray-800",
      padding: "py-2 px-4",
    },
    danger: {
      color: "bg-red-700",
      hover: "hover:bg-red-600",
      text: "text-xs text-white",
      padding: "py-2 px-4",
    },
    success: {
      color: "bg-green-700",
      hover: "hover:bg-green-600",
      text: "text-xs text-white",
      padding: "py-2 px-4",
    },
    login: {
      color: "bg-empower-500",
      hover: "hover:bg-empower-300",
      text: "text-sm text-white",
      padding: "py-3",
    },
    empower: {
      color:
        "bg-gradient-to-r from-empowerStart-500 via-empowerVia-500 to-empowerTo-500",
      hover:
        "hover:from-empowerStart-400 hover:via-empowerVia-400 hover:to-empowerTo-400 hover:brightness-105",
      text: "text-xs text-white",
      padding: "py-2 px-3",
    },
  };

  const btn = (
    <button
      className={`${colorMap[color].color} ${colorMap[color].hover} ${
        colorMap[color].text
      } ${
        colorMap[color].padding
      } flex justify-center items-center font-bold rounded-md shadow-md uppercase ${className} ${
        disabled || loading ? " opacity-50 cursor-not-allowed" : ""
      }`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {loading ? (
        <>
          <FontAwesomeIcon
            icon={faSpinner}
            className="text-white mr-2 animate-spin"
            style={{ width: "15px", height: "15px" }}
          />
          {upperCase(t("loading"))}
        </>
      ) : (
        <>
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className="text-white mr-2"
              style={{ width: "15px", height: "15px" }}
            />
          )}
          {children}
        </>
      )}
    </button>
  );
  if (RootComponent) {
    return <RootComponent to={to}>{btn}</RootComponent>;
  }
  return btn;
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
