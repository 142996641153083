import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as Icon from 'react-feather';
import { withRouter } from 'react-router-dom';

import Button from '../common/Button';
import Card from '../common/Card';
import Form from '../common/Form';
import Page from '../common/Page';
import Loading from '../common/Loading';
import Player from '../common/Player';

import APIManager from '../../managers/APIManager';
import HelperPlayer from '../../helpers/player';
import HelperUtils from '../../helpers/utils';
import { useObject } from '../../hooks';

import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/manipulateText';

const PlayerEdit = props => {
  const { t } = useTranslation();
  const appearance = useObject(HelperPlayer.getDefaultAppearanceParams());
  const behavior = useObject(HelperPlayer.getDefaultBehaviorParams());
  const player = useObject(null);

  useEffect(() => {
    APIManager.getPlayer(props.player).then(res => {
      if (res.data.status === 'DELETED') {
        return props.history.push('/players');
      }
      const config = JSON.parse(res.data.config) || {};
      const appearanceConfig = Object.assign(appearance.value, config.appearance);
      const behaviorConfig = Object.assign(behavior.value, config.behavior);
      player.setValue(res.data);
      appearance.setValue(appearanceConfig);
      behavior.setValue(behaviorConfig);
    });
    return () => {
      appearance.setValue(HelperPlayer.getDefaultAppearanceParams());
      behavior.setValue(HelperPlayer.getDefaultBehaviorParams());
      player.setValue(null);
    };
  }, [props.player]); // eslint-disable-line

  const deletePlayer = e => {
    e.preventDefault();
    HelperUtils.prompt('Are you sure you want to delete this player? This operation is irreversible.')
      .then(() => APIManager.deletePlayer(player.value.id))
      .then(() => props.history.push('/players'));
  };

  const makeDefaultPlayer = e => {
    e.preventDefault();
    HelperUtils.prompt('Are you sure you want to make this your default player?')
      .then(() => APIManager.updatePlayer({ id: player.value.id, isDefault: 1 }))
      .then(() => props.history.push('/players'));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const config = JSON.stringify({
      appearance: appearance.value,
      behavior: behavior.value
    });
    HelperUtils.prompt('Are you sure you want to save the changes?')
      .then(() => APIManager.updatePlayer({ ...player.value, config }))
      .then(() => props.history.push('/players'));
  };

  if (player.value === null) {
    return <Loading />;
  }

  return (
    <Page title={player.value.name}>
      <div className="flex">
        <div className="w-1/2 mr-5">
          <Card title="general settings">
            <Card.Body>
              <Form.FormTextInput
                className="bg-gray-200 font-mono"
                name="id"
                label="id"
                disabled={true}
                readonly={true}
                value={player.value.id}
              />
              <Form.FormTextInput
                name="name"
                label="name"
                onChange={player.onChange}
                value={player.value.name}
              />
              <Form.Select
                className="w-full"
                name="language"
                label="language"
                onChange={player.onChange}
                value={player.value.language}
              >
                <option value="en">English</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="ru">Russian</option>
                <option value="es">Spanish</option>
                <option value="tr">Turkish</option>
              </Form.Select>
            </Card.Body>
          </Card>
          <Card title='appearance'>
            <Card.Body>
              <div className="flex mb-2">
                <div className="w-1/2 mr-5">
                  <Form.FormCheckboxInput
                    name="showTitleBar"
                    label="show title bar"
                    onChange={appearance.onChange}
                    checked={appearance.value.showTitleBar}
                    value={appearance.value.showTitleBar}
                  />
                  <Form.FormCheckboxInput
                    name="showSeekBar"
                    label="show seek bar"
                    onChange={appearance.onChange}
                    checked={appearance.value.showSeekBar}
                    value={appearance.value.showSeekBar}
                  />
                  <Form.FormCheckboxInput
                    name="showTimeDisplay"
                    label="show time display"
                    onChange={appearance.onChange}
                    checked={appearance.value.showTimeDisplay}
                    value={appearance.value.showTimeDisplay}
                  />
                  <Form.FormCheckboxInput
                    name="showReplay"
                    label="show replay button"
                    onChange={appearance.onChange}
                    checked={appearance.value.showReplay}
                    value={appearance.value.showReplay}
                  />
                </div>
                <div className="w-1/2 mr-5">
                  <Form.FormCheckboxInput
                    name="showShareButton"
                    label="show share button"
                    onChange={appearance.onChange}
                    checked={appearance.value.showShareButton}
                    value={appearance.value.showShareButton}
                  />
                  <Form.FormCheckboxInput
                    name="showFullScreenButton"
                    label="show full screen button"
                    onChange={appearance.onChange}
                    checked={appearance.value.showFullScreenButton}
                    value={appearance.value.showFullScreenButton}
                  />
                  <Form.FormCheckboxInput
                    name="showLogo"
                    label="show logo"
                    onChange={appearance.onChange}
                    checked={appearance.value.showLogo}
                    value={appearance.value.showLogo}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-1/3 mr-5">
                  <Form.Label>background color</Form.Label>
                  <div className="flex">
                    <div className="w-100">
                      <input
                        type="color"
                        name="backgroundColor"
                        onChange={appearance.onChange}
                        style={{ height: '36px', width: '36px' }}
                        value={appearance.value.backgroundColor}
                      />
                    </div>
                    <div className="w-100">
                      <Form.FormTextInput
                        className="font-mono text-sm"
                        name="backgroundColor"
                        maxlength={7}
                        onChange={appearance.onChange}
                        value={appearance.value.backgroundColor}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/3 mr-5">
                  <Form.Label>foreground color</Form.Label>
                  <div className="flex">
                    <div className="w-100">
                      <input
                        type="color"
                        name="foregroundColor"
                        onChange={appearance.onChange}
                        style={{ height: '36px', width: '36px' }}
                        value={appearance.value.foregroundColor}
                      />
                    </div>
                    <div className="w-100">
                      <Form.FormTextInput
                        className="font-mono text-sm"
                        name="foregroundColor"
                        maxlength={7}
                        onChange={appearance.onChange}
                        value={appearance.value.foregroundColor}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <Form.Label>highlight color</Form.Label>
                  <div className="flex">
                    <div className="w-100">
                      <input
                        type="color"
                        name="highlightColor"
                        onChange={appearance.onChange}
                        style={{ height: '36px', width: '36px' }}
                        value={appearance.value.highlightColor}
                      />
                    </div>
                    <div className="w-100">
                      <Form.FormTextInput
                        className="font-mono text-sm"
                        name="highlightColor"
                        maxlength={7}
                        onChange={appearance.onChange}
                        value={appearance.value.highlightColor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card title='behavior'>
            <Card.Body>
              <div className="flex">
                <div className="w-1/2 mr-5">
                  <Form.FormCheckboxInput
                    name="autoplay"
                    label="autoplay"
                    onChange={behavior.onChange}
                    checked={behavior.value.autoplay}
                    value={behavior.value.autoplay}
                  />
                </div>
                <div className="w-1/2">
                  <Form.FormCheckboxInput
                    name="loop"
                    label="loop"
                    onChange={behavior.onChange}
                    checked={behavior.value.loop}
                    value={behavior.value.loop}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="w-1/2">
          <Card className="card-fixed">
            <Player
              id="aa44ac8b-2de4-4a2b-a2b6-eb0d644b95d5"
              optional={{
                autoPlay: behavior.value.autoplay,
                language: player.value.language,
                logo: appearance.value.showLogo ? true : {
                  image: ''
                },
                showFullscreen: appearance.value.showFullScreenButton,
                showReplay: appearance.value.showReplay,
                showSeekBar: appearance.value.showSeekBar,
                showTimeDisplay: appearance.value.showTimeDisplay,
                showTitleBar: appearance.value.showTitleBar,
                theme: {
                  background: appearance.value.backgroundColor.replace('#', ''),
                  foreground: appearance.value.foregroundColor.replace('#', ''),
                  highlight: appearance.value.highlightColor.replace('#', '')
                }
              }}
            />
            <Card.Body className="flex justify-evenly">
              <Button
                color="danger"
                onClick={deletePlayer}
              >
                <Icon.Trash2 className="inline mr-1" width="16" height="16" />
                {capitalize(t('delete'))}
              </Button>
              <Button
                className="mr-2"
                color="secondary"
                disabled={player.value.isDefault}
                onClick={makeDefaultPlayer}
              >
                <Icon.Star className="inline mr-1" width="16" height="16" />
                {capitalize(t('make default'))}
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit}
              >
                <Icon.Check className="inline mr-1 text-white" width="16" height="16" />
                {capitalize(t('save changes'))}
              </Button>

              {/* <div className="w-1/2">
                <Button
                  color="danger"
                  onClick={deletePlayer}
                >
                  <Icon.Trash2 className="inline mr-1" width="16" height="16" />
                  {capitalize(t('delete'))}
                </Button>
              </div>
              <div className="w-1/2 text-right">
                <Button
                  className="mr-2"
                  color="secondary"
                  disabled={player.value.isDefault}
                  onClick={makeDefaultPlayer}
                >
                  <Icon.Star className="inline mr-1" width="16" height="16" />
                  {capitalize(t('make default'))}
                </Button>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Icon.Check className="inline mr-1 text-white" width="16" height="16" />
                  {capitalize(t('save changes'))}
                </Button>
              </div> */}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Page>
  );
};

PlayerEdit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  player: PropTypes.string.isRequired
};

export default withRouter(PlayerEdit);
