import React, { useEffect, useRef } from "react";
import Card from "../common/Card";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HelperExcel from "../../helpers/exporter";
import * as Icon from "react-feather";
import { Translation } from "react-i18next";
import { upperCase } from "../../utils/manipulateText";
import Table from "../common/Table";
import _ from "lodash";
import HelperFormatter from "../../helpers/formatter";
import APIManager from "../../managers/APIManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
const AppsReportTable = ({
  auth,
  tableData,
  setTableData,
  tableDims,
  footerData,
  selectedUser,
  getUsername,
}) => {
  const sortOrder = useRef(null);
  const tableHeaders = [
    {
      content: "date",
      onClick: () => sortTable("date"),
    },
    {
      content: "app name",
      onClick: () => sortTable("appName"),
    },
    {
      content: "app id",
      onClick: () => sortTable("appId"),
    },
    {
      content: "ad unit",
      onClick: () => sortTable("adUnit"),
    },
    {
      content: "country",
      onClick: () => sortTable("country"),
    },
    {
      content: "ad request",
      onClick: () => sortTable("adRequest"),
    },
    {
      content: "impression",
      onClick: () => sortTable("impression"),
    },
    {
      content: "click",
      onClick: () => sortTable("click"),
    },
    {
      content: "ctr",
      onClick: () => sortTable("ctr"),
    },
    {
      content: "ecpm",
      onClick: () => sortTable("ecpm"),
    },
    {
      content: "revenue",
      onClick: () => sortTable("revenue"),
    },
  ];

  const sortTable = (field) => {
    sortOrder.current = sortOrder.current !== "asc" ? "asc" : "desc";
    const tblTmp = tableData;

    if (field === "date") {
      const sortedData = tblTmp
        .map((item) => {
          const [day, month, year] = item.date
            .split(".")
            .map((num) => parseInt(num, 10));

          const dateTmp = new Date(year, month - 1, day);
          return {
            ...item,
            date: dateTmp,
          };
        })
        .sort((a, b) => {
          if (sortOrder.current === "asc") {
            return a.date - b.date;
          } else {
            return b.date - a.date;
          }
        })
        .map((item) => {
          return {
            ...item,
            date: item.date.toLocaleDateString("en-GB").split("/").join("."),
          };
        });

      setTableData(sortedData);
    } else if (field === "appName") {
      setTableData(_.orderBy(tblTmp, "appName", sortOrder.current));
    } else if (field === "appId") {
      setTableData(_.orderBy(tblTmp, "appId", sortOrder.current));
    } else if (field === "adUnit") {
      setTableData(_.orderBy(tblTmp, "adUnit", sortOrder.current));
    } else if (field === "country") {
      setTableData(_.orderBy(tblTmp, "country", sortOrder.current));
    } else if (field === "adRequest") {
      setTableData(_.orderBy(tblTmp, "adRequest", sortOrder.current));
    } else if (field === "click") {
      setTableData(_.orderBy(tblTmp, "click", sortOrder.current));
    } else if (field === "impression") {
      setTableData(_.orderBy(tblTmp, "impression", sortOrder.current));
    } else if (field === "match") {
      setTableData(_.orderBy(tblTmp, "match", sortOrder.current));
    } else if (field === "revenue") {
      setTableData(_.orderBy(tblTmp, "revenue", sortOrder.current));
    } else if (field === "ecpm") {
      setTableData(_.orderBy(tblTmp, "ecpm", sortOrder.current));
    } else if (field === "ctr") {
      setTableData(_.orderBy(tblTmp, "ctr", sortOrder.current));
    } else {
      setTableData(_.orderBy(tblTmp, field, sortOrder.current));
    }
  };

  const getTableHeaders = () => {
    return tableHeaders.filter((header) => {
      if (header.content === "app name") {
        return tableDims.indexOf("appName") !== -1;
      } else if (header.content === "app id") {
        return tableDims.indexOf("appId") !== -1;
      } else if (header.content === "ad unit") {
        return tableDims.indexOf("adUnit") !== -1;
      } else if (header.content === "date") {
        return tableDims.indexOf("date") !== -1;
      } else if (header.content === "ad request") {
        return tableDims.indexOf("adRequest") !== -1;
      } else {
        if (tableDims.includes(header.content)) {
          return true;
        }
        return false;
      }
    });
  };

  const footerSection = (
    <Table.Row key={Math.random()} className="bg-gray-200">
      {tableDims.indexOf("date") !== -1 ? (
        <Table.Col className="border text-center uppercase">
          <strong>{footerData?.date}</strong>
        </Table.Col>
      ) : null}
      {tableDims.indexOf("appName") !== -1 ? (
        <Table.Col className="border text-center uppercase">
          <strong>{footerData?.appName}</strong>
        </Table.Col>
      ) : null}
      {tableDims.indexOf("appId") !== -1 ? (
        <Table.Col className="border text-center uppercase">
          <strong>{footerData?.appId}</strong>
        </Table.Col>
      ) : null}
      {tableDims.indexOf("adUnit") !== -1 ? (
        <Table.Col className="border text-center uppercase">
          <strong>{footerData?.adUnit}</strong>
        </Table.Col>
      ) : null}
      {tableDims.indexOf("country") !== -1 ? (
        <Table.Col className="border text-center uppercase">
          <strong>{footerData?.country}</strong>
        </Table.Col>
      ) : null}
      {tableDims.includes("adRequest") ? (
        <Table.Col className="border text-right">
          <strong>{HelperFormatter.number(footerData?.adRequest)}</strong>
        </Table.Col>
      ) : null}
      {tableDims.includes("impression") ? (
        <Table.Col className="border text-right">
          <strong>{HelperFormatter.number(footerData?.impression)}</strong>
        </Table.Col>
      ) : null}
      {tableDims.includes("click") ? (
        <Table.Col className="border text-right">
          <strong>{HelperFormatter.number(footerData?.click)}</strong>
        </Table.Col>
      ) : null}
      {tableDims.includes("ctr") ? (
        <Table.Col className="border text-right">
          <strong>{HelperFormatter.percentage(footerData?.ctr)}</strong>
        </Table.Col>
      ) : null}
      {tableDims.includes("ecpm") ? (
        <Table.Col className="border text-right">
          <strong>{HelperFormatter.number(footerData?.ecpm)}</strong>
        </Table.Col>
      ) : null}
      {tableDims.includes("revenue") ? (
        <Table.Col className="border text-right">
          <strong>
            {HelperFormatter.currency(auth.user.currency)(footerData?.revenue)}
          </strong>
        </Table.Col>
      ) : null}
    </Table.Row>
  );

  return (
    <>
      <Card title="report" className="mb-4">
        <div className="float-right flex ">
          <button
            style={{ marginTop: "-30px" }}
            onClick={() =>
              HelperExcel.exportExcel(
                [].concat(tableData),
                "DailyBreakdown",
                true,
                auth.user.currency
              )
            }
            className="mx-4 mt-4 pull-right text-sm py-1"
          >
            <Icon.Download
              className="pull-left mt-1 mr-1 text-gray-600"
              width="16"
              height="16"
            />
            <label
              style={{
                float: "right",
                color: "#718096",
                cursor: "pointer",
                marginTop: "1px",
              }}
            >
              /<Translation>{(t) => upperCase(t("download"))}</Translation>
            </label>
          </button>
          <button
            style={{ marginTop: "-30px" }}
            onClick={() => {
              var data = HelperExcel.exportExcel(
                [].concat(tableData),
                "DailyBreakdown",
                true,
                auth.user.currency,
                true
              );
              const fileName =
                getUsername(selectedUser) +
                " - " +
                new Date().toLocaleDateString();
              APIManager.exportToDrive(fileName, data).then((res) =>
                window.open(res.url, "_blank")
              );
            }}
            className="mx-4 mt-4 pull-right text-sm py-1"
          >
            <FontAwesomeIcon
              icon={faGoogleDrive}
              style={{
                width: "16px",
                height: "16px",
              }}
              className="pull-left mt-1 mr-1 text-gray-600"
            />
            <label
              style={{
                float: "right",
                color: "#718096",
                cursor: "pointer",
                marginTop: "1px",
              }}
            >
              /<Translation>{(t) => upperCase(t("open in drive"))}</Translation>
            </label>
          </button>
        </div>
        <div className="overflow-x-scroll">
          <Table headers={getTableHeaders()} footerSection={footerSection}>
            {tableData.map((row, index) => (
              <Table.Row
                key={Math.random()}
                className="even:bg-gray-100 hover:bg-gray-200"
              >
                {tableDims.indexOf("date") !== -1 ? (
                  <Table.Col className="border text-center">
                    <strong>{row.date}</strong>
                  </Table.Col>
                ) : null}
                {tableDims.indexOf("appName") !== -1 ? (
                  <Table.Col className="border text-center">
                    <strong>{row.appName}</strong>
                  </Table.Col>
                ) : null}
                {tableDims.indexOf("appId") !== -1 ? (
                  <Table.Col className="border text-center">
                    <strong>{row.appId}</strong>
                  </Table.Col>
                ) : null}
                {tableDims.indexOf("adUnit") !== -1 ? (
                  <Table.Col className="border text-center">
                    <strong>{row.adUnit}</strong>
                  </Table.Col>
                ) : null}
                {tableDims.indexOf("country") !== -1 ? (
                  <Table.Col className="border text-center">
                    <strong>{row.country}</strong>
                  </Table.Col>
                ) : null}
                {tableDims.includes("adRequest") ? (
                  <Table.Col className="border text-right">
                    {HelperFormatter.number(row.adRequest)}
                  </Table.Col>
                ) : null}
                {tableDims.includes("impression") ? (
                  <Table.Col className="border text-right">
                    {HelperFormatter.number(row.impression)}
                  </Table.Col>
                ) : null}
                {tableDims.includes("click") ? (
                  <Table.Col className="border text-right">
                    {HelperFormatter.number(row.click)}
                  </Table.Col>
                ) : null}
                {tableDims.includes("ctr") ? (
                  <Table.Col className="border text-right">
                    {HelperFormatter.percentage(row.ctr)}
                  </Table.Col>
                ) : null}
                {tableDims.includes("ecpm") ? (
                  <Table.Col className="border text-right">
                    {HelperFormatter.number(row.ecpm)}
                  </Table.Col>
                ) : null}
                {tableDims.includes("revenue") ? (
                  <Table.Col className="border text-right">
                    {HelperFormatter.currency(auth.user.currency)(row.revenue)}
                  </Table.Col>
                ) : null}
              </Table.Row>
            ))}
          </Table>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(AppsReportTable));
