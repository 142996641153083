import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import flatpickr from "flatpickr";

import "../../assets/css/flatpickr.css";

const DatePicker = (props) => {
  var fp = null;

  const initialQuickDateButtons = [
    { range: "LH", text: "Last Hour" },
    { range: "Y", text: "Yesterday" },
    { range: "T", text: "Today" },
    { range: "7D", text: "Last 7 Days" },
    { range: "30D", text: "Last 30 Days" },
    { range: "MTD", text: "This Month" },
    { range: "LM", text: "Last Month" },
    { range: "L3M", text: "Last 3 Months" },
    { range: "L6M", text: "Last 6 Months" },
  ];

  const [quickDateButtons, setQuickDateButtons] = useState(
    initialQuickDateButtons.filter(
      (quickDateButton) =>
        props.quickDateButtons.indexOf(quickDateButton.range) !== -1
    )
  );
  const [startDate, setStartDate] = useState(
    props.startDate || flatpickr.formatDate(new Date(), "01.m.Y")
  );
  const [endDate, setEndDate] = useState(
    props.endDate || flatpickr.formatDate(new Date(), "d.m.Y")
  );
  const [refresh, setRefresh] = useState(false);
  const datePickerElem = useRef();

  const buildConfig = () => {
    const config = {
      dateFormat: "d.m.Y",
      maxDate: "today",
      appendTo: datePickerElem.current,
      onChange: onChange,
      onReady: (dObj, dStr, fp) => {
        if (
          fp.calendarContainer.children.length &&
          fp.calendarContainer.children[1].firstChild &&
          fp.calendarContainer.children[1].firstChild.children.length < 3
        ) {
          const quickDates = document.createElement("div");
          quickDates.className = "flatpickr-quick-dates";
          quickDateButtons.forEach((quickDateButton) => {
            const button = document.createElement("button");
            button.dataset.range = quickDateButton.range;
            button.onclick = onSetDateRange;
            button.innerHTML = quickDateButton.text;
            quickDates.appendChild(button);
          });
          fp.calendarContainer.children[1].firstChild.appendChild(quickDates);
        }
      },
    };
    if (props.mode === "range") {
      config.mode = "range";
      config.defaultDate = [startDate, endDate];
    }
    return config;
  };

  useEffect(() => {
    fp = flatpickr(datePickerElem.current, buildConfig());
  }, [refresh]);

  const onChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      return;
    }

    const startDateVar = flatpickr.formatDate(selectedDates[0], "d.m.Y");
    const endDateVar = flatpickr.formatDate(selectedDates[1], "d.m.Y");

    setStartDate(startDateVar);
    setEndDate(endDateVar);
    setRefresh((prev) => !prev);
    const dates = {};
    dates.startDate = flatpickr.formatDate(selectedDates[0], "d.m.Y");
    dates.endDate = flatpickr.formatDate(selectedDates[1], "d.m.Y");
    props.onChange(dates);
  };

  const onSetDateRange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const range = e.target.dataset.range;
    if (typeof range === "undefined") {
      return;
    }
    const dates = {};
    switch (range) {
      case "LH":
        dates.startDate = moment().format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "T":
        dates.startDate = moment().format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "Y":
        dates.startDate = moment().subtract(1, "days").format("DD.MM.YYYY");
        dates.endDate = moment().subtract(1, "days").format("DD.MM.YYYY");
        break;
      case "MTD":
        dates.startDate = moment().startOf("month").format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "7D":
        dates.startDate = moment().subtract(7, "days").format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "30D":
        dates.startDate = moment().subtract(30, "day").format("DD.MM.YYYY");
        dates.endDate = moment().format("DD.MM.YYYY");
        break;
      case "LM":
        dates.startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("DD.MM.YYYY");
        dates.endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case "L3M":
        dates.startDate = moment()
          .subtract(3, "month")
          .startOf("month")
          .format("DD.MM.YYYY");
        dates.endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case "L6M":
        dates.startDate = moment()
          .subtract(6, "month")
          .startOf("month")
          .format("DD.MM.YYYY");
        dates.endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      default:
        return;
    }
    props.onChange(dates);
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    setRefresh((prev) => !prev);
  };

  window.setDateRange = onSetDateRange;
  return (
    <button
      className="flex items-center justify-center transition-all ease-out transition-fast font-bold p-2 border bg-gray-200 rounded-md my-auto"
      ref={datePickerElem}
    >
      <Icon.Calendar
        className="inline mx-1 text-gray-600"
        width="16"
        height="16"
      />

      <span className="text-xs font-bold ml-2">
        {moment(
          props.startDate.split(".").reverse().join("-") + " 03:00"
        ).format("MMM D, YYYY")}{" "}
        -{" "}
        {moment(props.endDate.split(".").reverse().join("-") + " 03:00").format(
          "MMM D, YYYY"
        )}
      </span>
    </button>
  );
};

DatePicker.defaultProps = {
  quickDateButtons: ["T", "Y", "7D", "30D", "MTD", "LM", "L3M", "L6M"],
};

DatePicker.propTypes = {
  endDate: PropTypes.string,
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  quickDateButtons: PropTypes.array,
  startDate: PropTypes.string,
};

export default DatePicker;
