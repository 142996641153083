import React, { useState, useEffect, useRef } from "react";
import APIManager from "../../../managers/APIManager";
import { modalConstants } from "./constants/modalConstants";
import { getCountryNames } from "../../apps/countryNames";
import { Translation, useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../utils/manipulateText";

const PopupMenu = ({
  report,
  integrationType,
  setRefreshData,
  setShowDeleteModal,
  setModalId,
  setModalReportName,
  setShowConfig,
  initialState,
  setInitialState,
}) => {
  const { t } = useTranslation();
  const [popupVisibility, setPopupVisibility] = useState(false);
  const popupRef = useRef(null);
  const handleEdit = (e) => {
    e.preventDefault();
    setModalId(report.id);
    const reportFilters = report.reportFilter;
    var tmpBD = [];
    var tmpPlatforms = [];
    if (integrationType === 3 || integrationType === 4) {
      Object.keys(reportFilters).forEach((key) => {
        if (reportFilters[key]) {
          if (modalConstants.appBreakdownsAll.hasOwnProperty(key))
            tmpBD.push(key);
          if (modalConstants.appPlatformsAll.hasOwnProperty(key))
            tmpPlatforms.push(key);
        }
      });
    } else {
      tmpBD = report.breakdowns;
      tmpPlatforms = report.platforms;
    }

    var appNamesArr = [];
    var countriesArr = [];
    var placementsArr = [];

    if (integrationType === 3 || integrationType === 4) {
      if (reportFilters.hasOwnProperty("apps")) {
        appNamesArr = reportFilters["apps"];
      }
      if (reportFilters.hasOwnProperty("countries")) {
        countriesArr = reportFilters["countries"];
      }
    } else {
      if (reportFilters.hasOwnProperty("zoneIds")) {
        placementsArr = reportFilters["zoneIds"];
      }
    }
    const newState = {
      ...initialState,
      name: "edit existing report",
      operation: "update",
      id: report.id,
      emails: report.recipients.split(";"),
      status: report.reportStatus,
      reportName: report.reportName,
      reportPeriod: report.period,
      dateRange: report.reportDateRange,
      monthDay: report.periodDay,
      weekDay: report.periodDay,
      selectedHour: report.periodHour,
      timezone: report.timezone,
      selectedBreakdowns: tmpBD,
      selectedPlatforms: tmpPlatforms,
      selectedAppNames: appNamesArr,
      selectedCountries: getCountryNames(countriesArr),
      selectedPlacements: placementsArr,
      selectedSite: report.site,
    };
    setInitialState({
      ...newState,
    });
    setShowConfig(true);
  };

  const handleDelete = (e) => {
    const bodyEl = document.getElementsByTagName("BODY")[0];
    bodyEl.style.overflow = "hidden";
    setModalId(report.id);
    setModalReportName(report.reportName);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.tagName !== "BUTTON"
    ) {
      setPopupVisibility(false);
    }
  };

  return (
    <div className="relative" ref={popupRef}>
      <button
        onClick={() => setPopupVisibility((prev) => !prev)}
        className="inline-block text-gray-500 hover:text-gray-700"
        style={{ float: "right" }}
      >
        <svg className="inline-block h-6 w-6 fill-current" viewBox="0 0 24 24">
          <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
        </svg>
      </button>

      <div
        className={`absolute ${
          popupVisibility ? "block absolute" : "hidden"
        } bg-white shadow-md rounded-md p-4 z-50`}
        style={{
          top: "0%",
          left: "50%",
          transform: "translate(-50%, 20px)",
        }}
      >
        <div>
          <p
            onClick={(e) => handleEdit(e)}
            className="block text-gray-600 font-semibold text-sm pb-2 hover:text-yellow-500 cursor-pointer"
          >
            <Translation>{(t) => capitalizeFirst(t("edit"))}</Translation>
          </p>
        </div>
        <div>
          <p
            onClick={(e) => handleDelete(e)}
            className="block text-gray-600 font-semibold text-sm pb-2 hover:text-red-500 cursor-pointer"
          >
            <Translation>{(t) => capitalizeFirst(t("delete"))}</Translation>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;
