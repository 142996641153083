import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import APIManager from '../../managers/APIManager';
import Card from '../common/Card';
import DatePicker from '../common/DatePicker';
import Page from '../common/Page';
import Chart from 'react-apexcharts';
import HelperFormatter from '../../helpers/formatter';
import Loading from '../common/Loading';
import StatsBox from '../common/StatsBox';
import TopVideosList from './fragments/TopVideosList';
import Table from '../common/Table';

import { Translation } from 'react-i18next';
import { capitalizeFirst } from '../../utils/manipulateText';

const VideoAnalytics = (props) => {

  const [onDateChangeCalled, setOnDateChangeCalled] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState({
    duration: 30000,
    interval: null
  })

  const [chart, setChart] = useState ({
    options: {
      chart: {
        id: 'views',
        group: 'video-analytics'
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#4299e1', '#f56565', '#48bb78'],
      fill: {
        colors: ['#4299e1', '#f56565', '#48bb78'],
        type: 'solid'
      },
      legend: {
        show: false
      },
      stroke: {
        colors: ['#4299e1', '#f56565', '#48bb78'],
        curve: 'smooth',
        width: 3,
        dashArray: [0, 3, 3]
      },
      tooltip: {
        x: {
          format: 'MMM dd, HH:mm'
        },
        y: {
          formatter: v => {
            return v?.toLocaleString();
          }
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          minWidth: 100,
          formatter: val => {
            if (val >= 1000000) {
              return ((val / 1000000).toFixed(1) + 'M').replace('.0', '');
            } else if (val >= 1000) {
              return ((val / 1000).toFixed(1) + 'K').replace('.0', '');
            }
            return val.toFixed(0);
          }
        },
        min: 0
      }
    },
    series: []
  });

  const [startDate, setStartDate] = useState(moment().subtract(24, 'hours').format('DD.MM.YYYY'));
  const [endDate, setEndDate] = useState(moment().subtract(24, 'hours').format('DD.MM.YYYY'));
  const [loading, setLoading] = useState(true);
  const [realtime, setRealtime] = useState({
    total: 0,
    series: []
  })
  const [stats, setStats] = useState(
    {
      topVideos: null,
      totals: {
        comparison: {
          infoLoad: 0,
          start: 0,
          complete: 0,
          error: 0,
          bufferEmpty: 0,
          bufferInit: 0,
          bufferSeek: 0,
          viewability: 0,
          recommendationClick: 0,
          recommendationImpression: 0,
          shareClick: 0,
          shareImpression: 0,
          posterClick: 0,
          pause: 0,
          resume: 0,
          replay: 0,
          mute: 0,
          unmute: 0,
          seek: 0,
          resizeFullscreen: 0,
          resizeNormal: 0,
          timeLoad: 0,
          timeBufferEmpty: 0,
          timeBufferInit: 0,
          timeBufferSeek: 0,
          timeStart: 0
        },
        period: {
          infoLoad: 0,
          start: 0,
          complete: 0,
          error: 0,
          bufferEmpty: 0,
          bufferInit: 0,
          bufferSeek: 0,
          viewability: 0,
          recommendationClick: 0,
          recommendationImpression: 0,
          shareClick: 0,
          shareImpression: 0,
          posterClick: 0,
          pause: 0,
          resume: 0,
          replay: 0,
          mute: 0,
          unmute: 0,
          seek: 0,
          resizeFullscreen: 0,
          resizeNormal: 0,
          timeLoad: 0,
          timeBufferEmpty: 0,
          timeBufferInit: 0,
          timeBufferSeek: 0,
          timeStart: 0
        }
      },
      watch: null
    }
  )

  const [video, setVideo] = useState(null);
  const [videoInit, setVideoInit] = useState(false);
  const [videos, setVideos] = useState([]);
  const [viewRate, setViewRate] = useState(0);
  
  useEffect(() => {
    if (props.video) {
      if(!videoInit)
      {
        setLoading(true)
        getVideo().then(() => {
          getRealtimeVideoData();
          enableAutoRefresh(that => that?.getRealtimeVideoData());
        });
      }
    } else {
      getVideos().then(() => {
        getOverallVideoAnalytics();
        getRealtimePlayerData();
        enableAutoRefresh(that => that?.getRealtimePlayerData());
      });
    }

    return () => disableAutoRefresh();
  },[video])

  const disableAutoRefresh = () => {
    const autoRefreshVar = autoRefresh;
    if (autoRefreshVar.interval) {
      clearInterval(autoRefreshVar.interval);
    }
    setAutoRefresh(autoRefreshVar);
  }

  const enableAutoRefresh = (callback) => {
    const autoRefreshVar = autoRefresh;
    if (autoRefreshVar.interval) {
      clearInterval(autoRefreshVar.interval);
    }
    autoRefreshVar.interval = setInterval(callback, autoRefresh.duration, this);
    setAutoRefresh(autoRefreshVar);
  }

  const callbackToSetState = () => {
    if (props.video ) {
      if(videoInit) getSingleVideoAnalytics();
    } else {
      getOverallVideoAnalytics();
    }
  }

  const onDateChange = (dates) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    setLoading(true);
    setOnDateChangeCalled(prev => !prev); 
  }

  useEffect(() => {
    callbackToSetState();     
    return () => disableAutoRefresh();
  }, [onDateChangeCalled, videoInit])

  const getVideo = () => {
    return APIManager.getVideo(props.video).then(res => {
      setStartDate(moment(res.data.createdAt).format('DD.MM.YYYY'));
      setVideoInit(true);
      if(video?.id !== res?.data?.id) setVideo(res.data);
      setViewRate(res.data.viewRate);
    });
  }

  const getVideos = () => {
    return APIManager.getVideos().then(res => {
      setVideos(res.data);
    });
  }

  const getOverallVideoAnalytics = () => {
    return APIManager.getOverallVideoAnalytics(
      moment(startDate, 'DD.MM.YYYY').unix(),
      moment(endDate, 'DD.MM.YYYY').unix()
    ).then(res => {
      if (!res.data) {
        return;
      }

      const chartVar = chart;
      chartVar.series = [];
      const dataPeriod = res.data.period.map(datum => ({
        x: moment(datum.ts).tz(props.auth.user.timezone).format(),
        y: datum.start
      }));
      chartVar.series.push({
        name: 'Views',
        data: dataPeriod
      });
      const dataComparison = res.data.comparison.map(datum => ({
        x: moment(datum.ts + (60 * 60 * 24 * 7 * 1000)).tz(props.auth.user.timezone).format(),
        y: datum.start
      }));
      chartVar.series.push({
        name: 'Last Week Views',
        data: dataComparison
      });

      const videosVar = {};
      videos.map(video => (videosVar[video.id] = video));

      const topVideos = Object.keys(res.data.videos)
        .filter(id => typeof videosVar[id] !== 'undefined')
        .map(id => {
          if (videosVar[id]) {
            videosVar[id].views = res.data.videos[id].start;
            videosVar[id].completionRate = res.data.videos[id].completionRate;
            return videosVar[id];
          }
          return null;
        })
        .sort((a, b) => (a.views > b.views ? 0 : 1));

      setChart(chartVar);
      setLoading(false);
      setStats({...stats, topVideos, totals: res.data.totals});
      setViewRate(res.data.totals.period.viewRate);
    });
  }

  const getSingleVideoAnalytics = () => {
    return APIManager.getSingleVideoAnalytics(
      props.video,
      moment(startDate, 'DD.MM.YYYY').unix(),
      moment(endDate, 'DD.MM.YYYY').unix()
    ).then(res => {
      if (!res.data || res.data.error) {
        return;
      }
      const chartVar = chart;
      chartVar.series = [];
      const dataPeriod = res.data.period.map(datum => ({
        x: moment(datum.ts).tz(props.auth.user.timezone).format(),
        y: datum.start
      }));
      chartVar.series.push({
        name: 'Views',
        data: dataPeriod
      });
      const dataComparison = res.data.comparison.map(datum => ({
        x: moment(datum.ts + (60 * 60 * 24 * 7 * 1000)).tz(props.auth.user.timezone).format(),
        y: datum.start
      }));
      chartVar.series.push({
        name: 'Last Week Views',
        data: dataComparison
      });

      setChart(chartVar);
      setLoading(false);
      setStats({...stats, totals:res.data.totals});
    });
  }

  const getRealtimePlayerData = () => {
    return APIManager.getRealtimePlayerData().then(res => {
      if (!res.data || res.data.error) {
        return;
      }

      const chartData = [];
      const dataPeriod = res.data.period.map(datum => ({
        x: moment(datum.ts).tz(props.auth.user.timezone).format(),
        y: datum.start
      }));
      chartData.push({
        name: 'Views',
        data: dataPeriod
      });

      setRealtime({...realtime, series: chartData, total: res.data.totals.period.start})

    });
  }

  const getRealtimeVideoData = () => {
    return APIManager.getRealtimeVideoData(
      props.video
    ).then(res => {
      if (!res.data || res.data.error) {
        return;
      }

      const chartData = [];
      const dataPeriod = res.data.period.map(datum => ({
        x: moment(datum.ts).tz(props.auth.user.timezone).format(),
        y: datum.start
      }));
      chartData.push({
        name: 'Views',
        data: dataPeriod
      });
      setRealtime({...realtime, series: chartData, total: res.data.totals.period.start});

    });
  }
    if (loading || (props.video && !videoInit)) {
      return <Loading />;
    }

    if (!stats.totals.period || !stats.totals.period.start) {
      return (
        <Page
          title={video ? video.title : 'analytics'}
          controls={
            <DatePicker
              mode="range"
              startDate={startDate}
              endDate={endDate}
              onChange={onDateChange}
            />
          }
        >
          <div className="w-full m-auto max-w-4xl text-center text-gray-700">
            <Translation>
              {(t) => capitalizeFirst(t('there is no data to show for this time period.'))}
            </Translation>
          </div>
        </Page>
      );
    }

    const interactionData = [
      { level: 'Video', group: 'Control Bar', interaction: 'pause', click: stats.totals.period.pause, impression: stats.totals.period.start, ctr: stats.totals.period.pause / stats.totals.period.start * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'resume', click: stats.totals.period.resume, impression: stats.totals.period.start, ctr: stats.totals.period.resume / stats.totals.period.start * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'replay', click: stats.totals.period.replay, impression: stats.totals.period.complete, ctr: stats.totals.period.replay / stats.totals.period.complete * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'seek', click: stats.totals.period.seek, impression: stats.totals.period.start, ctr: stats.totals.period.seek / stats.totals.period.start * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'mute', click: stats.totals.period.mute, impression: stats.totals.period.start, ctr: stats.totals.period.mute / stats.totals.period.start * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'unmute', click: stats.totals.period.unmute, impression: stats.totals.period.start, ctr: stats.totals.period.unmute / stats.totals.period.start * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'resize to fullscreen', click: stats.totals.period.resizeFullscreen, impression: stats.totals.period.start, ctr: stats.totals.period.resizeFullscreen / stats.totals.period.start * 100 },
      { level: 'Video', group: 'Control Bar', interaction: 'resize to normal', click: stats.totals.period.resizeNormal, impression: stats.totals.period.start, ctr: stats.totals.period.resizeNormal / stats.totals.period.start * 100 }
    ];

    return (
      <Page
        title={video ? video.title : 'Analytics'}
        controls={
          <DatePicker
            mode="range"
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            quickDateButtons={['LH', 'T', '7D', '30D', 'MTD', 'LM']}
          />
        }
      >
        <div className="flex flex-col md:flex-row">
          <div className="grid grid-cols-2 md:grid-cols-3 md:w-2/3">
            <div className={'mr-5'}>
              <StatsBox
                className="flex-1 mr-5"
                // description="Number of times the video is played."
                label="views in this period"
                comparison={stats.totals.comparison.start}
                value={stats.totals.period.start}
                formatter={HelperFormatter.number}
              />
            </div>
            <div className={'mr-4'}>
              <StatsBox
                className="flex-1 mr-5"
                // description="How often the video is played to its completion."
                label="completion rate"
                comparison={stats.totals.comparison.complete / stats.totals.comparison.start * 100}
                value={stats.totals.period.complete / stats.totals.period.start * 100}
                formatter={HelperFormatter.percentage}
              />
            </div>
            <div className={'mr-4'}>
              <StatsBox
                className="flex-1"
                // description="Weighted average of the watch quartile."
                label="average view rate"
                formatter={HelperFormatter.percentage}
                value={viewRate}
              />
            </div>
            <div className={'mr-4'}>
              <StatsBox
                className="flex-1 mr-5"
                // description="How often the video is seeked."
                label="average seek rate"
                comparison={(stats.totals.comparison.seek) / stats.totals.comparison.start * 100}
                value={(stats.totals.period.seek) / stats.totals.period.start * 100}
                formatter={HelperFormatter.percentage}
              />
            </div>
            <div className={'mr-4'}>
              <StatsBox
                className="flex-1 mr-5"
                // description="How often the played video started automatically."
                label="autoplay view rate"
                comparison={(1 - (stats.totals.comparison.posterClick / stats.totals.comparison.start)) * 100}
                value={(1 - (stats.totals.period.posterClick / stats.totals.period.start)) * 100}
                formatter={HelperFormatter.percentage}
              />
            </div>
            <div className={'mr-4'}>
              <StatsBox
                className="flex-1"
                // description="How often the player was loaded but did not start playing."
                label="bounce rate"
                comparison={(1 - (stats.totals.comparison.start / stats.totals.comparison.infoLoad)) * 100}
                value={(1 - (stats.totals.period.start / stats.totals.period.infoLoad)) * 100}
                formatter={HelperFormatter.percentage}
              />
            </div>
          </div>
          <div className={'flex md:w-1/3 mb-6'}>
            <Card className="bg-empower-700 h-full">
              <div className="p-4 pb-0">
                <div className="text-white mx-1 mt-1 font-semibold text-4xl">
                  <CountUp preserveValue={true} separator={','} end={realtime.total} />
                </div>
                <div className="text-white mx-1 text-base" style={{ color: '#7DBCFF' }}>
                  <Translation>
                    {(t) => capitalizeFirst(t('views in the last 30 minutes'))}
                  </Translation>
                </div>
                <div className="mt-3">
                  <Chart
                    type="bar"
                    height="80"
                    options={{
                      chart: {
                        animations: {
                          dynamicAnimation: {
                            enabled: true
                          }
                        },
                        offsetX: -5,
                        type: 'bar',
                        sparkline: {
                          enabled: true
                        }
                      },
                      colors: ['#4D8CFF'],
                      plotOptions: {
                        bar: {
                          columnWidth: '48px'
                        }
                      },
                      tooltip: {
                        x: {
                          format: 'MMM dd, HH:mm'
                        },
                        y: {
                          formatter: v => {
                            return v?.toLocaleString();
                          }
                        }
                      },
                      xaxis: {
                        type: 'datetime',
                        labels: {
                          datetimeUTC: false
                        }
                      }
                    }}
                    series={realtime.series}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 mr-4">
            <Card title="video views">
              <div className="p-2">
                <Chart
                  type="line"
                  height="300"
                  options={chart.options}
                  series={chart.series}
                />
              </div>
            </Card>
          </div>
          <div className="flex-1">
            <Card title="user interaction">
              <div className="overflow-x-scroll">
                <Table headers={[
                  // { content: 'Level', className: 'text-left' },
                  // { content: 'Group', className: 'text-left' },
                  { content: 'interaction', className: 'text-left' },
                  { content: 'click', className: 'text-right' },
                  { content: 'impression', className: 'text-right' },
                  { content: 'CTR', className: 'text-right' }
                ]}>
                  {interactionData.map((interaction, index) => (
                    <Table.Row key={`interaction-${index}`} className="cursor-pointer even:bg-gray-100 hover:bg-gray-200">
                      {/* <Table.Col>
                        {interaction.level}
                      </Table.Col>
                      <Table.Col>
                        {interaction.group}
                      </Table.Col> */}
                      <Table.Col>setLoadin
                        {HelperFormatter.number(interaction.interaction)}
                      </Table.Col>
                      <Table.Col className="text-right">
                        {HelperFormatter.number(interaction.click)}
                      </Table.Col>
                      <Table.Col className="text-right">
                        {HelperFormatter.number(interaction.impression)}
                      </Table.Col>
                      <Table.Col className="text-right">
                        {HelperFormatter.percentage(interaction.ctr)}
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table>
              </div>
            </Card>
          </div>
        </div>
        {stats.topVideos && <TopVideosList videos={stats.topVideos} />}
      </Page>
    );
}

VideoAnalytics.propTypes = {
  auth: PropTypes.object.isRequired,
  video: PropTypes.string
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(withRouter(VideoAnalytics));
