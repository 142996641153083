import { isEmpty } from "lodash";

import APIManager from "../../managers/APIManager";
import { AppActions } from "./app";

export const AuthActions = {
  ACTIVATE: "ACTIVATE",
  GET_PROFILE: "GET_PROFILE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  OAUTH_AUTHORIZE: "OAUTH_AUTHORIZE",
  OAUTH_GET_CLIENT: "OAUTH_GET_CLIENT",
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
  REGISTER: "REGISTER",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  SWITCH_ACCOUNT: "SWITCH_ACCOUNT",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export const AuthActionCreators = {
  activate: (credentials) => (dispatch) =>
    APIManager.activate(credentials).then((res) =>
      dispatch({
        type: AuthActions.ACTIVATE,
        payload: {
          status: !isEmpty(res.data.user),
          tokens: res.data.tokens,
          user: res.data.user,
        },
      })
    ),
  getProfile: () => (dispatch) =>
    APIManager.getProfile().then((res) =>
      dispatch({
        type: AuthActions.GET_PROFILE,
        payload: {
          user: res.data,
        },
      })
    ),
  login: (credentials) => (dispatch) =>
    APIManager.login(credentials)
      .then((res) => {
        dispatch({
          type: AuthActions.LOGIN,
          payload: {
            status: !isEmpty(res.data.user) ? 1 : 0,
            tokens: res.data.tokens,
            user: res.data.user,
          },
        });
        const lang = res?.data?.user?.language || "en";
        dispatch({
          type: AppActions.SET_LANGUAGE,
          payload: { language: lang },
        });
      })
      .catch((_) =>
        dispatch({
          type: AuthActions.LOGIN,
          payload: {
            status: -1,
            tokens: {
              accessToken: null,
              refreshToken: null,
            },
            user: null,
          },
        })
      ),
  logout: () => (dispatch) => dispatch({ type: AuthActions.LOGOUT }),
  oauthAuthorize: (clientId, redirectURI, state) => (dispatch) =>
    APIManager.authorize(clientId, redirectURI, state).then((res) => {
      dispatch({
        type: AuthActions.OAUTH_AUTHORIZE,
        payload: {
          redirectTo: res.data.redirectTo,
        },
      });
    }),
  oauthGetClient: (clientId) => (dispatch) =>
    APIManager.getClient(clientId).then((res) =>
      dispatch({
        type: AuthActions.OAUTH_GET_CLIENT,
        payload: {
          authorized: res.data.authorized,
          client: res.data.client,
        },
      })
    ),
  refreshAccessToken: (accessToken) => (dispatch) => {
    dispatch({
      type: AuthActions.REFRESH_ACCESS_TOKEN,
      payload: {
        accessToken: accessToken,
      },
    });
  },

  register: (user) => (dispatch) =>
    APIManager.register(user).then((res) =>
      dispatch({
        type: AuthActions.REGISTER,
        payload: {
          tokens: res.data.tokens,
          user: res.data.user,
        },
      })
    ),

  resetPassword: (email) => (dispatch) =>
    APIManager.resetPassword(email).then((res) =>
      dispatch({
        type: AuthActions.RESET_PASSWORD,
        payload: {
          email: res.data.email,
          message: res.data.message,
        },
      })
    ),

  changepassword: (user) => (dispatch) =>
    APIManager.changepassword(user).then((res) =>
      dispatch({
        type: AuthActions.CHANGE_PASSWORD,
        payload: {
          tokens: res.data.tokens,
          user: res.data.user,
        },
      })
    ),
  switchAccount: (account) => (dispatch) =>
    APIManager.switchAccount(account).then((res) =>
      dispatch({
        type: AuthActions.SWITCH_ACCOUNT,
        payload: {
          status: !isEmpty(res.data.user) ? 1 : 0,
          tokens: res.data.tokens,
          user: res.data.user,
        },
      })
    ),
};
