import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import validator from "validator";
import { isEmpty } from "lodash";

import { AuthActionCreators } from "../../store/actions/auth";
import HelperURL from "../../helpers/url";
import Activate from "./Activate";
import Authorize from "./Authorize";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";

import S from "../../store";
import { SiteActions } from "../../store/actions/site";
import APIManager from "../../managers/APIManager";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Auth = (props) => {
  const initialState = {
    errorMessage: null,
    infoMessage: null,
    errors: {
      email: null,
      name: null,
      password: null,
      password2: null,
    },
    loading: false,
    values: {
      client: {
        id: HelperURL.getQueryParameter("client_id", ""),
        logo: "",
        name: "",
        readAccess: false,
        verified: false,
        writeAccess: false,
      },
      code: HelperURL.getQueryParameter("code", ""),
      email: "",
      name: "",
      password: "",
      password2: "",
      reset: HelperURL.getQueryParameter("reset"),
    },
  };

  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(initialState.loading);
  const [values, setValues] = useState(initialState.values);
  const valuesRef = useRef(initialState.values);
  const [email, setEmail] = useState(initialState.values.email);
  const [password, setPassword] = useState(initialState.values.password);
  const [newPassword, setNewPassword] = useState(initialState.values.password);
  const [name, setName] = useState(initialState.values.name);
  const [isActivationSuccess, setIsActivationSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (values?.client?.id?.length) {
      props.oauthGetClient(values.client.id);
    }

    setValues({
      ...values,
      client: {
        ...values.client,
        id: HelperURL.getQueryParameter("client_id", ""),
      },
      code: HelperURL.getQueryParameter("code", ""),
      reset: HelperURL.getQueryParameter("reset", ""),
    });

    valuesRef.current = {
      ...values,
    };

    if (values?.client?.id?.length) {
      props.oauthGetClient(values.client.id);
    }
  }, []);

  useEffect(() => {
    if (props.auth.redirectTo) {
      return redirect(props.auth.redirectTo);
    } else if (props.auth.status === 1) {
      if (props.match.path !== "/auth/authorize") {
        if (props.match.path === "/auth/changepassword") {
        } else {
          return redirect();
        }
      }
    } else if (props.auth.status === -1 && loading) {
      setErrorMessage("Invalid credentials!");
      setLoading(false);
      return;
    } else if (props.auth.status === true) {
      props.auth.status = false;
      resetForm();
      window.location = "/";
      // return redirect();
    }
    // window.location = "/";
  }, []);

  const onBlur = (e) => {
    e && e.preventDefault();
    const errorsVar = errors;
    if (!e.target.value.length) {
      errorsVar[e.target.name] = "This is a required field!";
    } else {
      errorsVar[e.target.name] = null;
    }
    setErrors(errorsVar);
  };

  const onChange = (e) => {
    e && e.preventDefault();
    const valuesVar = valuesRef.current;
    valuesVar[e.target.name] = e.target.value;
    setValues(valuesVar);
    valuesRef.current = valuesVar;
    if (e.target.name === "email") setEmail(e.target.value);
    if (e.target.name === "password") setPassword(e.target.value);
    if (e.target.name === "password2") setNewPassword(e.target.value);
    if (e.target.name === "name") setName(e.target.value);
  };

  const onSubmit = (e) => {
    e && e.preventDefault();
    setErrors(initialState.errors);
    setErrorMessage(initialState.errorMessage);
    setLoading(true);
    if (props.match.params.action === "login") {
      props
        .login({
          email: email.trim(),
          password: password.trim(),
        })
        .then(() => {
          redirect();
        });
    } else if (props.match.params.action === "activate") {
      if (valuesRef.current.password !== valuesRef.current.password2) {
        const errorsVar = errors;
        errorsVar.password2 = "Passwords must match!";
        setErrors(errorsVar);
        setErrorMessage("Passwords must match!");
        setIsActivationSuccess(false);
        setLoading(false);
        return;
      }

      APIManager.activate({
        code: valuesRef.current.code.trim(),
        name: valuesRef.current.name.trim(),
        password: valuesRef.current.password.trim(),
      })
        .then((res) => {
          // S.store.dispatch({
          //   type: "ACTIVATE",
          //   payload: {
          //     status: !isEmpty(res.data.user),
          //     tokens: res.data.tokens,
          //     user: res.data.user,
          //   },
          // });
          setErrorMessage("");
          setIsActivationSuccess(true);
          setTimeout(() => {
            redirect();
          }, 1000);
        })
        .catch((err) => {
          setErrorMessage(
            "Your activation code seems to be invalid, please contact us and try again."
          );
        });

      // props.activate({
      //   code: valuesRef.current.code.trim(),
      //   name: valuesRef.current.name.trim(),
      //   password: valuesRef.current.password.trim(),
      // });
    } else if (props.match.path === "/auth/authorize") {
      const redirectURI = HelperURL.getQueryParameter("redirect_uri") || "";
      const state = HelperURL.getQueryParameter("state") || "";
      props.oauthAuthorize(valuesRef.current.client.id, redirectURI, state);
    } else if (props.match.params.action === "register") {
      if (
        valuesRef.current.email === "" ||
        valuesRef.current.name === "" ||
        valuesRef.current.password === ""
      ) {
        setErrorMessage("Please fill the required inputs");
        setLoading(false);
      } else {
        setInfoMessage("Please wait while our team activate your account");
        setLoading(false);
        props.register({
          email: email.trim(),
          name: name.trim(),
          password: password.trim(),
        });
      }
    } else if (props.match.params.action === "resetpassword") {
      if (validator.isEmail(email.trim())) {
        resetForm();
        setInfoMessage("Please check your inbox to reset password");
        setLoading(false);
        props.resetPassword({
          email: email.trim(),
        });
      } else {
        setErrorMessage("Check the e-mail address format");
        setLoading(false);
      }
    } else if (props.match.path === "/auth/changepassword") {
      if (valuesRef.current.password !== valuesRef.current.password2) {
        setErrorMessage("Passwords doesn't match");
        setLoading(false);
      } else {
        setInfoMessage("Your password has changed!");
        setLoading(false);
        props.changepassword({
          userID: props.auth.user.id,
          password: password.trim(),
        });
      }
    }
  };

  const redirect = (path) => {
    if (!path) {
      setTimeout(() => {
        window.location = HelperURL.getQueryParameter("referrer") || "/";
      }, 100);
    } else {
      setTimeout(() => {
        window.location = path;
      }, 100);
    }
  };

  const resetForm = () => {
    S.store.dispatch({ type: SiteActions.INVALIDATE_SITES });
    setErrorMessage(initialState.errorMessage);
    setInfoMessage(initialState.infoMessage);
    setErrors(initialState.errors);
    setLoading(initialState.loading);
    setValues(initialState.values);
    setEmail("");
    setPassword("");
    valuesRef.current = initialState.values;
  };

  // if (props.match.params.action === "switch") {
  //   const account = HelperURL.getQueryParameter("id");
  //   S.store.dispatch({ type: SiteActions.INVALIDATE_SITES });
  //   APIManager.switchAccount(account).then((res) => {
  //     S.store.dispatch({
  //       type: "SWITCH_ACCOUNT",
  //       payload: {
  //         status: res.data.user.length ? 1 : 0,
  //         tokens: res.data.tokens,
  //         user: res.data.user,
  //       },
  //     });
  //   });
  //   return null;
  // }

  if (props.match.params.action === "logout") {
    props.logout();
    // resetForm();
    S.store.dispatch({ type: SiteActions.INVALIDATE_SITES });

    // return <Redirect to="/auth/login" />;
    window.location = "/auth/login";
  } else if (props.match.params.action === "register") {
    return Register(
      errors,
      values,
      errorMessage,
      infoMessage,
      onBlur,
      onChange,
      onSubmit,
      email,
      password,
      name
    );
  } else if (props.match.params.action === "resetpassword") {
    return ResetPassword(
      errors,
      values,
      errorMessage,
      infoMessage,
      onBlur,
      onChange,
      onSubmit
    );
  } else if (props.match.path === "/auth/changepassword") {
    return ChangePassword(
      errors,
      values,
      errorMessage,
      infoMessage,
      onBlur,
      onChange,
      onSubmit,
      password,
      newPassword
    );
  } else if (
    props.match.params.action === "activate" &&
    valuesRef.current.code.length
  ) {
    return Activate(
      errors,
      values,
      errorMessage,
      isActivationSuccess,
      onBlur,
      onChange,
      onSubmit
    );
  } else if (props.match.path === "/auth/authorize") {
    return Authorize(
      errors,
      {
        ...values,
        client: props.auth.oauth.client,
      },
      errorMessage,
      onBlur,
      onChange,
      onSubmit
    );
  } else if (props.match.params.action === "login") {
    return Login(
      errors,
      values,
      errorMessage,
      loading,
      onBlur,
      onChange,
      onSubmit,
      email,
      password
    );
  }
};

Auth.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  activate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  oauthAuthorize: PropTypes.func.isRequired,
  oauthGetClient: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  changepassword: PropTypes.func.isRequired,
  switchAccount: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      auth: state.auth,
    }),
    (dispatch) => ({
      activate: (credentials) =>
        dispatch(AuthActionCreators.activate(credentials)),
      login: (credentials) => dispatch(AuthActionCreators.login(credentials)),
      logout: () => dispatch(AuthActionCreators.logout()),
      oauthAuthorize: (clientId, redirectURI, state) =>
        dispatch(
          AuthActionCreators.oauthAuthorize(clientId, redirectURI, state)
        ),
      oauthGetClient: (clientId) =>
        dispatch(AuthActionCreators.oauthGetClient(clientId)),
      register: (user) => dispatch(AuthActionCreators.register(user)),
      changepassword: (user) =>
        dispatch(AuthActionCreators.changepassword(user)),
      switchAccount: (account) =>
        dispatch(AuthActionCreators.switchAccount(account)),
      resetPassword: (email) =>
        dispatch(AuthActionCreators.resetPassword(email)),
    })
  )(Auth)
);
