import _ from 'lodash';
import * as React from 'react';
import * as Icon from 'react-feather';

const videoStatusMap = {
  AVAILABLE: {
    color: 'success'
  },
  CREATED: {
    color: 'info',
    percentage: 0,
    description: 'Getting ready...',
    alert: {
      type: 'info',
      title: 'Waiting for processing!',
      message: 'Your video will be processed shortly...'
    }
  },
  DELETED: {
    color: 'danger'
  },
  DOWNLOADING: {
    color: 'info',
    percentage: 20,
    description: 'Downloading...',
    alert: {
      type: 'info',
      title: 'Downloading!',
      message: 'Your video is being downloaded for transcoding.'
    }
  },
  DOWNLOAD_ERROR: {
    color: 'danger',
    alert: {
      type: 'danger',
      title: 'Download failed!',
      message: 'We were unable to download your video. Please contact support for further assistance.'
    }
  },
  QUEUED: {
    color: 'info',
    percentage: 10,
    description: 'Preparing for download...',
    alert: {
      type: 'info',
      title: 'Preparing for download!',
      message: 'Your video will begin the downloading process shortly...'
    }
  },
  TRANSCODING: {
    color: 'info',
    percentage: 80,
    description: 'Transcoding...',
    alert: {
      type: 'info',
      title: 'Transcoding in progress!',
      message: 'Your video will be available shortly...'
    }
  },
  TRANSCODING_ERROR: {
    color: 'danger',
    alert: {
      type: 'danger',
      title: 'Transcoding failed!',
      message: 'We were unable to transcode your video. Please contact support for further assistance.'
    }
  },
  UPLOAD_ERROR: {
    color: 'danger',
    alert: {
      type: 'danger',
      title: 'Upload failed!',
      message: 'We were unable to transfer your video for transcoding. Please contact support for further assistance...'
    }
  },
  UPLOADED: {
    color: 'info',
    percentage: 60,
    description: 'Preparing for transcoding...',
    alert: {
      type: 'info',
      title: 'Preparing for transcoding!',
      message: 'Your video will begin the transcoding process shortly...'
    }
  },
  UPLOADING: {
    color: 'info',
    percentage: 40,
    description: 'Uploading for transcoding...',
    alert: {
      type: 'info',
      title: 'Uploading for transcoding!',
      message: 'Your video is being transferred for the transcoding process...'
    }
  }
};

const video = {
  formatDuration: duration => {
    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    if (hours > 0) {
      minutes = minutes % 60;
      return [
        _.padStart(hours, 2, '0'),
        _.padStart(minutes, 2, '0'),
        _.padStart(seconds, 2, '0')
      ].join(':');
    }
    return [_.padStart(minutes, 2, '0'), _.padStart(seconds, 2, '0')].join(':');
  },
  getVideoStatus: status => {
    return videoStatusMap[status];
  },
  getVideoStatusAlert: video => {
    if (!videoStatusMap[video.status].alert) {
      return;
    }
    const alertColor = videoStatusMap[video.status].alert.type === 'info' ? 'teal' : 'red';
    return (
      <div className={`bg-${alertColor}-100 border-l-4 border-${alertColor}-500 rounded-b text-${alertColor}-900 px-4 py-3 shadow mb-3`} role="alert">
        <div className="flex">
          <div className="py-1">
            <Icon.Info className={`fill-current h-6 w-6 text-${alertColor}-500 mr-4`} />
          </div>
          <div>
            <p className="font-bold">{videoStatusMap[video.status].alert.title}</p>
            <p className="text-sm">{videoStatusMap[video.status].alert.message}</p>
          </div>
        </div>
      </div>
    );
  }
};

video.getVideoStatusAlert.displayName = 'VideoStatusAlert';

export default video;
