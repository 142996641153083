import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import * as Icon from 'react-feather';
import { Link, NavLink, withRouter } from 'react-router-dom';

import APIManager from '../../managers/APIManager';
import HelperPlayer from '../../helpers/player';
import HelperUtils from '../../helpers/utils';

import Badge from '../common/Badge';
import Button from '../common/Button';
import Card from '../common/Card';
import Loading from '../common/Loading';
import Page from '../common/Page';
import Table from '../common/Table';

import { Translation } from 'react-i18next';
import { capitalize, capitalizeFirst } from '../../utils/manipulateText';

const PlayerList = (props) => {
  const [filterKeywords, setFilterKeywords] = useState(null);
  const [players, setPlayers] = useState(null);

  useEffect(() => {
    getPlayers();
  }, [])

  const deletePlayer = (e, player) => {
    e.preventDefault();
    e.stopPropagation();
    HelperUtils.prompt('Are you sure you want to delete this player? This operation is irreversible.')
      .then(() => APIManager.deletePlayer(player))
      .then(() => getPlayers());
  }

  const getPlayers = () => {
    APIManager.getPlayers().then(res => {
      // setState({ players: res.data });
      setPlayers(res.data);
    });
  }

  const makeDefaultPlayer = (e, player) => {
    e.preventDefault();
    e.stopPropagation();
    HelperUtils.prompt('Are you sure you want to make this your default player?')
      .then(() => APIManager.updatePlayer({
        id: player,
        isDefault: 1
      }))
      .then(() => getPlayers());
  }

  const redirectToPlayerDetail = (e) => {
    e.preventDefault();
    props.history.push(`/players/${e.target.closest('tr').dataset.key}`);
  }

  const setFilterKeywordsFunc = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.value.trim().length) {
      setFilterKeywords(e.target.values);
    } else {
      setFilterKeywords(e.target.value.trim().toLowerCase())
    }
  }

    if (players === null) {
      return <Loading />;
    }

    const languageMap = {
      en: 'English',
      fr: 'French',
      de: 'German',
      ru: 'Russian',
      es: 'Spanish',
      tr: 'Turkish'
    };

    return players.length ? (
      <Page
        title="Players"
        controls={
          <Button
            RootComponent={withRouter(NavLink)}
            to="/players/new"
            color="primary"
          >
            <Icon.Plus className="inline mr-1" width="16" height="16" />
            New Player
          </Button>
        }
      >
        <div>
          <div className="flex rounded-md shadow-md mb-4">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Icon.Search className="h-5 w-5 text-gray-400" width="16" height="16" />
              </div>
              <input onChange={setFilterKeywordsFunc} className="appearance-none rounded-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-l-md text-gray-900 placeholder-gray-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150" placeholder="Search your players" />
            </div>
            <button className="-ml-px relative flex items-center px-3 py-2 rounded-r-md border border-gray-300 text-sm leading-5 bg-gray-50 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
              <Icon.Filter className="h-5 w-5 text-gray-400" width="16" height="16" />
              <span className="ml-2">Filter</span>
            </button>
          </div>
        </div>
        <Card>
          <div className="overflow-x-scroll">
            <Table
              headers={[
                { content: 'name', className: 'text-left' },
                { content: 'language', className: 'text-left' },
                { content: 'status', className: 'text-left' },
                { content: 'date created', className: 'text-left' },
                { content: 'actions' }
              ]}
            >
              {players.map((player, index) => (
                <Table.Row
                  key={player.id}
                  dataKey={player.id}
                  className={`even:bg-gray-100 hover:bg-gray-200 ${filterKeywords && player.name.toLowerCase().indexOf(filterKeywords) === -1 ? 'hidden' : ''} ${player.status !== 'DELETED' ? 'cursor-pointer' : ''}`}
                  onClick={player.status !== 'DELETED' ? redirectToPlayerDetail : () => { }}
                >
                  <Table.Col>
                    {player.name}
                    <div className="font-mono text-gray-500 text-xs">{player.id}</div>
                  </Table.Col>
                  <Table.Col>
                    {languageMap[player.language]}
                  </Table.Col>
                  <Table.Col>
                    <Badge color={HelperPlayer.getPlayerStatus(player.status).color} className="inline-block">
                      {player.status}
                    </Badge>
                    {player.isDefault ? (
                      <Badge color="primary" className="ml-1 inline-block">DEFAULT</Badge>
                    ) : null}
                  </Table.Col>
                  <Table.Col>
                    {moment(player.createdAt).format('MMMM D, YYYY')}
                  </Table.Col>
                  <Table.Col className="text-center">
                    {player.isDefault || player.status === 'DELETED' ? (
                      <>
                        {player.isDefault ? (
                          <Icon.Star className="mr-3 inline-block text-gray-400 fill-current" width="20" height="20" />
                        ) : (
                          <Icon.Star className="mr-3 inline-block text-gray-400" width="20" height="20" />
                        )}
                        <Icon.Trash2 className="inline-block text-gray-400" width="20" height="20" />
                      </>
                    ) : (
                      <>
                        <Link className="mr-3" onClick={e => makeDefaultPlayer(e, player.id)} title="Make Default Player">
                          <Icon.Star className="inline-block text-gray-600 hover:text-gray-900" width="20" height="20" />
                        </Link>
                        <Link onClick={e => deletePlayer(e, player.id)}>
                          <Icon.Trash2 className="inline-block text-gray-600 hover:text-pink-600" width="20" height="20" />
                        </Link>
                      </>
                    )}

                  </Table.Col>
                </Table.Row>
              ))}
            </Table>
          </div>
        </Card>
      </Page>
    ) : (
      <Page
        title="Players"
        controls={
          <Button
            RootComponent={withRouter(NavLink)}
            to="/players/new"
            color="primary"
          >
            <Icon.Plus className="inline mr-1" width="16" height="16" />
            <Translation>
              {(t) => capitalize(t('new player'))}
            </Translation>
          </Button>
        }>
        <div className="w-full m-auto max-w-4xl text-center text-gray-700">
          <Translation>
            {(t) => capitalizeFirst(t("you don't have a player yet!"))}
          </Translation>
        </div>
      </Page>
    );
}

PlayerList.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(PlayerList);
