import PropTypes from "prop-types";
import React, { useEffect } from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { upperCase, capitalizeFirst } from "../../utils/manipulateText";

const ToolTip = ({ title, content, children, customStyle = "" }) => {
  const { t } = useTranslation();
  const container = React.createRef();

  useEffect(() => {
    const elm = container.current;
    const ttElement = elm.childNodes[1];
    const bounds = elm.getBoundingClientRect();
    const ttContent = elm.childNodes[0].getBoundingClientRect();
    ttElement.style.left = bounds.left + "px";
    ttElement.style.top = bounds.top + ttContent.height + "px";
    if (ttContent.width < 60) {
      ttElement.style.width = ttContent.width + 50 + "px";
    } else {
      ttElement.style.width = ttContent.width + "px";
    }
    elm.onmouseover = (e) => {
      if (window.innerWidth >= 576) {
        ttElement.style.display = "block";
      }
    };
    elm.onmouseout = (e) => {
      ttElement.style.display = "none";
    };
  }, [container]);

  return (
    <div className={`empower-tooltip z-60 ${customStyle}`} ref={container}>
      <div>{children}</div>
      <div
        style={{ color: "#718096" }}
        className="hidden absolute z-10 md:p-2 mb-5 rounded-md transition duration-500 ease-in-out card shadow-md bg-white w-full tt-div"
      >
        <div className="flex items-center justify-start my-1">
          <Icon.HelpCircle className="h-4 w-4 text-gray-600"></Icon.HelpCircle>
          <div style={{ fontSize: 13 }} className="md:font-bold text-md px-2">
            {upperCase(t(title))}
          </div>
        </div>
        <div
          style={{ fontSize: 11.5 }}
          className="md:font-light text-md pt-1 px-2"
        >
          <span>{capitalizeFirst(t(content))}</span>
        </div>
      </div>
    </div>
  );
};

ToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any,
  children: PropTypes.any,
  customStyle: PropTypes.any,
};

export default withRouter(ToolTip);
