import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PaymentList from "./PaymentList";
import PaymentEdit from "./PaymentEdit";
import APIManager from "../../managers/APIManager";

const Payments = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      APIManager.getPayments(props.auth.user.id),
      APIManager.getPaymentInfo(props.auth.user.id),
    ])
      .then(([paymentsResponse, paymentInfoResponse]) => {
        setPaymentData([...paymentsResponse.data.payments]);
        setPaymentInfo({ ...paymentInfoResponse.data });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.auth.user.id]);

  if (props.auth.user.integrationType === 1) {
    props.history.push("/analytics/videos");
    return null;
  } else if (props.match.params.subaction === "edit") {
    return (
      <PaymentEdit
        loading={loading}
        setLoading={setLoading}
        paymentInfo={paymentInfo}
        paymentData={paymentData}
      />
    );
  }
  return (
    <PaymentList
      loading={loading}
      setLoading={setLoading}
      paymentInfo={paymentInfo}
      paymentData={paymentData}
    />
  );
};

Payments.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withRouter(Payments));
