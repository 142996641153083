import { VideoActions } from '../actions/video';

const INITIAL_STATE = {
  playback: {},
  videos: []
};

function video(state = INITIAL_STATE, action) {
  let playback, videos;
  switch (action.type) {
    case VideoActions.CREATE_VIDEO:
      videos = state.videos.slice();
      videos.push(action.payload.video);
      return {
        ...state,
        videos
      };
    case VideoActions.DELETE_VIDEO:
    case VideoActions.GET_VIDEO:
    case VideoActions.UPDATE_VIDEO:
      videos = state.videos.slice().filter(video => video.id !== action.payload.video.id);
      videos.push(action.payload.video);
      return {
        ...state,
        videos
      };
    case VideoActions.GET_VIDEO_PLAYBACK:
      playback = Object.assign({}, state.playback);
      playback[action.payload.video.id] = action.payload.video;
      return {
        ...state,
        playback
      };
    case VideoActions.GET_VIDEOS:
      return {
        ...state,
        videos: action.payload.videos
      };
    case VideoActions.INVALIDATE_VIDEOS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default { video };
