import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import logoSmall from "./assets/images/empower-logo-box.png";
import logoLarge from "./assets/images/empower-logo.png";

import Wrapper from "./components/common/Wrapper";
import HelperUtils from "./helpers/utils";

const AppWrapper = ({ auth, site, children }) => {
  const { status, user, sites } = auth;

  const [isAppNamesIncluded, setIsAppNamesIncluded] = useState(false);

  useEffect(() => {
    var isIncluded =
      site?.sites?.[0]?.dimensions?.includes("appNames") === undefined
        ? false
        : site?.sites?.[0]?.dimensions?.includes("appNames");
    setIsAppNamesIncluded(isIncluded);
  }, [site]);

  if (status !== 1) {
    const referrer = window.location.pathname + window.location.search;
    let redirectTo = "/auth/login";
    if (referrer.length) {
      redirectTo += "?referrer=" + encodeURIComponent(referrer);
    }
    return <Redirect to={redirectTo} />;
  }

  const navBarItems = [
    {
      title: "Documentation",
      target: "<https://developers.empower.net/>",
      LinkComponent: withRouter(NavLink),
    },
    {
      title: "Support",
      target: "<mailto:info@empower.net>",
      LinkComponent: withRouter(NavLink),
    },
  ];

  return (
    <Wrapper
      header={{
        logo: {
          image: {
            small: logoSmall,
            large: logoLarge,
          },
          title: "Empower",
          target: "/",
        },
        account: {
          avatar: HelperUtils.getGravatarURL(user.email),
          name: user.name,
          description: user.email,
          options: [{ icon: "log-out", to: "/auth/logout", value: "Log Out" }],
          integrationType: user.integrationType,
          currency: user.currency,
          timezone: user.timezone,
        },
        sites: sites && sites.data ? sites.data : [],
        isAppNamesIncluded: isAppNamesIncluded,
      }}
      navigation={{
        items: navBarItems,
      }}
    >
      {children}
    </Wrapper>
  );
};

AppWrapper.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    site: state.site,
  };
};

export default connect(mapStateToProps)(AppWrapper);
