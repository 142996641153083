import React from "react";
import Chart from "react-apexcharts";

const ComboChart = ({ chart }) => {
  return (
    <Chart
      type={chart.combinedChart.options.chart.type}
      height="360"
      options={chart.combinedChart.options}
      series={chart.combinedChart.series}
    />
  );
};

export default ComboChart;
