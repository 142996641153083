import { isEmpty } from "lodash";

import { AuthActions } from "../actions/auth";

const INITIAL_STATE = {
  status: 0,
  oauth: {
    authorized: false,
    client: {
      id: "",
      logo: "",
      name: "",
      readAccess: false,
      verified: false,
      writeAccess: false,
    },
    redirectTo: null,
  },
  tokens: {
    accessToken: null,
    refreshToken: null,
  },
  user: null,
};

function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AuthActions.ACTIVATE:
    case AuthActions.LOGIN:
    case AuthActions.REGISTER:
    case AuthActions.CHANGE_PASSWORD:
      return {
        ...state,
        status: action.payload.status,
        tokens: action.payload.tokens,
        user: action.payload.user,
      };

    case AuthActions.SWITCH_ACCOUNT:
      return {
        ...INITIAL_STATE,
        status: action.payload.status,
        tokens: action.payload.tokens,
        user: action.payload.user,
      };

    case AuthActions.RESET_PASSWORD:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
        email: action.payload.email,
      };
    case AuthActions.GET_PROFILE:
      return {
        ...state,
        user: action.payload,
      };
    case AuthActions.LOGOUT:
      return INITIAL_STATE;
    case AuthActions.OAUTH_AUTHORIZE:
      return {
        ...state,
        authorized: !isEmpty(action.payload.redirectTo),
        redirectTo: action.payload.redirectTo,
      };
    case AuthActions.OAUTH_GET_CLIENT:
      return {
        ...state,
        oauth: {
          ...state.oauth,
          authorized: action.payload.authorized,
          client: action.payload.client,
        },
      };
    case AuthActions.REFRESH_ACCESS_TOKEN:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          accessToken: action.payload.accessToken,
        },
      };
    default:
      return state;
  }
}

export default { auth };
