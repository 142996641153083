import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import APIManager from "../../managers/APIManager";
import Button from "../common/Button";
import Card from "../common/Card";
import ClipboardButton from "../common/ClipboardButton";
import Form from "../common/Form";
import Page from "../common/Page";
import Loading from "../common/Loading";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atelierLakesideDark as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";

const adstxt = `#Google
google.com, pub-5848572006993303, RESELLER, f08c47fec0942fa0
google.com, pub-0802393472674142, RESELLER, f08c47fec0942fa0
google.com, pub-7983651257838282, RESELLER, f08c47fec0942fa0
google.com, pub-6593523210010154, RESELLER, f08c47fec0942fa0

#Adform
adform.com, 1846, RESELLER, 9f5210a2f0999e32

#Rubicon
rubiconproject.com, 13760, RESELLER, 0bfd66d529a55807
rubiconproject.com, 22988, RESELLER, 0bfd66d529a55807

#Pubmatic
pubmatic.com, 159432, RESELLER, 5d62403b186f2ace
pubmatic.com, 160850 , DIRECT, 5d62403b186f2ace

#Adagio
adagio.io, 1066, RESELLER
rubiconproject.com, 19116, RESELLER, 0bfd66d529a55807
pubmatic.com, 159110, RESELLER, 5d62403b186f2ace
improvedigital.com, 1790, RESELLER

#TripleLift
triplelift.com, 9317, DIRECT, 6c33edb13117fd86

#Ttssp
programattik.com, 31, DIRECT

#EmxDigital
EMXDGT.com,1751, DIRECT, 1e1d41537f7cad7f
Appnexus.com, 1356, RESELLER, f5ab79cb980f11d1

#Appnexus
appnexus.com, 12268, DIRECT, f5ab79cb980f11d1

#Sovrn
sovrn.com, 293179, DIRECT, fafdf38b16bf6b2b
lijit.com, 293179, DIRECT, fafdf38b16bf6b2b
lijit.com, 293179-eb, DIRECT, fafdf38b16bf6b2b
appnexus.com, 1360, RESELLER, f5ab79cb980f11d1
gumgum.com, 11645, RESELLER, ffdef49475d318a9
openx.com, 538959099, RESELLER, 6a698e2ec38604c6
openx.com, 539924617, RESELLER, 6a698e2ec38604c6
pubmatic.com, 137711, RESELLER, 5d62403b186f2ace
pubmatic.com, 156212, RESELLER, 5d62403b186f2ace
pubmatic.com, 156700, RESELLER, 5d62403b186f2ace
rubiconproject.com, 17960, RESELLER, 0bfd66d529a55807

#Unruly
video.unrulymedia.com, 4093688580, DIRECT
rhythmone.com, 4093688580, DIRECT, a670c89d4a324e47 
indexexchange.com, 182257, RESELLER 
appnexus.com, 6849, RESELLER 
rubiconproject.com, 15268, RESELLER 
spotxchange.com, 285547, RESELLER, 7842df1d2fe2db34 
spotx.tv, 285547, RESELLER, 7842df1d2fe2db34 
pubmatic.com, 159277, RESELLER, 5d62403b186f2ace 
advertising.com, 28605, RESELLER 
improvedigital.com, 1699, RESELLER

#ValueImpression
quantumdex.io, EXU5913, DIRECT
interdogmedia.com, EXU5913, DIRECT
apacdex.com, EXU5913, DIRECT
appnexus.com, 10273, RESELLER, f5ab79cb980f11d1
appnexus.com, 11711, RESELLER, f5ab79cb980f11d1
indexexchange.com, 192922, RESELLER, 50b1c356f2c5c8fc
sonobi.com, 4d443a3ea2, RESELLER, d1a215d9eb5aee9e
rhythmone.com, 1059622079, RESELLER, a670c89d4a324e47
contextweb.com, 560606, RESELLER, 89ff185a4c4e857c
onetag.com, 2bb78272a859ca6, DIRECT
advertising.com, 28246, RESELLER
advangelists.com, 8fe0093bb30d6f8c31474bd0764e6ac0, DIRECT, 60d26397ec060f98
rubiconproject.com,20848, RESELLER, 0bfd66d529a55807
advertising.com, 28643, RESELLER #VerizonVideo
aol.com,55609, RESELLER, e1a5b5b6e3255540
yahoo.com,55609, RESELLER, e1a5b5b6e3255540
contextweb.com, 557779, RESELLER, 89ff185a4c4e857c
sovrn.com, 278426, DIRECT, fafdf38b16bf6b2b
lijit.com, 278426, DIRECT, fafdf38b16bf6b2b
appnexus.com, 1360, RESELLER, f5ab79cb980f11d1
gumgum.com, 11645, RESELLER, ffdef49475d318a9
openx.com, 538959099, RESELLER, 6a698e2ec38604c6
openx.com, 539924617, RESELLER, 6a698e2ec38604c6
pubmatic.com, 137711, RESELLER, 5d62403b186f2ace
pubmatic.com, 156212, RESELLER, 5d62403b186f2ace
pubmatic.com, 156700, RESELLER, 5d62403b186f2ace
rubiconproject.com, 17960, RESELLER, 0bfd66d529a55807
#Smart
smartadserver.com, 3913, DIRECT
contextweb.com, 560288, RESELLER, 89ff185a4c4e857c
pubmatic.com, 156439, RESELLER, 5d62403b186f2ace
pubmatic.com, 154037, RESELLER, 5d62403b186f2ace
rubiconproject.com, 16114, RESELLER, 0bfd66d529a55807
openx.com, 537149888, RESELLER, 6a698e2ec38604c6
appnexus.com, 3703, RESELLER, f5ab79cb980f11d1
districtm.io, 101760, RESELLER, 3fd707be9c4527c3
loopme.com, 5679, RESELLER, 6c8d5f95897a5a3b
xad.com, 958, RESELLER, 81cbf0a75a5e0e9a
rhythmone.com, 2564526802, RESELLER, a670c89d4a324e47
smaato.com, 1100044045, RESELLER, 07bcf65f187117b4
pubnative.net, 1006576, RESELLER, d641df8625486a7b
adyoulike.com, b4bf4fdd9b0b915f746f6747ff432bde, RESELLER
axonix.com, 57264, RESELLER
admanmedia.com, 43, RESELLER

#Teads
teads.tv, 22617, DIRECT, 15a9c44f6d26cbe1`;

const SiteOnboarding = (props) => {
  var onboardingSteps = [
    {
      title: "Enter your domain name",
      icon: "globe",
    },
    {
      title: "Fill in Google Ad Manager SPM form",
      icon: "edit-3",
    },
    {
      title: "Upload the ads.txt file",
      icon: "file-text",
    },
    {
      title: "Upload and embed your videos",
      description:
        "Let's start with uploading your videos to Empower. Simply paste URLs of 5 of your videos below and start the upload process. You will be able to upload more videos once you complete the onboarding process.",
      icon: "play",
    },
  ];

  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({ fqdn: "", video1: "" });
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState({});
  const [processing, setProcessing] = useState({ video1: false });
  const [site, setSite] = useState(null);
  const [values, setValues] = useState({ fqdn: "", video1: "" });
  const [videoIndex, setVideoIndex] = useState(1);
  const [videos, setVideos] = useState({ video1: null });

  const getSite = () => {
    APIManager.getSite(props.site).then((res) => {
      const siteVar = res.data;
      const siteOnboardingSteps = siteVar.onboardingSteps.split(",");
      const onboardingStepsVar = [];
      for (const onboardingStep in onboardingSteps) {
        if (siteOnboardingSteps.indexOf(onboardingStep) > -1) {
          onboardingStepsVar.push(onboardingSteps[onboardingStep]);
        }
      }
      onboardingSteps = onboardingStepsVar;
      setSite(siteVar);
    });
  };

  useEffect(() => {
    getSite();
  }, []);

  const onAddNewVideo = (e) => {
    e.preventDefault();

    const videoIndexVar = videoIndex + 1;
    const videoKeyVar = `video${videoIndexVar}`;

    const errorsVar = errors;
    errorsVar[videoKeyVar] = false;

    const processingVar = processing;
    processingVar[videoKeyVar] = false;

    const valuesVar = values;
    valuesVar[videoKeyVar] = "";

    const videosVar = videos;
    videosVar[videoKeyVar] = null;

    setErrors(errorsVar);
    setProcessing(processingVar);
    setValues(valuesVar);
    setVideoIndex(videoIndexVar);
    setVideos(videosVar);
  };

  const onBlur = (e) => {
    e.preventDefault();
    let disabledVar = disabled;
    const errorsVar = errors;
    switch (e.target.name) {
      case "fqdn":
        if (e.target.value.indexOf("http") !== -1) {
          errorsVar.fqdn = "Please only enter your domain name";
        } else if (!e.target.value.length) {
          errorsVar.fqdn = "You must enter your domain name";
        } else {
          errorsVar.fqdn = "";
        }
        break;
      default:
        break;
    }

    if (
      site.onboardingStep === 0 &&
      values.fqdn.length &&
      !errors.fqdn.length
    ) {
      disabledVar = false;
    } else if (site.onboardingStep === 1 && values.video1.length) {
      disabledVar = false;
    }

    setDisabled(disabledVar);
    setErrors(errorsVar);
  };

  const onChange = (e) => {
    e.preventDefault();
    const valuesVar = values;
    valuesVar[e.target.name] = e.target.value;
    setValues(valuesVar);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateOnboardingStep();
  };

  const onUploadVideo = (e) => {
    e.preventDefault();

    const videoKeyVar = e.target.value;
    const processingVar = processing;
    processingVar[videoKeyVar] = true;
    setProcessing(processingVar);

    APIManager.createVideo({
      source: values[videoKeyVar],
    }).then((res) => {
      const videosVar = videos;
      videosVar[videoKeyVar] = res.data;
      setVideos(videosVar);
    });
  };

  const updateOnboardingStep = () => {
    const siteVar = site;

    if (siteVar.onboardingStep === 3) {
      siteVar.onboardingStep = -1;
      siteVar.status = "LIVE";
    } else {
      siteVar.onboardingStep++;
    }

    updateSite(siteVar);
  };

  const updateSite = (site) => {
    APIManager.updateSite(site).then((res) => {
      setLoading(false);
      setSite(res.data);
    });
  };

  if (!site) {
    return <Loading />;
  }

  if (site.onboardingStep === -1) {
    props.history.push(`/sites/${site.id}`);
    return null;
  } else if (site.onboardingStep > 1 && disabled) {
    setDisabled(false);
  }

  const proceedButton =
    site.onboardingStep < onboardingSteps.length - 1 ? (
      <Button
        color="success"
        disabled={loading || disabled}
        loading={loading}
        onClick={onSubmit}
        icon="chevrons-right"
      >
        Proceed
      </Button>
    ) : (
      <Button color="success" loading={loading} onClick={onSubmit} icon="check">
        Finish
      </Button>
    );

  return (
    <Page title={`Welcome, ${site.fqdn}!`}>
      <div className="flex">
        {onboardingSteps.map((stepDetails, step) => (
          <Card
            key={`step-${step}`}
            className={`flex-1 ${step < 3 ? " mr-2" : ""} ${
              step !== site.onboardingStep ? " opacity-50" : ""
            }`}
            color={
              step < site.onboardingStep
                ? "green"
                : step === site.onboardingStep
                ? "yellow"
                : "primary"
            }
            icon={step < site.onboardingStep ? "check" : stepDetails.icon}
          >
            <Card.Body>
              <div className="text font-bold">{`Step ${step + 1}`}</div>
              <div className="text-sm">{stepDetails.title}</div>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Card
        title={`Step ${site.onboardingStep + 1}: ${
          onboardingSteps[site.onboardingStep].title
        }`}
      >
        {site.onboardingStep === 0 && (
          <Card.Body>
            <Form.FormTextInput
              name="fqdn"
              label="Domain Name"
              placeholder="example.com"
              onBlur={onBlur}
              onChange={onChange}
              error={errors && errors.fqdn}
              value={values && values.fqdn}
            />
          </Card.Body>
        )}
        {site.onboardingStep === 1 && (
          <Card.Body>
            <p className="mb-5">
              Please fill in the Google Ad Manager SPM form sent to your email
              address before proceeding.
            </p>
            <p className="mb-5">
              This is step is mandatory for us to be able to display ads on your
              property.
            </p>
          </Card.Body>
        )}
        {site.onboardingStep === 2 && (
          <Card.Body>
            <p className="mb-5">
              Place the following lines in a text file named{" "}
              <code className="code-inline text-sm">ads.txt</code> and upload it
              to the{" "}
              <code className="code-inline text-sm">{site.fqdn}/ads.txt</code>{" "}
              address.
            </p>
            <p className="mb-5">
              If you already have an{" "}
              <code className="code-inline text-sm">ads.txt</code> file, append
              these lines to the existing file.
            </p>
            <p className="mb-5">
              This is step is mandatory for us to be able to display ads on your
              property.
            </p>
            <p className="mb-5">
              <SyntaxHighlighter
                language="xml"
                style={codeStyle}
                useInlineStyles="true"
              >
                {adstxt}
              </SyntaxHighlighter>
            </p>
            <ClipboardButton content={adstxt} />
          </Card.Body>
        )}
        {site.onboardingStep === 3 && (
          <Card.Body>
            <p className="mb-5">
              Let's start with uploading your videos to Empower. Simply paste
              the URL of one of your videos below and start the upload process.
            </p>
            {Object.keys(videos).map((videoKey) => (
              <Form key={videoKey} value={videoKey} onSubmit={onUploadVideo}>
                {!videos[videoKey] && (
                  <>
                    {pages[videoKey] && (
                      <>
                        <Form.Label>Suggested Page</Form.Label>
                        <Form.FormTextInput
                          readOnly={true}
                          value={pages && pages[videoKey]}
                        />
                      </>
                    )}
                    <Form.Label>
                      Video URL{" "}
                      <span className="float-right font-normal">
                        Please only upload videos that you own the rights of.
                      </span>
                    </Form.Label>
                    <div className="mb-5 flex w-100">
                      <Form.FormTextInput
                        className="flex-1 mr-4"
                        name={videoKey}
                        placeholder="https://www.youtube.com/watch?v=1A3B5C6D7E"
                        onBlur={onBlur}
                        onChange={onChange}
                        readOnly={processing && processing[videoKey]}
                        error={errors && errors[videoKey]}
                        value={values && values[videoKey]}
                      />
                      <Button
                        className="flex-none"
                        color="primary"
                        size="sm"
                        icon="upload"
                        value={videoKey}
                        onClick={onUploadVideo}
                        disabled={
                          (values && !values[videoKey].length) ||
                          (processing && processing[videoKey])
                        }
                        loading={processing && processing[videoKey]}
                      >
                        Upload
                      </Button>
                    </div>
                  </>
                )}
                {videos[videoKey] && (
                  <>
                    <p className="mb-5">
                      Your video is now being processed for streaming. In the
                      meantime, you may place the following embed code on your
                      website where you wish the player to be displayed.
                    </p>
                    <code className="code-block bg-gray-200 text-xs">
                      &lt;script async
                      src="https://cdn.empower.net/sdk/EmpowerSDK.min.js"&gt;&lt;/script&gt;
                      <br />
                      &lt;div class="empower-player" data-empower-id="
                      {videos[videoKey].id}
                      "&gt;&lt;/div&gt;
                    </code>
                    <ClipboardButton
                      className="mb-5"
                      content={`<script async src="https://cdn.empower.net/sdk/EmpowerSDK.min.js"></script><div class="empower-player" data-empower-id="${videos[videoKey].id}"></div>`}
                    />
                  </>
                )}
              </Form>
            ))}
            <Button
              align="center"
              color="primary"
              icon="plus"
              onClick={onAddNewVideo}
            >
              Add More Videos
            </Button>
          </Card.Body>
        )}
        <Card.Footer>{proceedButton}</Card.Footer>
      </Card>
    </Page>
  );
};

SiteOnboarding.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  site: PropTypes.string.isRequired,
};

export default withRouter(SiteOnboarding);
