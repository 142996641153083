import APIManager from "../../managers/APIManager";

export const SiteActions = {
  CREATE_SITE: "CREATE_SITE",
  GET_SITE: "GET_SITE",
  GET_SITE_REPORT: "GET_SITE_REPORT",
  GET_SITES: "GET_SITES",
  INVALIDATE_SITES: "INVALIDATE_SITES",
  UPDATE_SITE: "UPDATE_SITE",
  SELECT_SITE: "SELECT_SITE",
};

export const SiteActionCreators = {
  createSite: (site) => (dispatch) =>
    APIManager.createPlayer(site).then((res) =>
      dispatch({
        type: SiteActions.CREATE_SITE,
        payload: {
          site: res.data,
        },
      })
    ),
  getSite: (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
      APIManager.getSite(id)
        .then((res) => {
          dispatch({
            type: SiteActions.SELECT_SITE,
            payload: { site: res.data },
          });
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getSiteReport: (id, params) => (dispatch) =>
    APIManager.getSiteReport(id, params).then((res) =>
      dispatch({
        type: SiteActions.GET_SITE_REPORT,
        payload: {
          params,
          report: res.data,
        },
      })
    ),
  getSites: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      APIManager.getSites()
        .then((res) => {
          dispatch({
            type: SiteActions.GET_SITES,
            payload: {
              sites: res.data,
              info: res.info,
            },
          });
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateSite: (site) => (dispatch) =>
    APIManager.updatePlayer(site).then((res) =>
      dispatch({
        type: SiteActions.UPDATE_SITE,
        payload: {
          site: res.data,
        },
      })
    ),
};
